import React, { useEffect, useState } from 'react';

// Externals
import { t } from '@lingui/macro';
import { CircularProgress, Grid } from '@mui/material';
import ReactECharts from 'echarts-for-react';

// Components
import { Header } from 'components/legacy/pages/audit/questions/header';
import { ICustomBlock, UserComponentProps } from '../..';
import { ComponentProps } from '../../google/connect/models';
import * as StorageDataKeys from '../../google/constants';

// Services
import useStorageValueCollection, { Query } from 'components/legacy/hooks/useStorageValueCollection';
import { StorageValue } from 'components/legacy/hooks/useStorageValue';

interface IStorageValueCollection extends Query {
  [StorageDataKeys.CHART_GENDER_GA]: StorageValue<any>;
}

const UserComponent = ({ name, description, connectorKey, questionKey, isScoreVisible, tooltip }: ComponentProps) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));

  const [mapping] = useStorageValueCollection<IStorageValueCollection>(
    [StorageDataKeys.PROPERTY_ID_GA, StorageDataKeys.CHART_GENDER_GA, StorageDataKeys.CHART_AGE_GA],
    organizationId
  );

  const loading =
    mapping[StorageDataKeys.PROPERTY_ID_GA].loading ||
    mapping[StorageDataKeys.CHART_GENDER_GA].loading ||
    mapping[StorageDataKeys.CHART_AGE_GA].loading;

  const chartAgeGA = mapping[StorageDataKeys.CHART_AGE_GA].value;
  const chartGenderGA = mapping[StorageDataKeys.CHART_GENDER_GA].value;
  const selectedGA = mapping[StorageDataKeys.PROPERTY_ID_GA];

  const [ageData, setAgeData] = useState<any>([]);
  const [genderData, setGenderData] = useState<any>([]);

  useEffect(() => {
    if (chartAgeGA) {
      const dataTmp = [];
      chartAgeGA?.slice(1).map((element) => {
        var newObj = {
          name: element[0],
          value: parseInt(element[1])
        };
        dataTmp.push(newObj);
      });
      setAgeData(dataTmp);
    }

    if (chartGenderGA) {
      const dataTmpGender = [];
      chartGenderGA?.slice(1).map((element) => {
        var newObj = {
          name: element[0],
          value: parseInt(element[1])
        };
        dataTmpGender.push(newObj);
      });
      setGenderData(dataTmpGender);
    }
  }, [chartAgeGA, chartGenderGA, selectedGA]);

  const optionsAge = {
    smooth: true,
    tooltip: {
      trigger: 'item'
    },
    series: [
      {
        data: ageData,
        type: 'pie',
        stack: 'total'
      }
    ],
    legend: {
      orient: 'vertical',
      left: 'right'
    }
  };

  const optionsGender = {
    smooth: true,
    tooltip: {
      trigger: 'item'
    },
    series: [
      {
        data: genderData,
        type: 'pie',
        stack: 'total'
      }
    ],
    legend: {
      orient: 'vertical',
      left: 'right'
    }
  };

  if (loading) {
    return (
      <>
        <Header
          isScoreVisible={isScoreVisible}
          label={description}
          organizationId={organizationId}
          title={name}
          tooltip={tooltip}
        />
        <CircularProgress />
      </>
    );
  }

  return (
    <>
      <Header
        isScoreVisible={isScoreVisible}
        label={description}
        organizationId={organizationId}
        title={name}
        tooltip={tooltip}
      />
      {chartAgeGA?.length < 2 && chartGenderGA?.length < 2 ? (
        'Pas de données'
      ) : (
        <Grid container={true} minHeight="100px" spacing={2}>
          <Grid item={true} md={6} sm={12} xs={12}>
            <ReactECharts option={optionsGender} />
          </Grid>
          <Grid item={true} md={6} sm={12} xs={12}>
            <ReactECharts option={optionsAge} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export const optionsLandingPage = {};
export const optionsExitPage = {};

class GoogleGA4ChartAgeGender implements ICustomBlock {
  name = () => t`Démographie`;
  description = () => t`Typologie de visiteurs ces 30 derniers jours`;
  tooltip = () => t`Google Analytics donne une estimation de qui sont vos visiteurs.`;
  isScoreVisible = () => false;
  key = () => 'google-ga-chart-agegender';
  UserComponent: React.FC<UserComponentProps> = (props) => (
    <UserComponent
      connectorKey={this.key()}
      description={this.description()}
      isScoreVisible={this.isScoreVisible()}
      name={this.name()}
      questionKey={props.questionKey}
      tooltip={this.tooltip()}
    />
  );
}

export default GoogleGA4ChartAgeGender;

function AdaptDataTableFormat(chartDataCollection) {
  const table = [];
  const headers = [];
  chartDataCollection[0].forEach((element, index) => {
    headers.push(GetHeaderName(element));
  });
  table.push(headers);

  if (chartDataCollection.rows?.length === 0) {
    table.push('', 0);
  }

  chartDataCollection.forEach((element, index) => {
    if (index === 0) return; //skip header row
    const data = [];
    element.forEach((element, index) => {
      if (index === 0) {
        //Country Name column
        data.push(element);
      } else {
        //value column
        data.push(parseInt(element));
      }
    });
    table.push(data);
  });
  return table;
}
function GetHeaderName(value: string) {
  switch (value) {
    case 'activeUsers':
    case 'ga:sessions':
      return 'Utilisateurs';
    default:
      return value;
  }
}
