// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const codesApi = createApi({
  reducerPath: 'codesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API,
    prepareHeaders: () => {
      return baseHeader(false);
    }
  }),
  endpoints: (build) => ({
    sendCode: build.mutation({
      query: (body: { email: string; language: string; url: string; notify: boolean }) => ({
        url: `/api/users/sendCode`,
        method: 'POST',
        body
      })
    }),
    codeValidation: build.mutation({
      query: (body: { code: string; email: string }) => ({
        url: `/api/users/codeValidation`,
        method: 'POST',
        body
      })
    })
  })
});

export const { useCodeValidationMutation, useSendCodeMutation } = codesApi;
