// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const organizationMemberApi = createApi({
  reducerPath: 'organizationMemberApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API,
    prepareHeaders: () => {
      return baseHeader(true);
    }
  }),
  tagTypes: [
    'my-pending-invitations',
    'pending-invitations',
    'role',
    'list-members',
    'dashboard-settings',
    'dashboard-actif'
  ],
  endpoints: (build) => ({
    getPendingInvitations: build.query({
      query: ({ organizationId }) => `/api/organizations/members/invitations/list/${organizationId}`,
      providesTags: ['pending-invitations'],
      extraOptions: {}
    }),
    getMyPendingInvitations: build.query({
      query: () => `/api/organizations/members/invitations/self/list`,
      providesTags: ['my-pending-invitations']
    }),

    getOrganizationMembers: build.query({
      query: ({ organizationId }) => `/api/organizations/members/list/${organizationId}`,
      providesTags: ['list-members']
    }),
    getOrganizationMember: build.query({
      query: ({ organizationId, userId }) => `/api/organizations/members/member/${organizationId}/${userId}`,
      providesTags: ['role']
    }),
    getMyMembershipsOwned: build.query({
      query: () => `/api/organizations/members/memberships-owned/list`
    }),
    checkInvitation: build.query({
      query: ({ invitationId }) => `/api/invitation/check/${invitationId}`
    }),
    KPISettingsActif: build.mutation({
      query: (body: { questionKpiKey: string; isActif: boolean }) => ({
        url: `/api/organizations/members/kpi/settings`,
        method: 'POST',
        body
      })
    }),
    KPIDashboardSettingsActif: build.mutation({
      query: (body: { dashboardList: object }) => ({
        url: `/api/organizations/members/kpi/dashboard`,
        method: 'POST',
        body,
        providesTags: ['dashboard-actif']
      }),
      invalidatesTags: ['dashboard-settings']
    }),
    sendInvitation: build.mutation({
      query: (body: { organizationId: number; email: string; roleId: number }) => ({
        url: `/api/organizations/members/invite`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['pending-invitations']
    }),
    //TODO : pk un POST ?
    removeMember: build.mutation({
      query: (body: { userId: string; organizationId: number }) => ({
        url: `/api/organizations/members/remove`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['list-members']
    }),
    declineInvitation: build.mutation({
      query: (body: { invitationId: string }) => ({
        url: `/api/organizations/members/invitation/decline`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['my-pending-invitations']
    }),
    cancelInvitation: build.mutation({
      query: (body: { invitationId: string }) => ({
        url: `/api/organizations/members/invitation/cancel`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['pending-invitations']
    }),
    acceptInvitation: build.mutation({
      query: (body: { invitationId: string; userId: string }) => ({
        url: `/api/organizations/members/invitation/accept`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['my-pending-invitations']
    }),
    getMemberSettings: build.query({
      query: () => `/api/organizations/members/settings`,
      providesTags: ['dashboard-settings', 'dashboard-actif']
    }),
    getMemberSettingsDashboard: build.query({
      query: () => `/api/organizations/members/dashboard`,
      providesTags: ['dashboard-settings', 'dashboard-actif']
    })
  })
});

export const {
  useAcceptInvitationMutation,
  useCancelInvitationMutation,
  useCheckInvitationQuery,
  useDeclineInvitationMutation,
  useGetMyMembershipsOwnedQuery,
  useGetMyPendingInvitationsQuery,
  useGetOrganizationMemberQuery,
  useGetOrganizationMembersQuery,
  useGetPendingInvitationsQuery,
  useSendInvitationMutation,
  useRemoveMemberMutation,
  useGetMemberSettingsQuery,
  useKPISettingsActifMutation,
  useGetMemberSettingsDashboardQuery,
  useKPIDashboardSettingsActifMutation
} = organizationMemberApi;
