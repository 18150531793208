import * as React from 'react';

// Internals
import { ICustomBlock, UserComponentProps } from '../index';
import { Header } from 'components/legacy/pages/audit/questions/header';

// Externals
import { t, Trans } from '@lingui/macro';
import { Avatar, FormHelperText, Stack, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';

// Models
import { QuestionScoreDto } from 'components/legacy/models/questionScore.dto';

// Redux
import { useSelector } from 'components/legacy/store';

import _ from 'lodash';
import YoutubeConnect from './connect';

// Hooks
import useStorageValueCollection from 'components/legacy/hooks/useStorageValueCollection';
import connectorStorageKeys from 'components/legacy/connectors/connectorStorageKeys';
import ConnectorAuthValues from 'components/legacy/connectors/connectorAuthValues';
import { useGetQuestionScoresQuery } from 'components/legacy/services/questions';
import { useParams } from 'react-router';
import { useGetSingleRoleQuery } from 'components/legacy/services/organization-roles';
import { useGetOrganizationMemberQuery } from 'components/legacy/services/organizationMember';
import { useGetUserProfileQuery } from 'components/legacy/services/userProfiles';

interface ComponentProps {
  name: string;
  description: string;
  connectorKey: string;
  questionKey: string;
  tooltip: string;
}

const ValidationSchema = yup.object().shape({
  url: yup
    .string()
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^https?:\/\/(www\.)?youtube\.com\/watch.*?(?:[?&]v=|\%2F)([\w-]+).*$/g,
      t`l'url doit être au format suivant :  https://www.youtube.com/watch?v=@<Id>`
    )
    .required(t`Veuillez renseigner l'url d'une de vos vidéos Youtube.`)
});

const UserComponent = ({ name, description, connectorKey, questionKey, tooltip }: ComponentProps) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));
  const { data: userProfile, isLoading: loadingUserProfile } = useGetUserProfileQuery({});
  const { data: role, isLoading: loadingRoleAndPermissions } = useGetOrganizationMemberQuery({
    organizationId: userProfile?.data?.userProfile?.currentOrganizationId,
    userId: userProfile?.data?.userProfile?.id
  });
  const { data: roleDetail, isLoading: loadingRoleDetail } = useGetSingleRoleQuery({
    roleId: role?.data?.member?.roleId
  });

  const [mapping] = useStorageValueCollection(
    [
      connectorStorageKeys.youtube.connectionStatus,
      connectorStorageKeys.youtube.auditStatus,
      connectorStorageKeys.youtube.channelId,
      connectorStorageKeys.youtube.channelForUsername,
      connectorStorageKeys.youtube.channelType,
      connectorStorageKeys.youtube.channelWatch,
      connectorStorageKeys.youtube.profileImageUrl
    ],
    organizationId
  );

  const { auditKey } = useParams();

  const { data: scoresQuery, isLoading: loadingScores } = useGetQuestionScoresQuery({
    organizationId,
    auditKey
  });

  const scores: QuestionScoreDto[] = useSelector<QuestionScoreDto[]>((state) =>
    scoresQuery?.data?.questions?.filter((s) => s.key === questionKey)
  );
  const score = _.sumBy(scores, (s) => s.score);

  const connectionStatus = mapping[connectorStorageKeys.youtube.connectionStatus];
  const auditStatus = mapping[connectorStorageKeys.youtube.auditStatus];
  const channelId = mapping[connectorStorageKeys.youtube.channelId];
  const channelForUsername = mapping[connectorStorageKeys.youtube.channelForUsername];
  const channelWatch = mapping[connectorStorageKeys.youtube.channelWatch];
  const channelType = mapping[connectorStorageKeys.youtube.channelType];
  const profileImageUrl = mapping[connectorStorageKeys.youtube.profileImageUrl];

  const connectionSucceeded = connectionStatus.value === ConnectorAuthValues.succeeded;
  const auditFailed = auditStatus.value === ConnectorAuthValues.failed;

  return (
    <>
      <Header
        isScoreVisible={true}
        label={description}
        organizationId={organizationId}
        score={score}
        title={name}
        tooltip={tooltip}
      />
      <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
        {profileImageUrl.value && profileImageUrl.value && (
          <Avatar
            src={profileImageUrl.value}
            sx={{ width: 70, height: 70, background: '#F1F1F1', color: ' #A8A8A8' }}
            variant="circular"
          />
        )}

        <Formik
          enableReinitialize={true}
          initialValues={{
            url: channelType.value
              ? channelType.value === 'channel'
                ? 'https://www.youtube.com/channel/' + channelId.value
                : channelType.value === 'user'
                ? 'https://www.youtube.com/user/' + channelForUsername.value
                : channelType.value === 'c'
                ? 'https://www.youtube.com/c/' + channelForUsername.value
                : channelType.value === 'shorter'
                ? 'https://www.youtube.com/@' + channelForUsername.value
                : channelType.value === 'watch'
                ? 'https://www.youtube.com/watch?v=' + channelWatch.value
                : ''
              : ''
          }}
          onSubmit={(values) => {}}
          validateOnMount={true}
          validationSchema={ValidationSchema}
        >
          {({ handleBlur, handleChange, handleReset, handleSubmit, isValid, errors, touched, values }): JSX.Element => (
            <form noValidate={true} onReset={handleReset} onSubmit={handleSubmit}>
              {connectionSucceeded && !Boolean(touched.url && errors.url) ? (
                <Typography sx={{ mb: 1 }} variant="h5">
                  {channelForUsername.value}
                </Typography>
              ) : (
                <TextField
                  disabled={connectionSucceeded || !roleDetail?.data?.role?.permissions.find((r) => r.key === 'answer')}
                  error={Boolean(touched.url && errors.url)}
                  fullWidth={true}
                  helperText={touched.url && errors.url}
                  margin="none"
                  name="url"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="https://www.youtube.com/watch?v=AAAA"
                  sx={{ mt: 2, mb: 2 }}
                  type="url"
                  value={values.url}
                />
              )}
              {connectionStatus.loading && connectionSucceeded && !channelId.value && !channelForUsername.value && (
                <FormHelperText error={true} sx={{ mb: 2 }}>
                  <Trans>Le nom de compte n'as pas été trouvé. </Trans>
                </FormHelperText>
              )}

              {auditFailed && (
                <FormHelperText error={true} sx={{ mb: 2 }}>
                  <Trans>Les statistiques de la chaîne n'ont pas pu être récupérés.</Trans>
                </FormHelperText>
              )}

              <YoutubeConnect
                connectionStatusKey={connectorStorageKeys.youtube.connectionStatus}
                isUrlValid={isValid}
                url={values.url}
              />
            </form>
          )}
        </Formik>
      </Stack>
    </>
  );
};

class YoutubeBlock implements ICustomBlock {
  description = () => t`Indiquez l'adresse URL d'une de vos vidéos Youtube afin d'analyser tout votre canal`;
  name = () => t`YouTube`;
  key = () => 'youtube-connect';
  tooltip = () =>
    t`Vous n'avez pas besoin de donner vos identifiants, juste l'adresse URL d'une de vos vidéos Youtube, n'importe laquelle. Nous n'accédons à aucune donnée privée, uniquement les données publiques de votre chaine pour analyser son nombre d'abonnés, de vidéos vues et leurs évolutions journalières. Aucune information n'est partagée avec des tiers.`;
  UserComponent: React.FC<UserComponentProps> = (props) => (
    <UserComponent
      connectorKey={this.key()}
      description={this.description()}
      name={this.name()}
      questionKey={props.questionKey}
      tooltip={this.tooltip()}
    />
  );
}

export default YoutubeBlock;
