import { alpha, Box, Grid, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { t } from '@lingui/macro';

// Services
import { useEffect } from 'react';
import NetworkIcon, { NetworkKey } from 'components/legacy/components/network';
import { useGetKeyFigureQuery } from 'components/legacy/services/socialData';

interface BoxAnalyticsProps {
  type: NetworkKey;
  setHaveDataToDisplay: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * @version 1.0.0
 * @author [Prune Blanc]
 * @param props.type The type of network
 * @returns Block of content for analytics views.
 */

function BoxAnalytics({ type, setHaveDataToDisplay }: BoxAnalyticsProps) {
  const { data } = useGetKeyFigureQuery({ socialNetwork: type });

  useEffect(() => {
    if (
      data?.data?.keyFigures &&
      (data?.data?.keyFigures.absoluteFollowers || data?.data?.keyFigures.lastThirtyDailyFollowers)
    ) {
      setHaveDataToDisplay(true);
    }
  }, [data, setHaveDataToDisplay]);

  if (data?.data?.keyFigures === null) {
    return null;
  }

  const likesCount = data?.data?.keyFigures.last30DaysPostsLikes;
  const likesProgression = data?.data?.keyFigures.last30DaysPostsLikesDiff;

  return (
    <Grid item={true}>
      <Paper
        elevation={3}
        sx={{
          position: 'relative',
          borderRadius: '20px',
          breakInside: 'avoid'
        }}
      >
        <Box
          sx={{
            p: 2,
            background: (theme) => alpha(theme.palette.primary.light, 0.05),
            borderRadius: '20px',
            height: 'max-content'
          }}
        >
          <Stack display={'flex'} flexDirection={'row'}>
            <Tooltip title={t`Total et progression sur les 30 derniers jours`}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row'
                }}
              >
                <NetworkIcon networkName={type} displayTitle />
                <Typography sx={{ pl: 1, fontSize: 36, fontWeight: 700 }}>
                  {new Intl.NumberFormat().format(likesCount)}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Typography
                    sx={{
                      pl: 1,
                      fontSize: 18,
                      fontWeight: 700,
                      color: (theme) =>
                        likesProgression === null
                          ? theme.palette.text.primary
                          : likesProgression > 0
                          ? theme.palette.success.main
                          : likesProgression === 0
                          ? theme.palette.text.primary
                          : theme.palette.error.main
                    }}
                  >
                    {likesProgression === null
                      ? '--'
                      : likesProgression > 0
                      ? '+' + likesProgression
                      : likesProgression === 0
                      ? '--'
                      : likesProgression}
                  </Typography>
                </Box>
              </Box>
            </Tooltip>
          </Stack>
        </Box>
      </Paper>
    </Grid>
  );
}

export default BoxAnalytics;
