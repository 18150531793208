// Common
import { request } from '../../common';

interface RequestRoute {
  organizationId: number;
}

export const domainTech = (route: RequestRoute): Promise<Response> =>
  request({
    method: 'GET',
    api: `/api/organizations/tech?domain=eugeka.com`,
    authorized: true
  });

interface Response {
  data: {};
  errors: any[];
}
