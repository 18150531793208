import React from 'react';
import { t } from '@lingui/macro';

type QuoteDisplayProps = {
  text: string | undefined;
  isLoading: boolean;
};

const QuoteDisplay: React.FC<QuoteDisplayProps> = ({ text, isLoading }) => {
  return (
    <div>
      <blockquote style={{ fontStyle: 'italic', padding: '10px' }}>
        {isLoading ? (
          <p>{t`Chargement` + '...'}</p>
        ) : (
          text ??
          t`Aucune information disponible pour le moment. Veuillez relancer un audit dans les paramêtres de l'organisation.`
        )}
      </blockquote>
    </div>
  );
};

export default QuoteDisplay;
