import { useState } from 'react';

// Externals
import { t } from '@lingui/macro';
import _ from 'lodash';

// Internals
import { ICustomBlock, UserComponentProps } from '../index';
import { Header } from 'components/legacy/pages/audit/questions/header';
import { postGenerationOptions } from './Models/postGenerationOptions';

// Hooks
import useStorageValueByRef from 'components/legacy/hooks/useStorageValueByRef';
import { useGetOrganizationDetailsQuery } from 'components/legacy/services/organizations';

// Store
import { useLazyGeneratePostFromUrlAndIdeaQuery } from 'components/legacy/services/connectors';
import AiPostsGenerationDesktop from './desktop';
import { Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import AiPostsGenerationMobile from './mobile';
import toast from 'react-hot-toast';
import CopyButton from '../../Copybutton/CopyButton';

interface ComponentProps {
  name: string;
  description: string;
  connectorKey: string;
  questionKey: string;
  tooltip: string;
  isScoreVisible: boolean;
}

const UserComponent = (props: ComponentProps) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));

  const { data: organizationDetails, isLoading: loadingOrganizationDetails } = useGetOrganizationDetailsQuery({
    id: organizationId
  });

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [isPostGenerating, setIsPostGenerating] = useState<boolean>(false);

  const [subjects] = useStorageValueByRef<string[]>(
    'aiconversation:post-ideas-generate-from-url',
    organizationDetails?.data?.organization?.website
  );

  const postLengths = {
    VeryShort: t`Très court`,
    Short: t`Court`,
    Medium: t`Moyen`,
    Long: t`Long`,
    VeryLong: t`Très long`
  };

  const nbHashtags = {
    // None: t`Aucun`, // The generation always adds hashtags even if we don't ask for them
    Few: t`Peu`,
    Medium: t`Moyen`,
    Many: t`Beaucoup`
  };

  const tonalities = {
    CasualProfessional: t`Professionnel mais détendu`,
    Factual: t`Informatif et factuel`,
    Friendly: t`Amical et familier`,
    Funny: t`Drôle et humoristique`,
    Inspiring: t`Enthousiaste et inspirant`,
    Original: t`Original et inattendu`,
    Serious: t`Très sérieux et formel`
  };

  const translationLanguages = {
    Dutch: t`néerlandais`,
    English: t`anglais`,
    French: t`français`,
    German: t`allemand`,
    Italian: t`italien`,
    Portuguese: t`portugais`,
    Spanish: t`espagnol`
  };

  const [
    generatePostFromUrlAndIdea,
    { isLoading: isLoadingGeneratePostFromUrlAndIdea, data: generatedPostFromUrlAndIdea }
  ] = useLazyGeneratePostFromUrlAndIdeaQuery();

  const [options, SetOption] = useState<postGenerationOptions>({
    nbHashtags: Object.keys(nbHashtags)[Object.values(nbHashtags).indexOf(nbHashtags.Few)],
    postLengths: Object.keys(postLengths)[Object.values(postLengths).indexOf(postLengths.Short)],
    tonality: Object.keys(tonalities)[Object.values(tonalities).indexOf(tonalities.Factual)],
    translationLanguages:
      Object.keys(translationLanguages)[Object.values(translationLanguages).indexOf(translationLanguages.French)],
    subject: ''
  });

  const handlerSendGenerationQuery = async () => {
    if (options.subject && !_.isEmpty(options.subject)) {
      try {
        setIsPostGenerating(true);

        await generatePostFromUrlAndIdea({
          subject: encodeURIComponent(options.subject),
          url: encodeURIComponent(organizationDetails?.data?.organization?.website),
          nbHashTags: encodeURIComponent(options.nbHashtags),
          tonality: encodeURIComponent(options.tonality),
          postLength: encodeURIComponent(options.postLengths),
          language: encodeURIComponent(options.translationLanguages)
        });
      } catch (ex) {
        console.error('handlerSendGenerationQuery exception', ex);
      }
      setIsPostGenerating(false);
    } else {
      toast.error(t`Veuillez choisir un des sujets dans la liste`);
    }
  };

  return (
    <>
      <Header
        isScoreVisible={false}
        label={props.description}
        organizationId={organizationId}
        title={props.name}
        tooltip={props.tooltip}
      />
      {!isMobile && (
        <AiPostsGenerationDesktop
          subjects={subjects}
          handlerSendGenerationQuery={handlerSendGenerationQuery}
          isPostGenerating={isPostGenerating || isLoadingGeneratePostFromUrlAndIdea}
          options={options}
          SetOption={SetOption}
          postLengths={postLengths}
          nbHashtags={nbHashtags}
          tonalities={tonalities}
          translationLanguages={translationLanguages}
        ></AiPostsGenerationDesktop>
      )}
      {isMobile && (
        <AiPostsGenerationMobile
          subjects={subjects}
          handlerSendGenerationQuery={handlerSendGenerationQuery}
          isPostGenerating={isPostGenerating || isLoadingGeneratePostFromUrlAndIdea}
          options={options}
          SetOption={SetOption}
          postLengths={postLengths}
          nbHashtags={nbHashtags}
          tonalities={tonalities}
          translationLanguages={translationLanguages}
        ></AiPostsGenerationMobile>
      )}
      <Stack direction="column" spacing={2} sx={{ alignItems: 'start', display: 'flex', mb: 2 }}>
        {generatedPostFromUrlAndIdea && generatedPostFromUrlAndIdea.post && (
          <>
            <Typography>{generatedPostFromUrlAndIdea.post}</Typography>
            <CopyButton textToCopy={generatedPostFromUrlAndIdea.post} />
          </>
        )}
      </Stack>
    </>
  );
};

class AiPostsGeneration implements ICustomBlock {
  name = () => t`Generation de publication`;
  description = () => '';
  key = () => 'ai-posts-generation';
  tooltip = () => '';
  isScoreVisible = () => false;
  UserComponent: React.FC<UserComponentProps> = (props) => (
    <UserComponent
      connectorKey={this.key()}
      description={this.description()}
      isScoreVisible={this.isScoreVisible()}
      name={this.name()}
      questionKey={props.questionKey}
      tooltip={this.tooltip()}
    />
  );
}
export default AiPostsGeneration;
