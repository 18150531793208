import { useEffect, useState } from 'react';

// Internals
import { ICustomBlock, UserComponentProps } from '../index';
import { Header } from 'components/legacy/pages/audit/questions/header';
import BoxAnalytics from './boxAnalytics';

// Externals
import { t } from '@lingui/macro';
import {
  Box,
  CircularProgress,
  Grid,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  useTheme
} from '@mui/material';
import ReactECharts from 'echarts-for-react';

// Services
import { useGetKeyFigureQuery } from 'components/legacy/services/socialData';
import useSocialDataHistory from 'components/legacy/hooks/useSocialDataHistory';

interface ComponentProps {
  name: string;
  description: string;
  connectorKey: string;
  questionKey: string;
  tooltip: string;
}

const UserComponent = ({ name, description, connectorKey, questionKey, tooltip }: ComponentProps) => {
  const theme = useTheme();

  const organizationId = Number(window.localStorage.getItem('organizationId'));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const betweenSmallAndMd = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'md'));
  const betweenMdAndLg = useMediaQuery((theme: Theme) => theme.breakpoints.between('md', 'lg'));
  const [is30, setis30] = useState(false);
  const [alignment, setAlignment] = useState<any>('90days');

  // get some date for the chart
  const [, arrayData] = useSocialDataHistory({ days: is30 ? 30 : 90, property: 'followers' }, organizationId);
  let color = [];

  const [haveDataToDisplay, setHaveDataToDisplay] = useState(false);
  const [fbLegendVisible, setFbLegendVisible] = useState<any>();
  const [igLegendVisible, setIgLegendVisible] = useState<any>();
  const [ytLegendVisible, setYtLegendVisible] = useState<any>();
  const [liLegendVisible, setLiLegendVisible] = useState<any>();

  const { data: fb } = useGetKeyFigureQuery({ socialNetwork: 'facebook' });
  const { data: ig } = useGetKeyFigureQuery({ socialNetwork: 'instagram' });
  const { data: yt } = useGetKeyFigureQuery({ socialNetwork: 'youtube' });
  const { data: linkedin } = useGetKeyFigureQuery({ socialNetwork: 'linkedin' });

  useEffect(() => {
    (async () => {
      setFbLegendVisible(fb?.data?.keyFigures);
      setIgLegendVisible(ig?.data?.keyFigures);
      setYtLegendVisible(yt?.data?.keyFigures);
      setLiLegendVisible(linkedin?.data?.keyFigures);
    })();
  }, [organizationId, fb, ig, yt, linkedin]);

  if (arrayData === null || arrayData.length <= 1) {
    return (
      <Header
        isScoreVisible={false}
        label={t`Veuillez connecter au moins un de vos réseaux sociaux pour avoir accès au tableau de bord de l'évolution de vos followers.`}
        organizationId={organizationId}
        title={name}
        tooltip={tooltip}
      />
    );
  }

  // eslint-disable-next-line array-callback-return
  arrayData[0].map((element, index) => {
    if (element === 'Facebook')
      fbLegendVisible && (fbLegendVisible.absoluteFollowers || fbLegendVisible.lastThirtyDailyFollowers)
        ? color.splice(index, 0, '#3b5998')
        : color.splice(index, 0, '#CCCCCC');
    if (element === 'Instagram')
      igLegendVisible && (igLegendVisible.absoluteFollowers || igLegendVisible.lastThirtyDailyFollowers)
        ? color.splice(index, 0, '#B908E2')
        : color.splice(index, 0, '#CCCCCC');
    if (element === 'LinkedIn')
      liLegendVisible && (liLegendVisible.absoluteFollowers || liLegendVisible.lastThirtyDailyFollowers)
        ? color.splice(index, 0, '#00AE65')
        : color.splice(index, 0, '#CCCCCC');
    if (element === 'Youtube')
      ytLegendVisible && (ytLegendVisible.absoluteFollowers || ytLegendVisible.lastThirtyDailyFollowers)
        ? color.splice(index, 0, '#FF4545')
        : color.splice(index, 0, '#CCCCCC');
  });

  const handleClick30 = () => {
    setis30(true);
  };

  const handleClick90 = () => {
    setis30(false);
  };

  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    switch (newAlignment) {
      case '30days':
        handleClick30();
        break;
      case '90days':
        handleClick90();
        break;
      default:
        handleClick30();
        break;
    }
    setAlignment(newAlignment);
  };

  //todo : pas oublier changer index QD twitter sera clean
  const option = {
    legend: {
      textStyle: {
        color: theme.palette.text.primary
      }
    },
    color: color,
    smooth: true,
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLabel: {
        rotate: 30
      },
      data: arrayData.slice(1).map((element) => element[0])
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    tooltip: {
      trigger: 'axis'
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        name: 'Facebook',
        type: 'bar',
        stack: 'total',
        label: {
          show: false
        },
        data: arrayData.slice(1).map((element) => element[1])
      },
      {
        name: 'Instagram',
        type: 'bar',
        stack: 'total',
        label: {
          show: false
        },
        data: arrayData.slice(1).map((element) => element[2])
      },
      {
        name: 'Youtube',
        type: 'bar',
        stack: 'total',
        label: {
          show: false
        },
        data: arrayData.slice(1).map((element) => element[3])
      },
      {
        name: 'LinkedIn',
        type: 'bar',
        stack: 'total',
        label: {
          show: false
        },
        data: arrayData.slice(1).map((element) => element[4])
      }
    ]
  };

  return (
    <>
      <Header
        isScoreVisible={false}
        label={description}
        organizationId={organizationId}
        title={name}
        tooltip={tooltip}
      />
      <Grid container spacing={3}>
        {isMobile || betweenSmallAndMd || betweenMdAndLg ? (
          ''
        ) : (
          <>
            <Grid item lg={4}></Grid>
            <Grid item lg={8}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  py: 2,
                  pl: 4,
                  justifyContent: 'start'
                }}
              >
                <ToggleButtonGroup color="primary" exclusive={true} onChange={handleChange} value={alignment}>
                  <ToggleButton value="30days">{t`30 jours`}</ToggleButton>
                  <ToggleButton value="90days">{t`90 jours`}</ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Grid>
          </>
        )}
        <Grid
          item
          lg={4}
          xs={12}
          sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mt: isMobile ? 2 : 0 }}
        >
          <BoxAnalytics type="facebook" setHaveDataToDisplay={setHaveDataToDisplay} />
          <BoxAnalytics type="linkedin" setHaveDataToDisplay={setHaveDataToDisplay} />
          <BoxAnalytics type="instagram" setHaveDataToDisplay={setHaveDataToDisplay} />
          <BoxAnalytics type="youtube" setHaveDataToDisplay={setHaveDataToDisplay} />
        </Grid>
        {!isMobile ? (
          ''
        ) : (
          <>
            <Grid item md={12} xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  pt: 4,
                  justifyContent: 'center'
                }}
              >
                <ToggleButtonGroup color="primary" exclusive={true} onChange={handleChange} value={alignment}>
                  <ToggleButton value="30days">{t`30 jours`}</ToggleButton>
                  <ToggleButton value="90days">{t`90 jours`}</ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Grid>
          </>
        )}
        {betweenSmallAndMd || betweenMdAndLg ? (
          <>
            <Grid item md={12} xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  pt: 4,
                  justifyContent: 'center'
                }}
              >
                <ToggleButtonGroup color="primary" exclusive={true} onChange={handleChange} value={alignment}>
                  <ToggleButton value="30days">{t`30 jours`}</ToggleButton>
                  <ToggleButton value="90days">{t`90 jours`}</ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Grid>
          </>
        ) : (
          ''
        )}
        {arrayData === null ? (
          <CircularProgress />
        ) : (
          <Grid item lg={8} xs={12}>
            <ReactECharts option={option} theme={'westeros'} />
          </Grid>
        )}
      </Grid>

      <Box
        sx={{
          display: 'flex',
          flexDirection: !isMobile ? 'row' : 'column'
        }}
      >
        {!haveDataToDisplay && t`pas de données`}
      </Box>
    </>
  );
};

class SocialNetworkFollowersRecap implements ICustomBlock {
  tooltip = () =>
    t` Les abonnés, appelés également fans ou followers, sont les utilisateurs qui ont décidé de s'abonner, de suivre ou de "liker" votre compte. Pour Youtube, le décompte commence lors de la connexion de votre compte à FreewayTeam.`;
  description = () => '';
  name = () => t`Abonnés à vos pages`;
  key = () => 'socialNetworkFollowers-recap';
  UserComponent: React.FC<UserComponentProps> = (props) => (
    <UserComponent
      connectorKey={this.key()}
      description={this.description()}
      name={this.name()}
      questionKey={props.questionKey}
      tooltip={this.tooltip()}
    />
  );
}

export default SocialNetworkFollowersRecap;
