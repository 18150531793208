// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const districtsApi = createApi({
  reducerPath: 'districtsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API,
    prepareHeaders: () => {
      return baseHeader(true);
    }
  }),
  endpoints: (build) => ({
    getAdminDistricts: build.query({
      query: () => '/api/districts/admin'
    }),
    getAdminDistrictDetails: build.query({
      query: ({ id, languageCode }) => `/api/districts/admin/details/${id}?lg=${languageCode}`
    }),
    getAdminDistrictAllDetails: build.query({
      query: () => `/api/districts/admin/getAll`
    }),
    updateDistrictsValidationTranslation: build.mutation({
      query: (body: { id: string; languageCode: string; isValidate: boolean }) => ({
        url: `/api/districts/translations/validation`,
        method: 'PUT',
        body
      })
    }),
    syncDistrictTranslations: build.mutation({
      query: (body: { translations: { label: string; languageCode: string; districtId: number }[] }) => ({
        url: `/api/districts/translations`,
        method: 'PUT',
        body
      })
    }),
    setDistrictLevelGrid: build.mutation({
      query: (body: { districtId: number; levelGridId: number }) => ({
        url: `/api/districts/set-level-grid`,
        method: 'PUT',
        body
      })
    })
  })
});

export const {
  useGetAdminDistrictAllDetailsQuery,
  useGetAdminDistrictDetailsQuery,
  useGetAdminDistrictsQuery,
  useUpdateDistrictsValidationTranslationMutation,
  useSyncDistrictTranslationsMutation,
  useSetDistrictLevelGridMutation
} = districtsApi;
