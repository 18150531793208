export const baseHeader = (authorized) => {
  const headers = new Headers();
  const srConnectionId = window.sessionStorage.getItem('SR-ConnectionId');
  const organizationId = window.localStorage.getItem('organizationId');
  const languageCode = window.localStorage.getItem('preferred-language');

  headers.set('Accept-Language', languageCode ?? 'fr');
  headers.set('access-control-allow-methods', 'DELETE, GET, POST, PUT, OPTIONS');
  headers.set('x-sr-connection-id', srConnectionId);
  headers.set('x-organization-id', organizationId || '0');
  headers.set('x-ganesha-api-key', process.env.NEXT_PUBLIC_GANESHA_API_KEY);

  if (authorized) {
    const accessToken = window.localStorage.getItem('accessToken');
    if (accessToken && authorized) {
      headers.set('authorization', `Bearer ${accessToken}`);
    } else {
      // Gérer l'absence du jeton ici, si nécessaire
    }
  }
  return headers;
};
