import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

// Internals
import { ICustomBlock, UserComponentProps } from '../index';
import { Header, HeaderBinary } from 'components/legacy/pages/audit/questions/header';
import { emailLeakDetectorProfiles } from 'components/legacy/models/emailLeakDetectorProfiles';
import HaveIBeenPwnedDialogSettings from './settings';
import { QuestionScoreDto } from 'components/legacy/models/questionScore.dto';

// Externals
import { t } from '@lingui/macro';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography
} from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import TuneIcon from '@mui/icons-material/Tune';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Hooks
import useMoment from 'components/legacy/hooks/useMoment';
import useStorageValue from 'components/legacy/hooks/useStorageValue';
import connectorStorageKeys from 'components/legacy/connectors/connectorStorageKeys';

// Redux
import { useSelector } from 'components/legacy/store';

// Services
import { useUpdateResolvedBreachHibpMutation } from 'components/legacy/services/connectors';
import { useGetQuestionScoresQuery } from 'components/legacy/services/questions';
import { useGetUserProfileQuery } from 'components/legacy/services/userProfiles';
import { useGetSingleRoleQuery } from 'components/legacy/services/organization-roles';
import { useGetOrganizationMemberQuery } from 'components/legacy/services/organizationMember';

interface ComponentProps {
  name: string;
  description: string;
  connectorKey: string;
  questionKey: string;
}

export interface BreachDto {
  email: string;
  breaches: {
    email: string;
    isResolved: boolean;
    name: string;
    title: string;
    domain: string;
    breachDate: string;
    addedDate: string;
    modifiedDate: string;
    pwnCount: number;
    description: string;
    dataClasses: string[];
    isVerified: boolean;
    isFabricated: boolean;
    isSensitive: boolean;
    isRetired: boolean;
    isSpamList: boolean;
    logoPath: string;
  }[];
}

export interface ResolvedDto {
  email: string;
  breachName: string;
  status: boolean;
}

const BREACHES_RESOLVED = 'haveibeenpwned:breaches-resolved';

const UserComponent = ({ name, description, connectorKey, questionKey }: ComponentProps) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));
  const { data: userProfile, isLoading: loadingUserProfile } = useGetUserProfileQuery({});
  const { data: role, isLoading: loadingRoleAndPermissions } = useGetOrganizationMemberQuery({
    organizationId: userProfile?.data?.userProfile?.currentOrganizationId,
    userId: userProfile?.data?.userProfile?.id
  });
  const { data: roleDetail, isLoading: loadingRoleDetail } = useGetSingleRoleQuery({
    roleId: role?.data?.member?.roleId
  });

  const { auditKey } = useParams();

  const { data: scoresQuery, isLoading: loadingScores } = useGetQuestionScoresQuery({
    organizationId,
    auditKey
  });

  const scores: QuestionScoreDto[] = useSelector<QuestionScoreDto[]>((state) =>
    scoresQuery?.data?.questions?.filter((s) => s.key === questionKey)
  );
  const score = _.sumBy(scores, (s) => s.score);

  const [breachesData] = useStorageValue<Array<BreachDto>>(connectorStorageKeys.hibp.breachesData, organizationId);
  const [emailLeakDetectorProfiles] = useStorageValue<emailLeakDetectorProfiles>(
    connectorStorageKeys.emailLeakDetector.profiles,
    organizationId
  );
  const moment = useMoment();

  const [breachesResolved] = useStorageValue<Array<ResolvedDto>>(BREACHES_RESOLVED, organizationId);

  const [updateResolvedBreachHibp] = useUpdateResolvedBreachHibpMutation();

  const handleChange =
    (email: string, breachName: string, status: boolean) =>
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      updateResolvedBreachHibp({
        organizationId: organizationId,
        email: email,
        breachName: breachName,
        status: status
      });
    };

  const handlePanelChange = (userIndex: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    if (userIndex === expanded) {
      setExpanded(-1);
    } else {
      setExpanded(userIndex);
    }
  };

  const [expanded, setExpanded] = React.useState<number>(-1);
  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters={true} elevation={0} square={false} {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  }));

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [emailsList, setEmailsList] = useState<BreachDto[]>([]);

  useEffect(() => {
    if (
      breachesData?.value !== null &&
      breachesData?.value !== undefined &&
      emailLeakDetectorProfiles?.value?.response !== null &&
      emailLeakDetectorProfiles?.value?.response !== undefined
    ) {
      setEmailsList(
        breachesData?.value
          .filter(
            (b) =>
              emailLeakDetectorProfiles.value.response.filter((r) => r.email === b.email && r.isToBeTreated).length > 0
          )
          .sort((a, b) => a.email.localeCompare(b.email)) ?? []
      );
    }
  }, [breachesData, emailLeakDetectorProfiles]);

  if (breachesData.loading || breachesResolved.loading || emailLeakDetectorProfiles.loading) {
    return (
      <>
        <Header isScoreVisible={true} label={description} organizationId={organizationId} score={score} title={name} />
        <CircularProgress />
      </>
    );
  }

  //init breachesResolved.value with empty element.
  if (!breachesResolved.value) {
    breachesResolved.value = [];
  }

  return (
    <>
      <HaveIBeenPwnedDialogSettings
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        organizationId={organizationId}
      />
      {emailsList.length <= 0 ? (
        <>
          <HeaderBinary
            isScoreVisible={true}
            label={t`Les adresses email de cette société n'apparaissent dans aucune brèche de sécurité connue.`}
            organizationId={organizationId}
            score={score}
            title={name}
            value={0}
          />
          <Button
            color="primary"
            variant="contained"
            sx={{ my: 2 }}
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            <TuneIcon sx={{ color: (theme) => theme.palette.secondary.main }} />
          </Button>
        </>
      ) : (
        <>
          {(breachesResolved?.value?.reduce((accumulator: number, e) => (accumulator += e.status ? 1 : 0), 0) ?? 0) ===
          emailsList.reduce((accumulator: number, e) => (accumulator += e.breaches.length), 0) ? (
            <>
              <HeaderBinary
                isScoreVisible={true}
                label={t`Vous avez corrigé toutes les brèches de sécurité.
                Vous pouvez les passer en revue ci-dessous.`}
                organizationId={organizationId}
                score={score}
                title={name}
                value={0}
              />
            </>
          ) : (
            <HeaderBinary
              isScoreVisible={true}
              label={t`Des informations liées à une ou plusieurs adresses email ont été compromises suite à des brèches de sécurité.
            Il est vivement conseillé de changer les mots de passe concernés. (sélectionnez celles que vous avez déjà réglée)`}
              organizationId={organizationId}
              score={score}
              title={name}
              value={1}
            />
          )}

          <Button
            color="primary"
            variant="contained"
            sx={{ my: 2 }}
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            <TuneIcon sx={{ color: (theme) => theme.palette.secondary.main }} />
          </Button>

          {emailsList.map((user, userIndex) => (
            <Accordion expanded={expanded === userIndex} onChange={handlePanelChange(userIndex)} key={user.email}>
              <AccordionSummary aria-controls="panel1bh-content" expandIcon={<ExpandMoreIcon />} id="panel1bh-header">
                <Typography variant="body1" sx={{ mr: 3 }}>
                  {user.email}
                </Typography>
                {user.breaches.length -
                  breachesResolved?.value?.filter((b) => b.email === user.email && b.status).length >
                  0 && (
                  <Typography variant="body1">
                    ({t`brèches ouvertes`}
                    {': '}
                    {user.breaches.length -
                      breachesResolved?.value?.filter((b) => b.email === user.email && b.status).length}
                    )
                  </Typography>
                )}
              </AccordionSummary>
              <AccordionDetails>
                {user.breaches
                  .sort((a, b) => {
                    let first = !breachesResolved?.value?.some(
                      (r) => r.breachName === a.name && r.email === a.email && r.status === true
                    );
                    let second = !breachesResolved?.value?.some(
                      (r) => r.breachName === b.name && r.email === b.email && r.status === true
                    );

                    if (first === second) {
                      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                    }

                    return first ? -1 : 1;
                  })
                  .map((breach, breachIndex) => {
                    let resolved = breachesResolved?.value?.find(
                      (b) => b.breachName === breach.name && b.email === breach.email
                    );
                    return (
                      <Box key={`${user.email}${breach.name}`}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={resolved?.status ?? false}
                              sx={{ color: (theme) => theme.palette.primary.main }}
                              onChange={handleChange(breach.email, breach.name, !resolved?.status ?? true)}
                            />
                          }
                          label={`${breach.title} ${breach.domain ? `(${breach.domain})` : ''}`}
                          labelPlacement="end"
                          sx={{
                            ml: 0,
                            mb: -1,
                            textDecoration: resolved?.status ?? false ? ' line-through' : ''
                          }}
                          value={breachIndex}
                        />
                        {breach.dataClasses.map((dataClass, dataClassIndex) => (
                          <Box key={`${user.email}${breach.name}${dataClass}`}>
                            {dataClassIndex ? ' / ' : ''}
                            <Typography
                              sx={{
                                textDecoration: resolved?.status ?? false ? ' line-through' : ''
                              }}
                              variant="caption"
                            >
                              {dataClass}
                            </Typography>
                          </Box>
                        ))}
                        <Typography
                          sx={{
                            textDecoration: resolved?.status ?? false ? ' line-through' : ''
                          }}
                          variant="caption"
                        >
                          {t`Date de la brèche :`}{' '}
                          <span title={moment(breach.breachDate).format('LL')}>
                            {moment(breach.breachDate).fromNow()}
                          </span>
                        </Typography>
                      </Box>
                    );
                  })}
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      )}
    </>
  );
};

class HIBPRecap implements ICustomBlock {
  description = () => t`Affiche la liste des brèches dans lesquelles se trouve l'email de l'utilisateur`;
  name = () => t`Vulnérabilité de vos informations`;
  key = () => 'hibp-recap';
  UserComponent: React.FC<UserComponentProps> = (props) => (
    <UserComponent
      connectorKey={this.key()}
      description={this.description()}
      name={this.name()}
      questionKey={props.questionKey}
    />
  );
}

export default HIBPRecap;
function setEmailsList(arg0: BreachDto[]) {
  throw new Error('Function not implemented.');
}
