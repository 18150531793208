// Externals
import { t } from '@lingui/macro';
import { CircularProgress } from '@mui/material';

// Internals
import { ICustomBlock, UserComponentProps } from '../../index';
import { Header } from 'components/legacy/pages/audit/questions/header';
import TableAccomplishment from './tableAccomplishment';

// Redux
import { useSelector } from 'components/legacy/store';

interface ComponentProps {
  name: string;
  description: string;
  connectorKey: string;
  questionKey: string;
  tooltip: string;
  isScoreVisible: boolean;
}

const UserComponent = (props: ComponentProps) => {
  const loading = false;
  const organizationId = Number(window.localStorage.getItem('organizationId'));

  if (loading) {
    return (
      <>
        <Header
          isScoreVisible={props.isScoreVisible}
          label={props.description}
          organizationId={organizationId}
          title={props.name}
          tooltip={props.tooltip}
        />
        <CircularProgress />
      </>
    );
  }

  return (
    <>
      <Header
        isScoreVisible={props.isScoreVisible}
        label={props.description}
        organizationId={organizationId}
        title={props.name}
        tooltip={props.tooltip}
      />
      <TableAccomplishment></TableAccomplishment>
    </>
  );
};

class TaskItemsList implements ICustomBlock {
  name = () => t`Accomplissements`;
  description = () => t``;
  key = () => 'taskitem-accomplishment';
  tooltip = () => t``;
  isScoreVisible = () => false;
  UserComponent: React.FC<UserComponentProps> = (props) => (
    <UserComponent
      connectorKey={this.key()}
      description={this.description()}
      isScoreVisible={this.isScoreVisible()}
      name={this.name()}
      questionKey={props.questionKey}
      tooltip={this.tooltip()}
    />
  );
}
export default TaskItemsList;
