import React from 'react';
import { useEffect, useState } from 'react';

// Internals
import { ICustomBlock, UserComponentProps } from '../../index';
import { Header } from 'components/legacy/pages/audit/questions/header';
import * as StorageDataKeys from 'components/legacy/components/blocks/google/constants';
import { ComponentProps } from '../../google/connect/models';

// Externals
import _ from 'lodash';
import { t } from '@lingui/macro';
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useParams } from 'react-router-dom';
import ReactECharts from 'echarts-for-react';

// models
import { QuestionScoreDto } from 'components/legacy/models/questionScore.dto';

// Stores
import { useSelector } from 'components/legacy/store';

import { StorageValue } from 'components/legacy/hooks/useStorageValue';
import useStorageValueCollection, { Query } from 'components/legacy/hooks/useStorageValueCollection';
import { useGetQuestionScoresQuery } from 'components/legacy/services/questions';

import useMoment from 'components/legacy/hooks/useMoment';

interface IStorageValueCollection extends Query {
  [StorageDataKeys.PROPERTY_ID_GA]: StorageValue<string>;
}

interface IReportData {
  isProgression: boolean;
  value: number;
  progressionValue: number;
  nbDaysPeriode: number;
  textDisplay: string;
}

const UserComponent = ({ name, description, connectorKey, questionKey, isScoreVisible, tooltip }: ComponentProps) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const betweenSmallAndMd = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'md'));
  const betweenMdAndLg = useMediaQuery((theme: Theme) => theme.breakpoints.between('md', 'lg'));

  const moment = useMoment();

  const { auditKey } = useParams();

  const { data: scoresQuery, isLoading: loadingScores } = useGetQuestionScoresQuery({
    organizationId,
    auditKey
  });

  const scores: QuestionScoreDto[] = useSelector<QuestionScoreDto[]>((state) =>
    scoresQuery?.data?.questions?.filter((s) => s.key === questionKey)
  );
  const score = _.sumBy(scores, (s) => s.score);

  const [mapping] = useStorageValueCollection<IStorageValueCollection>(
    [
      StorageDataKeys.PROPERTY_ID_GA,
      StorageDataKeys.LAST_WEEK_DAY_SESSIONS_REPORT_GA,
      StorageDataKeys.LAST_THIRTY_DAY_SESSIONS_REPORT_GA,
      StorageDataKeys.LAST_YEAR_DAY_SESSIONS_REPORT_GA,
      StorageDataKeys.SESSIONS_REPORT_GA
    ],
    organizationId
  );

  const loading =
    mapping[StorageDataKeys.SESSIONS_REPORT_GA].loading ||
    mapping[StorageDataKeys.PROPERTY_ID_GA].loading ||
    mapping[StorageDataKeys.LAST_WEEK_DAY_SESSIONS_REPORT_GA].loading ||
    mapping[StorageDataKeys.LAST_THIRTY_DAY_SESSIONS_REPORT_GA].loading ||
    mapping[StorageDataKeys.LAST_YEAR_DAY_SESSIONS_REPORT_GA].loading;

  const dataReportWeekGA = mapping[StorageDataKeys.LAST_WEEK_DAY_SESSIONS_REPORT_GA].value;
  const dataReportThirtyGA = mapping[StorageDataKeys.LAST_THIRTY_DAY_SESSIONS_REPORT_GA].value;
  const dataReportYearGA = mapping[StorageDataKeys.LAST_YEAR_DAY_SESSIONS_REPORT_GA].value;
  const dataReportGA = mapping[StorageDataKeys.SESSIONS_REPORT_GA].value;

  const selectedGA = mapping[StorageDataKeys.PROPERTY_ID_GA];

  const [dataReport, setDataReport] = useState<IReportData[]>();
  const [categories, setCategories] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [alignment, setAlignment] = useState('365');

  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setAlignment(newAlignment);
  };

  useEffect(() => {
    if (selectedGA.value && dataReportWeekGA) {
      let allData: IReportData[] = [];
      allData.push(GetReportData(dataReportWeekGA));
      allData.push(GetReportData(dataReportThirtyGA));
      allData.push(GetReportData(dataReportYearGA));
      setDataReport(allData);
    }

    if (dataReportGA) {
      const categoriesTmp = [];
      const dataTmp = [];
      dataReportGA?.slice(1, alignment).map((element) => {
        categoriesTmp.push(moment(element[0]).format('DD/MM/YY'));
        dataTmp.push(element[1]);
      });
      setCategories(categoriesTmp.reverse());
      setData(dataTmp.reverse());
    }
  }, [dataReportGA, dataReportThirtyGA, dataReportWeekGA, dataReportYearGA, selectedGA, alignment]);

  const option = {
    color: ['rgb(77, 119, 255)'],
    smooth: true,
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: categories,
      axisLabel: {
        rotate: 30
      }
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    tooltip: {
      trigger: 'axis'
    },
    yAxis: {
      type: 'value',
      height: '400px'
    },
    series: [
      {
        data: data,
        type: 'line',
        showSymbol: false,
        stack: 'Total'
      }
    ]
  };

  if (loading) {
    return (
      <>
        <Header
          isScoreVisible={isScoreVisible}
          label={description}
          organizationId={organizationId}
          score={score}
          title={name}
          tooltip={tooltip}
        />
        <CircularProgress />
      </>
    );
  }

  if (dataReport) {
    return (
      <>
        <Header
          isScoreVisible={isScoreVisible}
          label={description}
          organizationId={organizationId}
          score={score}
          title={name}
          tooltip={tooltip}
        />

        <Grid container spacing={3}>
          {isMobile || betweenSmallAndMd || betweenMdAndLg ? (
            ''
          ) : (
            <>
              <Grid lg={4}></Grid>
              <Grid lg={8}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    py: 2,
                    pl: 4,
                    justifyContent: 'start'
                  }}
                >
                  <ToggleButtonGroup color="primary" exclusive={true} onChange={handleChange} value={alignment}>
                    <ToggleButton value="30">{t`30 jours`}</ToggleButton>
                    <ToggleButton value="90">{t`90 jours`}</ToggleButton>
                    <ToggleButton value="365">{t`365 jours`}</ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Grid>
            </>
          )}
          <Grid
            item
            lg={4}
            xs={12}
            sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mt: isMobile ? 2 : 0 }}
          >
            {dataReport.map((element, index) =>
              element ? (
                <Grid sx={{ mb: 2, width: '100%' }} key={element.nbDaysPeriode + index} item={true}>
                  <Paper
                    elevation={3}
                    sx={{
                      position: 'relative',
                      height: '100%',
                      background: '#FBF9FF',
                      borderRadius: '20px',
                      breakInside: 'avoid',
                      p: 2
                    }}
                  >
                    <Typography sx={{ textAlign: 'center', pt: 1, fontWeight: 400 }} variant="h6">
                      {index === 0 ? '7' : index === 1 ? '30' : index === 2 ? '365' : ''} {t`derniers jours`}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'row'
                      }}
                    >
                      <Typography
                        sx={{
                          color: (theme) => theme.palette.primary.main,
                          textAlign: 'center',
                          fontFamily: 'Inter, sans-serif',
                          fontSize: 32,
                          fontStyle: 'normal',
                          fontWeight: 500,
                          letterSpacing: 'normal'
                        }}
                      >
                        {new Intl.NumberFormat().format(element.value)}
                      </Typography>
                      <Typography
                        sx={{
                          ml: 1,
                          textAlign: 'center',
                          fontFamily: 'Inter, sans-serif',
                          fontSize: 20,
                          fontStyle: 'normal',
                          fontWeight: 500,
                          letterSpacing: 'normal',

                          color: (theme) =>
                            element.progressionValue === null
                              ? theme.palette.text.primary
                              : element.progressionValue > 0
                              ? theme.palette.success.main
                              : element.progressionValue === 0
                              ? theme.palette.text.primary
                              : element.progressionValue < 0
                              ? theme.palette.error.main
                              : theme.palette.error.main
                        }}
                      >
                        {element.progressionValue === null
                          ? '--'
                          : element.progressionValue > 0
                          ? '+' + new Intl.NumberFormat().format(element.progressionValue)
                          : element.progressionValue === 0
                          ? '--'
                          : element.progressionValue < 0
                          ? new Intl.NumberFormat().format(element.progressionValue)
                          : new Intl.NumberFormat().format(element.progressionValue)}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              ) : (
                ''
              )
            )}
          </Grid>
          {!isMobile ? (
            ''
          ) : (
            <>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    pt: 4,
                    justifyContent: 'center'
                  }}
                >
                  <ToggleButtonGroup color="primary" exclusive={true} onChange={handleChange} value={alignment}>
                    <ToggleButton value="30">{t`30 jours`}</ToggleButton>
                    <ToggleButton value="90">{t`90 jours`}</ToggleButton>
                    <ToggleButton value="365">{t`365 jours`}</ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Grid>
            </>
          )}
          {betweenSmallAndMd || betweenMdAndLg ? (
            <>
              <Grid item md={12} xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    pt: 4,
                    justifyContent: 'center'
                  }}
                >
                  <ToggleButtonGroup color="primary" exclusive={true} onChange={handleChange} value={alignment}>
                    <ToggleButton value="30">{t`30 jours`}</ToggleButton>
                    <ToggleButton value="90">{t`90 jours`}</ToggleButton>
                    <ToggleButton value="365">{t`365 jours`}</ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Grid>
            </>
          ) : (
            ''
          )}

          {dataReportGA?.length > 0 ? (
            <Grid item lg={8} xs={12}>
              <ReactECharts option={option} />
            </Grid>
          ) : (
            <Typography>{t`Aucune données pour le moment.`}</Typography>
          )}
        </Grid>
      </>
    );
  }
  return (
    <>
      <Header
        isScoreVisible={isScoreVisible}
        label={description}
        organizationId={organizationId}
        title={name}
        tooltip={tooltip}
      />
      <Typography>{t`Aucune données pour le moment.`}</Typography>
    </>
  );
};

export const GetReportData = (data) => {
  if (!data) return;
  var date1 = new Date(data.start);
  var date2 = new Date(); // new Date(data.end);
  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);

  // To calculate the time difference of two dates
  var Difference_In_Time = date2.getTime() - date1.getTime();

  // To calculate the no. of days between two dates
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  let label: string = '';
  switch (Difference_In_Days) {
    case 365:
      label = t`Les 12 derniers mois`;
      break;
    default:
      label = t`Les` + Difference_In_Days + t`derniers jours`;
      break;
  }

  let signDisplay = data.sessionsProgression > 0 ? '+' : '';

  label = `${label} : ${data.sessions} (${signDisplay}${data.sessionsProgression})`;

  const result: IReportData = {
    isProgression: data.sessionsProgression >= 0 ? true : false,
    value: data.sessions,
    progressionValue: data.sessionsProgression,
    nbDaysPeriode: Difference_In_Days,
    textDisplay: label
  };
  return result;
};

class GoogleGA4Sessions implements ICustomBlock {
  name = () => t`Trafic du site - Google Analytics`;
  description = () => t``;
  tooltip = () =>
    t`Une session est une visite d'un utilisateur sur votre page. Si une personne vient 5 fois sur votre site, ça comptera pour 5 sessions. S'il vient 1 fois mais qu'il visite 5 pages durant sa visite, cela ne comptera que pour 1 session. La progression affichée est par rapport à la période précédente d'une même durée, si l'information est accessible.`;
  isScoreVisible = () => true;
  key = () => 'google-ga-sessions';
  UserComponent: React.FC<UserComponentProps> = (props) => (
    <UserComponent
      connectorKey={this.key()}
      description={this.description()}
      isScoreVisible={this.isScoreVisible()}
      name={this.name()}
      questionKey={props.questionKey}
      tooltip={this.tooltip()}
    />
  );
}

export default GoogleGA4Sessions;
