// Common
import { request } from '../common';

interface requestParams {
  pageSize: number;
  pageNumber: number;
  sortColumn: string;
  sortOrder: 'asc' | 'desc';
  filterSelected: string[];
}

export const GetSocialPublicationData = (requestParams: requestParams): Promise<Response> =>
  request({
    method: 'GET',
    api: `/api/social-publication-statistic/publications`,
    params: {
      pageSize: requestParams.pageSize,
      pageNumber: requestParams.pageNumber,
      orderBy: requestParams.sortColumn,
      IsDescending: requestParams.sortOrder === 'desc',
      filterSelected: requestParams.filterSelected
    },
    authorized: true
  })
    .then((response) => response.data)
    .catch((error) => ({ data: { value: [] }, errors: [error.message] }));
interface Response {
  data: any;
  errors: string[];
}
