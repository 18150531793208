// This list of severities comes from
//https://csrc.nist.gov/schema/nvd/api/2.0/external/cvss-v3.1.json
//https://csrc.nist.gov/schema/nvd/api/2.0/external/cvss-v3.0.json
//
// CTRL+F for "severityType" to find the list of severities types

export enum Severity {
  CRITICAL = 'CRITICAL',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
  NONE = 'NONE'
}

export default Severity;
