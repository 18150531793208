import { useState } from 'react';

// Externals
import { alpha, Box, Collapse, Link, List, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore, Update, Visibility } from '@mui/icons-material';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { t } from '@lingui/macro';

// Stores
import { useDispatch, useSelector } from 'components/legacy/store';
import { usePostponeTaskItemMutation } from 'components/legacy/services/organizations';
import { taskItemApi } from 'components/legacy/services/taskitems';

// Slices

const TableTaskTodo = (props) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));

  let userLanguage = window.localStorage.getItem('preferred-language');
  if (userLanguage === undefined) userLanguage = props.lang;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(-1);

  const [postponeTaskItem] = usePostponeTaskItemMutation();

  const handleSave = async (key: string) => {
    try {
      postponeTaskItem({ taskItemKey: key, organizationId });
      dispatch(taskItemApi.util.invalidateTags(['taskitems']));
      toast.success(t`Tâche postposée.`);
    } catch (e) {
      toast.error(t`Oups! ça na pas marché.`);
    }
  };

  const handleOpen = (index) => {
    index === open ? setOpen(0) : setOpen(index);
  };

  return (
    <>
      <List aria-labelledby="nested-list-subheader" component="nav" sx={{ width: '100%', borderRadius: '20px' }}>
        {props.rows?.map((row, index) => {
          if (row.publicationStatus.value === 'published' || props.isAdmin) {
            return (
              <Box
                key={row.taskItemKey + index}
                sx={{
                  display: 'flex',
                  borderRadius: '20px',
                  boxShadow: '0px 0px 10px rgba(142, 85, 255, 0.2)',
                  mb: 2,
                  flexDirection: 'column',
                  breakInside: 'avoid'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    borderRadius: '20px',
                    background: (theme) => alpha(theme.palette.primary.main, 0.05),
                    py: 1
                  }}
                >
                  {props.displayLink &&
                    (open === row.index ? (
                      <ExpandLess
                        onClick={() => handleOpen(row.index)}
                        sx={{ cursor: 'pointer', mx: 1, color: (theme) => theme.palette.primary.main }}
                      />
                    ) : (
                      <ExpandMore
                        onClick={() => handleOpen(row.index)}
                        sx={{ cursor: 'pointer', mx: 1, color: (theme) => theme.palette.primary.main }}
                      />
                    ))}

                  {props.displayLink && (
                    <ListItemText
                      onClick={() => handleOpen(row.index)}
                      primary={row.translations.find((x) => x.languageCode === userLanguage)?.title ?? ''}
                      primaryTypographyProps={{ fontWeight: props.displayLink ? '500' : 'bold' }}
                      sx={{ cursor: props.displayLink ? 'pointer' : 'default', pl: 2 }}
                    />
                  )}
                  {/* email report - display link instead of Collapsed texte*/}
                  {!props.displayLink && (
                    <Link
                      href="http://www.freewayteam.com/city/dashboard/kpi"
                      sx={{ textAlign: 'center', fontSize: '1.3em' }}
                    >
                      <ListItemText
                        onClick={() => handleOpen(row.index)}
                        primary={row.translations.find((x) => x.languageCode === userLanguage)?.title ?? ''}
                        primaryTypographyProps={{ fontWeight: props.displayLink ? '500' : 'bold' }}
                        sx={{ cursor: props.displayLink ? 'pointer' : 'default', pl: 2 }}
                      />
                    </Link>
                  )}
                  {props.displayLink && (
                    <Update
                      onClick={() => handleSave(row.taskItemKey)}
                      sx={{ cursor: 'pointer', marginRight: 2, color: (theme) => theme.palette.primary.main }}
                      titleAccess={t`Reporter à plus tard`}
                    />
                  )}
                  {props.displayLink && (
                    <Visibility
                      onClick={() => navigate(row.url)}
                      sx={{ cursor: 'pointer', marginRight: 2, color: (theme) => theme.palette.primary.main }}
                      titleAccess={t`Voir la question liée`}
                    />
                  )}
                </Box>
                {props.displayLink && (
                  <Collapse in={open === row.index} sx={{ pl: 2, pb: 4, pt: 2 }} timeout="auto" unmountOnExit={true}>
                    <ReactMarkdown>
                      {row.translations.find((x) => x.languageCode === userLanguage)?.description ?? ''}
                    </ReactMarkdown>
                  </Collapse>
                )}
              </Box>
            );
          }
          return '';
        })}
      </List>
    </>
  );
};

export default TableTaskTodo;
