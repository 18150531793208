// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const questsApi = createApi({
  reducerPath: 'questsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API,
    prepareHeaders: () => {
      return baseHeader(true);
    }
  }),
  tagTypes: ['organization-quests', 'ignored-quests', 'history-quest'],
  endpoints: (build) => ({
    getOrganizationQuestItems: build.query({
      query: () => `/api/quests/details`,
      providesTags: ['organization-quests']
    }),
    getQuests: build.query({
      query: ({ languageCode }) => `/api/quests/getall?lg=${languageCode}`,
      providesTags: ['organization-quests']
    }),
    getAdminQuest: build.query({
      query: ({ id, languageCode }) => `/api/quests/${id}?lg=${languageCode}`
    }),
    getQuestsSupport: build.query({
      query: () => `/api/quests/getquestforsupport`
    }),
    getNoteSupport: build.query({
      query: ({ organizationId, questDefinitionId }) =>
        `/api/quests/getnotesupport?OrganizationId=${organizationId}&QuestDefinitionId=${questDefinitionId}`
    }),
    getQuestIgnoredCount: build.query({
      query: ({ organizationId }) => `/api/quests/ignoredQuestCount/${organizationId}`
    }),
    getIgnoredQuest: build.query({
      query: ({ questSkip, questTake }) => `/api/quests/ignored/${questSkip}/${questTake}`,
      providesTags: ['ignored-quests']
    }),
    getHistoryQuest: build.query({
      query: ({ pageSkip }) => `/api/quests/getHistoryPage/${pageSkip}/25`,
      providesTags: ['history-quest']
    }),
    getQuestArchievedCount: build.query({
      query: ({ organizationId }) => `/api/quests/archivedQuestCount/${organizationId}`
    }),
    updateQuestValidationTranslation: build.mutation({
      query: (body: { questId: number; languageCode: string; isValidate: boolean }) => ({
        url: `/api/quests/translations/validation`,
        method: 'PUT',
        body
      })
    }),
    updateQuestsIndex: build.mutation({
      query: (body: { indexes: { id: number; index: number }[] }) => ({
        url: `/api/quests/indexes`,
        method: 'PUT',
        body
      })
    }),
    completeQuestSupport: build.mutation({
      query: (body: { id: number; isCompleted: boolean }) => ({
        url: `/api/quests/completesupport`,
        method: 'PUT',
        body
      })
    }),
    syncQuestTranslations: build.mutation({
      query: (body: {
        translations: { questDefinitionId: number; title: string; description: string; languageCode: string }[];
      }) => ({
        url: `/api/quests/translations`,
        method: 'PUT',
        body
      })
    }),
    skipQuest: build.mutation({
      query: (body: { questOccurrencesId: number }) => ({
        url: `/api/quests/skip`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['ignored-quests', 'organization-quests']
    }),
    ignoreQuestOccurrence: build.mutation({
      query: (body: { organizationQuestId: number; isIgnored: boolean }) => ({
        url: `/api/quests/ignore`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['ignored-quests', 'organization-quests']
    }),
    //TODO : pk en POST ?
    getQuestReport: build.mutation({
      query: ({ accessToken, organizationId, languageCode }) => ({
        url: `/api/quests/quest-report/${languageCode}/${organizationId}/${accessToken}`,
        method: 'POST'
      })
    }),
    updateCustomQuest: build.mutation({
      query: (body: { id: number; title: string; description: string }) => ({
        url: `/api/quests/custom`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['organization-quests']
    }),
    updateQuest: build.mutation({
      query: (body: {
        id: number;
        url: string;
        enable: boolean;
        frequencyWeight: number;
        sectionId: number;
        index: number;
        recurrenceMode: string;
        completionMode: string;
      }) => ({
        url: `/api/quests`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['organization-quests']
    }),
    deleteQuestOccurrence: build.mutation({
      query: ({ occurrenceId }) => ({
        url: `/api/quests/deleteOccurrence/${occurrenceId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['organization-quests']
    }),
    deleteQuest: build.mutation({
      query: ({ id }) => ({
        url: `/api/quests/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['organization-quests']
    }),
    updateQuestItemConditions: build.mutation({
      query: (body: {
        //condition type : { visibility: 1; completion; 2 }
        QuestDefinitionId: number;
        conditionType: number;
        conditions: {
          conditionType: number;
          sourceType: string;
          sourceKey: string;
          operator: string;
          target: string;
        }[];
      }) => ({
        url: `/api/quests/conditions`,
        method: 'PUT',
        body
      })
    }),
    completeQuest: build.mutation({
      query: (body: { questOccurrenceId: number; isCompleted: boolean }) => ({
        url: `/api/quests/complete`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['organization-quests']
    }),
    archiveAndRenewQuest: build.mutation({
      query: () => ({
        url: `/api/quests/archiveAndRenew`,
        method: 'POST'
      })
    }),
    addOprhanQuest: build.mutation({
      query: (body: { title: string; description: string }) => ({
        url: `/api/quests/addCustomOccurrence`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['organization-quests']
    }),
    addQuest: build.mutation({
      query: (body: {
        url: string;
        enable: boolean;
        frequencyWeight: number;
        index: number;
        recurrenceMode: string;
        sectionId: number;
        completionMode: 'manual' | 'automatic';
      }) => ({
        url: `/api/quests`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['organization-quests']
    }),
    updateQuestNoteSupport: build.mutation({
      query: (body: { organizationId: number; questDefinitionId: number; noteSupport: string }) => ({
        url: `/api/quests/notesSupport`,
        method: 'PUT',
        body
      })
    }),
    updateQuestNote: build.mutation({
      query: (body: { id: number; note: string }) => ({
        url: `/api/quests/note`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['organization-quests']
    }),
    // todo : elle existe pas ?
    updateQuestAssignementToSupport: build.mutation({
      query: (body: {
        QuestId: number;
        OrganizationId: number;
        OrganizationUrl: string;
        UserEmail: string;
        UserId: string;
        SupportEmail: string;
        QuestName: string;
        PlanName: string;
      }) => ({
        url: `/api/quests/attributeToSupport`,
        method: 'PUT',
        body
      })
    }),
    updateQuestAssignement: build.mutation({
      query: (body: {
        id: number;
        userId?: string;
        userEmail?: string;
        organizationId: number;
        organizationUrl: string;
        supportEmail: string;
        questName: string;
        planName: string;
        askHelpFromSupport?: boolean;
      }) => ({
        url: `/api/quests/attribute`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['organization-quests']
    })
  })
});

export const {
  useAddOprhanQuestMutation,
  useAddQuestMutation,
  useArchiveAndRenewQuestMutation,
  useCompleteQuestMutation,
  useCompleteQuestSupportMutation,
  useDeleteQuestMutation,
  useDeleteQuestOccurrenceMutation,
  useGetAdminQuestQuery,
  useGetHistoryQuestQuery,
  useGetIgnoredQuestQuery,
  useGetNoteSupportQuery,
  useGetQuestArchievedCountQuery,
  useGetQuestIgnoredCountQuery,
  useGetQuestReportMutation,
  useGetQuestsQuery,
  useGetQuestsSupportQuery,
  useIgnoreQuestOccurrenceMutation,
  useUpdateQuestsIndexMutation,
  useUpdateQuestValidationTranslationMutation,
  useUpdateQuestMutation,
  useUpdateQuestItemConditionsMutation,
  useUpdateCustomQuestMutation,
  useSyncQuestTranslationsMutation,
  useSkipQuestMutation,
  useGetOrganizationQuestItemsQuery,
  useUpdateQuestAssignementMutation,
  useUpdateQuestNoteSupportMutation,
  useUpdateQuestNoteMutation,
  useUpdateQuestAssignementToSupportMutation,
  useLazyGetAdminQuestQuery
} = questsApi;
