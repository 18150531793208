// Externals
import _ from 'lodash';
import { Box, Typography } from '@mui/material';
import { t } from '@lingui/macro';

// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';

// Models
import connectorStorageKeys from 'components/legacy/connectors/connectorStorageKeys';
import ReportPartContainer from '../common';

interface IGoogleSearchReportRow {
  keys: string[];
  impressions: number;
  clicks: number;
  ctr: number;
  position: number;
}
interface IGoogleSearchReport {
  rows: IGoogleSearchReportRow[];
}

const ReportGoogleImpressions = (props) => {
  if (props.organizationId === null || props.organizationId === undefined)
    throw new Error('ReportGoogleImpressions organizationid empty');

  const [lastWeekStorage] = useStorageValue<IGoogleSearchReport>(
    connectorStorageKeys.googleSearch.report.lastWeekTotal,
    props.organizationId
  );
  const [previousWeekStorage] = useStorageValue<IGoogleSearchReport>(
    connectorStorageKeys.googleSearch.report.previousWeekTotal,
    props.organizationId
  );

  if (lastWeekStorage.value === null || previousWeekStorage.value === null)
    return (
      <ReportPartContainer displayLink={props.displayLink} route={'/redirect/google-search-stats'}>
        <Typography sx={{ textAlign: 'center', pt: 2 }} variant="h4">
          {t`Google Search`}
        </Typography>
        <Typography sx={{ textAlign: 'center' }}>
          {t`Veuillez connecter ou créer votre compte pour avoir accès au rapport.`}
        </Typography>
      </ReportPartContainer>
    );

  const impressions = _.sumBy(lastWeekStorage.value.rows, (r: IGoogleSearchReportRow) => r.impressions);
  const previousImpressions = _.sumBy(previousWeekStorage.value.rows, (r: IGoogleSearchReportRow) => r.impressions);

  const difference = impressions - previousImpressions;

  return (
    <ReportPartContainer displayLink={props.displayLink} route={'/redirect/google-search-stats'}>
      <Typography sx={{ textAlign: 'center', pt: 2 }} variant="h4">
        {t`Impressions sur Google Search*`}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          py: 2
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            fontFamily: 'Inter, sans-serif',
            fontSize: 58,
            fontStyle: 'normal',
            fontWeight: 700,
            color: (theme) => theme.palette.primary.main,
            letterSpacing: 'normal',
            lineHeight: 50 / 42
          }}
        >
          {new Intl.NumberFormat().format(impressions)}
        </Typography>
        <Typography
          sx={{
            ml: 1,
            textAlign: 'center',
            fontFamily: 'Inter, sans-serif',
            fontSize: 24,
            fontStyle: 'normal',
            fontWeight: 700,
            letterSpacing: 'normal',
            lineHeight: 50 / 42,
            color: (theme) =>
              difference === null
                ? theme.palette.text.primary
                : difference > 0
                ? theme.palette.success.main
                : difference === 0
                ? theme.palette.text.primary
                : difference < 0
                ? theme.palette.error.main
                : theme.palette.error.main
          }}
        >
          {difference === null
            ? '--'
            : difference > 0
            ? '+' + difference
            : difference === 0
            ? '--'
            : difference < 0
            ? difference
            : difference}
        </Typography>
      </Box>
    </ReportPartContainer>
  );
};

export default ReportGoogleImpressions;
