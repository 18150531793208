// hooks
import useStorageValueCollection from './useStorageValueCollection';

// Types
import {
  entityConnectorAuthConfig,
  EntityConnectorKeys,
  IConnectedEntity
} from 'components/legacy/connectors/connectorStorageKeys';
import { useCallback } from 'react';

// Redux
import { useSelector } from 'components/legacy/store';
import { useUpdateSelectedEntityMutation } from '../services/connectors';

const useConnectedEntities = (
  connector: EntityConnectorKeys,
  connectedEntityPropKey: string,
  selectedEntityPropKeys: string
): [boolean, IConnectedEntity[], IConnectedEntity, (entityId: string) => void] => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));

  const entityConnectorAuthConfigConnector = entityConnectorAuthConfig[connector];
  const connectedEntityFullKey = entityConnectorAuthConfigConnector[connectedEntityPropKey];
  const selectedEntityFullKeys = entityConnectorAuthConfigConnector[selectedEntityPropKeys];

  const [mapping] = useStorageValueCollection([connectedEntityFullKey, selectedEntityFullKeys], organizationId);
  const [updateSelectedEntity] = useUpdateSelectedEntityMutation();

  const connectedEntitiesStorage = mapping[connectedEntityFullKey];
  const selectedEntityStorage = mapping[selectedEntityFullKeys];

  const updateEntity = useCallback(
    (entityId: string) => {
      updateSelectedEntity({
        entityId: entityId,
        connector: connector,
        connectorStorageKeys: connectedEntityFullKey.split(':')[1]
      });
    },
    [connector, connectedEntityFullKey]
  );

  if (connectedEntitiesStorage.loading || selectedEntityStorage.loading) {
    return [true, null, null, updateEntity];
  }

  return [false, connectedEntitiesStorage.value, selectedEntityStorage.value, updateEntity];
};

export default useConnectedEntities;
