import * as React from 'react';

// Internals
import { ICustomBlock, UserComponentProps } from '../../index';
import { Header } from 'components/legacy/pages/audit/questions/header';

// Externals
import { t } from '@lingui/macro';
import { CircularProgress, Icon, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';

// Redux
import { useSelector } from 'components/legacy/store';

// Services
import { QuestionScoreDto } from 'components/legacy/models/questionScore.dto';
import _ from 'lodash';
import { useGetQuestionScoresQuery } from 'components/legacy/services/questions';
import { useParams } from 'react-router-dom';

interface ComponentProps {
  name: string;
  description: string;
  toolTip: string;
  questionKey: string;
}

export interface GoogleBusinessProfileCompletenessDTO {
  missing: string;
  gbpSite: string;
  gbpUrl: string;
  gbpPhone: string;
}

const GOOGLE_BUSINESS_PROFILE_COMPLETENESS = 'seo:google-business-profile-completeness';

const UserComponent = ({ name, description, toolTip, questionKey }: ComponentProps) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));

  const { auditKey } = useParams();

  const { data: scoresQuery, isLoading: loadingScores } = useGetQuestionScoresQuery({
    organizationId,
    auditKey
  });

  const scores: QuestionScoreDto[] = useSelector<QuestionScoreDto[]>((state) =>
    scoresQuery?.data?.questions?.filter((s) => s.key === questionKey)
  );

  const score = _.sumBy(scores, (s) => s.score);

  const [gbpCompleteness] = useStorageValue<GoogleBusinessProfileCompletenessDTO>(
    GOOGLE_BUSINESS_PROFILE_COMPLETENESS,
    organizationId
  );

  return (
    <>
      <Header
        isScoreVisible={true}
        label={description}
        organizationId={organizationId}
        score={score}
        title={name}
        tooltip={toolTip}
      />
      {gbpCompleteness.loading && <CircularProgress />}
      {!gbpCompleteness.loading && gbpCompleteness.value && (
        <>
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }}>
                    <Icon
                      sx={{
                        color: (theme) =>
                          gbpCompleteness.value.gbpSite ? theme.palette.green.main : theme.palette.error.main
                      }}
                    >
                      {gbpCompleteness.value.gbpSite ? 'check' : 'close'}
                    </Icon>
                  </TableCell>
                  <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }}>{t`Adresse`}</TableCell>
                  <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }}>{gbpCompleteness.value.gbpSite}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }}>
                    <Icon
                      sx={{
                        color: (theme) =>
                          gbpCompleteness.value.gbpUrl ? theme.palette.green.main : theme.palette.error.main
                      }}
                    >
                      {gbpCompleteness.value.gbpUrl ? 'check' : 'close'}
                    </Icon>
                  </TableCell>
                  <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }}>{t`URL`}</TableCell>
                  <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }}>{gbpCompleteness.value.gbpUrl}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }}>
                    <Icon
                      sx={{
                        color: (theme) =>
                          gbpCompleteness.value.gbpPhone ? theme.palette.green.main : theme.palette.error.main
                      }}
                    >
                      {gbpCompleteness.value.gbpPhone ? 'check' : 'close'}
                    </Icon>
                  </TableCell>
                  <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }}>{t`Téléphone`}</TableCell>
                  <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }}>{gbpCompleteness.value.gbpPhone}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

class GoogleBusinessProfileCompleteness implements ICustomBlock {
  tips = () =>
    t`Compléter au maximum votre profil Google My Business et le garder à jour permet à vos clients de vous trouver ou contacter plus facilement, ainsi que de connaître vos horaires d'ouverture.`;
  description = () => '';
  name = () => t`Profil Google My Business`;
  key = () => 'google-bp-completeness';
  UserComponent: React.FC<UserComponentProps> = (props) => (
    <UserComponent
      description={this.description()}
      name={this.name()}
      toolTip={this.tips()}
      questionKey={props.questionKey}
    />
  );
}

export default GoogleBusinessProfileCompleteness;
