import type { FC } from 'react';

// Externals
import { Box, Grid, Stack, styled, Tooltip, tooltipClasses, TooltipProps, Typography, Zoom } from '@mui/material';
import DynamicText from 'components/legacy/components/dynamic/DynamicText';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import HelpIcon from '@mui/icons-material/Help';
import { Link } from 'react-router-dom';
import { t } from '@lingui/macro';

interface HeaderProps {
  title: string;
  label: string;
  tooltip?: string;
  score?: number;
  isScoreVisible: boolean;
  link?: string;
  organizationId: number;
}

interface HeaderBinaryProps extends HeaderProps {
  value: number;
  organizationId: number;
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    borderRadius: 5,
    fontSize: 14
  }
});

const Header: FC<HeaderProps> = (props) => {
  return (
    <Box
      sx={{
        justifyContent: 'space-between',
        display: 'flex',
        width: '100%',
        mb: 2
      }}
    >
      <Stack spacing={2} sx={{ width: '90%' }}>
        <Stack direction="row" spacing={1}>
          {props.link ? (
            <Link style={{ textDecoration: 'none' }} target="_blank" to={props.link}>
              <Typography sx={{ whiteSpace: 'pre-wrap' }} variant="h5">
                <DynamicText organizationId={props.organizationId} text={props.title} />
              </Typography>
            </Link>
          ) : (
            <Typography sx={{ whiteSpace: 'pre-wrap' }} variant="h5">
              <DynamicText organizationId={props.organizationId} text={props.title} />
            </Typography>
          )}
          {props.tooltip && (
            <CustomWidthTooltip TransitionComponent={Zoom} title={props.tooltip}>
              <HelpIcon color="primary" fontSize="medium" />
            </CustomWidthTooltip>
          )}
        </Stack>
        <Typography sx={{ whiteSpace: 'pre-wrap' }} variant="body2">
          <DynamicText organizationId={props.organizationId} text={props.label} />
        </Typography>
      </Stack>

      {props.isScoreVisible && (
        <Grid
          sx={{
            position: 'relative',
            alignSelf: 'center',
            height: '70px',
            minWidth: '70px'
          }}
        >
          <Box
            sx={{
              background: (theme) => theme.palette.success.main,
              height: '100%',
              width: '100%',
              borderRadius: '35px',
              opacity: '30%'
            }}
          />
          <Typography
            sx={{
              fontSize: 16,
              position: 'absolute',
              top: '40%',
              left: '50%',
              transform: 'translate(-50%,-50%)'
            }}
            variant="overline"
          >
            {Math.round(props.score || 0)}
          </Typography>
          <Typography
            sx={{
              fontSize: 12,
              position: 'absolute',
              top: '60%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              textTransform: 'none'
            }}
          >
            {t`hab.`}
          </Typography>
        </Grid>
      )}
    </Box>
  );
};

const HeaderBinary: FC<HeaderBinaryProps> = (props) => {
  return (
    <Box
      sx={{
        justifyContent: 'space-between',
        display: 'flex',
        width: '100%',
        mb: 2
      }}
    >
      <Stack spacing={3}>
        <Stack alignItems="center" direction="row" spacing={1}>
          <Typography sx={{ whiteSpace: 'pre-wrap' }} variant="h5">
            <DynamicText organizationId={props.organizationId} text={props.title} />
          </Typography>
          {props.tooltip && (
            <CustomWidthTooltip TransitionComponent={Zoom} title={props.tooltip}>
              <HelpIcon color="primary" fontSize="medium" />
            </CustomWidthTooltip>
          )}
        </Stack>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {props.value === 0 && (
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                height: '40px',
                width: '40px',
                minWidth: '40px',
                mr: 2
              }}
            >
              <Box
                sx={{
                  border: 1.5,
                  borderColor: (theme) => theme.palette.success.main,
                  display: 'block',
                  background: (theme) => theme.palette.success.light,
                  height: '100%',
                  width: '100%',
                  borderRadius: '20px'
                }}
              >
                <CheckIcon
                  sx={{
                    fontSize: '30px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                    color: (theme) => theme.palette.success.main
                  }}
                />
              </Box>
            </Box>
          )}
          {props.value === 1 && (
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                height: '40px',
                width: '40px',
                minWidth: '40px',
                mr: 2
              }}
            >
              <Box
                sx={{
                  border: 1.5,
                  borderColor: (theme) => theme.palette.error.main,
                  display: 'block',
                  background: (theme) => theme.palette.error.light,
                  height: '100%',
                  width: '100%',
                  borderRadius: '20px'
                }}
              >
                <CloseIcon
                  sx={{
                    fontSize: '30px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                    color: (theme) => theme.palette.error.main
                  }}
                />
              </Box>
            </Box>
          )}
          <Typography sx={{ whiteSpace: 'pre-wrap' }} variant="body2">
            <DynamicText organizationId={props.organizationId} text={props.label} />
          </Typography>
        </Box>
      </Stack>

      {props.isScoreVisible && (
        <Grid
          sx={{
            position: 'relative',
            alignSelf: 'center',
            height: '70px',
            minWidth: '70px'
          }}
        >
          <Box
            sx={{
              background: (theme) => theme.palette.success.main,
              height: '100%',
              width: '100%',
              borderRadius: '35px',
              opacity: '30%'
            }}
          />
          <Typography
            display="block"
            paragraph={true}
            sx={{
              fontSize: 16,
              position: 'absolute',
              top: '40%',
              left: '50%',
              transform: 'translate(-50%,-50%)'
            }}
            variant="overline"
          >
            {props.score}
          </Typography>
          <Typography
            sx={{
              fontSize: 12,
              position: 'absolute',
              top: '60%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              textTransform: 'none'
            }}
          >
            {t`hab.`}
          </Typography>
        </Grid>
      )}
    </Box>
  );
};

export { Header, HeaderBinary };
