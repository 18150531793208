// Externals
import { t } from '@lingui/macro';
import { ICustomBlock, UserComponentProps } from '..';
import { Header } from 'components/legacy/pages/audit/questions/header';
import { Grid } from '@mui/material';

// Components
import ReportGoogleSessionDuration from './parts/report-google-duration';
import ReportGoogleTopPageViews from './parts/report-google-top-page-views';
import ReportSeoDomainAuthority from './parts/report-domain-authority';
import ReportDomainVulnerabilities from './parts/report-domain-vulnerabilities';
import ReportGoogleImpressions from './parts/report-google-impressions';
import ReportHibpRecap from './parts/report-hibp-recap';
import ReportScore from './parts/report-score';
import ReportQuestions from './parts/report-questions';
import ReportGoogleSessionCount from './parts/report-google-sessions';
import ReportGoogleTopQueries from './parts/report-google-top-5-queries';
import ReportFollowersProgress from './parts/report-followers-progress';
import ReportImpressionsProgress from './parts/report-impressions-progress';
import ReportSocialNetworkConnected from './parts/report-sn-connected';
import ReportDownTimeDuration from './parts/report-downTime-duration';

// Redux
import { useSelector } from 'components/legacy/store';

// Hooks
import useMoment from 'components/legacy/hooks/useMoment';

interface ComponentProps {
  name: string;
  description: string;
  tips?: string;
}

const UserComponent = ({ name, description, tips }: ComponentProps) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));
  const dateNow = Date.now();
  const moment = useMoment();

  name = t`Rapport du ${moment(dateNow).subtract(7, 'days').format('L')} au ${moment(dateNow).format('L')}*`;
  description = t`Comparaison avec les 7 jours précédents cette période.`;
  return (
    <>
      <Header isScoreVisible={false} label={description} organizationId={organizationId} title={name} tooltip={tips} />

      <Grid columns={{ xs: 1, sm: 8, md: 12 }} container={true} spacing={{ xs: 2, md: 3 }}>
        <ReportSocialNetworkConnected organizationId={organizationId} />
        <ReportFollowersProgress organizationId={organizationId} />
        <ReportImpressionsProgress organizationId={organizationId} />
        <ReportHibpRecap organizationId={organizationId} />
        <ReportSeoDomainAuthority organizationId={organizationId} />
        <ReportGoogleImpressions organizationId={organizationId} />
        <ReportGoogleTopQueries organizationId={organizationId} />
        <ReportGoogleSessionCount organizationId={organizationId} />
        <ReportGoogleSessionDuration organizationId={organizationId} />
        <ReportGoogleTopPageViews organizationId={organizationId} />
        {/* <ReportUptime /> */}
        <ReportScore organizationId={organizationId} />
        <ReportQuestions organizationId={organizationId} />
        <ReportDownTimeDuration organizationId={organizationId} />
        <ReportDomainVulnerabilities organizationId={organizationId} />
      </Grid>
    </>
  );
};

class Report implements ICustomBlock {
  description = () => t`Récapitulatif des chiffres clés et conseils.`;
  name = () => t`Rapport`;
  key = () => 'report';
  UserComponent: React.FC<UserComponentProps> = (props) => (
    <UserComponent description={this.description()} name={this.name()} />
  );
}

export default Report;
