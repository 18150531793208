import moment from 'moment';

import 'moment/locale/nl';
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import 'moment/locale/pt';

const useMoment = () => {
  const language = window.localStorage.getItem('preferred-language');
  moment.locale(language ? language : 'fr');

  return moment;
};

export default useMoment;
