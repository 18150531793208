// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const storageUserApi = createApi({
  reducerPath: 'storageUserApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API,
    prepareHeaders: () => {
      return baseHeader(true);
    }
  }),
  endpoints: (build) => ({
    retrieveValueUser: build.mutation({
      query: (body: { organizationId: number; keys: string[] }) => ({
        url: `/api/user-storage/values`,
        method: 'POST',
        body
      })
    })
  })
});

export const { useRetrieveValueUserMutation } = storageUserApi;
