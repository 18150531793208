import { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';

// Services
import { useSignalRContext } from 'components/legacy/components/providers/SignalRProvider';
import { useLazyRetrieveValuesStorageQuery } from '../services/storages';

interface Query {
  [key: string]: StorageValue<any>;
}

const useStorageValueCollection = <T extends Query>(keys: string[], organizationId: number): [T] => {
  // The value to retrieve and subscribe to
  const [values, setValues] = useState<T>(
    _.reduce(keys, (values, key) => ({ ...values, [key]: { key: key, value: null, loading: true } }), {} as T)
  );

  const keysHash = keys.join('');

  // Methods to start and stop subscribing to storage value change
  const { registerStorageHandler, removeStorageHandler } = useSignalRContext();

  const [retrieveValuesLazyQuery] = useLazyRetrieveValuesStorageQuery();

  const retrieveValueAsync = useCallback(
    async (key: string) => {
      setValues((oldValues) => {
        const data = {
          ...oldValues,
          [key]: {
            ...(oldValues[key] ? oldValues[key] : { key: key, value: null, loading: true }),
            loading: true
          } as StorageValue<any>
        };

        return data;
      });

      const lazyResponse = await retrieveValuesLazyQuery({ organizationId: organizationId, keys: keys }).unwrap();

      if (lazyResponse?.data.values[key]) {
        setValues((oldValues) => {
          const data = {
            ...oldValues,
            [key]: {
              ...(oldValues[key] ? oldValues[key] : { key: key, value: null, loading: true }),
              value: JSON.parse(lazyResponse?.data.values[key]),
              loading: false
            } as StorageValue<any>
          };

          return data;
        });
      } else {
        setValues((oldValues) => {
          const data = {
            ...oldValues,
            [key]: {
              ...(oldValues[key] ? oldValues[key] : { key: key, value: null, loading: true }),
              value: null,
              loading: false,
              error: 'key not found'
            } as StorageValue<any>
          };

          return data;
        });
      }
      try {
      } catch (error) {
        setValues((oldValues) => {
          const data = {
            ...oldValues,
            [key]: {
              ...(oldValues[key] ? oldValues[key] : { key: key, value: null, loading: true }),
              value: null,
              loading: false,
              error: 'unknown error'
            } as StorageValue<any>
          };

          return data;
        });
      }
    },
    [organizationId]
  );

  const valueChangeHandler = useCallback(
    (updatedKey: string) => {
      if (_.find(keys, (key) => updatedKey === key)) {
        retrieveValueAsync(updatedKey);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [keysHash, retrieveValueAsync]
  );

  const retrieveValueCollectionAsync = useCallback(async () => {
    if (!keys.length) return;

    let response = await retrieveValuesLazyQuery({
      organizationId: organizationId,
      keys: keys
    }).unwrap();

    const data = _.reduce(
      keys,
      (values, key) => ({
        ...values,
        [key]: {
          key: key,
          value: response?.data.values[key] ? JSON.parse(response.data.values[key]) : null,
          loading: false
        }
      }),
      {} as T
    );

    setValues(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keysHash, organizationId]);

  useEffect(() => {
    registerStorageHandler(valueChangeHandler);

    return () => {
      removeStorageHandler(valueChangeHandler);
    };
  }, [registerStorageHandler, removeStorageHandler, valueChangeHandler]);

  useEffect(() => {
    retrieveValueCollectionAsync();
  }, [retrieveValueCollectionAsync]);

  return [values];
};

interface StorageValue<StorageValueType> {
  key: string;
  value: StorageValueType | null;
  loading: boolean;
  error?: string;
}

export default useStorageValueCollection;
export type { Query };
