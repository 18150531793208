// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

// Models
import { emailLeakDetectorSettings } from 'components/legacy/models/emailLeakDetectorSettings';

export const emailLeakDetectorApi = createApi({
  reducerPath: 'emailLeakDetectorApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API,
    prepareHeaders: () => {
      return baseHeader(true);
    }
  }),
  tagTypes: ['values'],
  endpoints: (build) => ({
    postSettings: build.mutation({
      query: (body: emailLeakDetectorSettings) => ({
        url: `/api/email-leak-detector/settings`,
        method: 'POST',
        body
      })
    }),
    putProfile: build.mutation({
      query: (body: { users: { email: string; toBeTreated: boolean }[] }) => ({
        url: `/api/connectors/email-leak-detector/put-to-be-treated`,
        method: 'PUT',
        body
      })
    }),
    postProfile: build.mutation({
      query: (body: { email: string }) => ({
        url: `/api/connectors/email-leak-detector/add-profile`,
        method: 'POST',
        body
      })
    }),
    adminPostProfile: build.mutation({
      query: (body: { email: string; organizationId: number }) => ({
        url: `/api/connectors/email-leak-detector/add-profile`,
        method: 'POST',
        body
      })
    }),
    adminPostStandaloneAudit: build.mutation({
      query: (body: { email: string; reference: string }) => ({
        url: `/api/connectors/email-leak-detector/standalone/audit`,
        method: 'POST',
        body
      })
    })
  })
});

export const {
  usePostSettingsMutation,
  usePutProfileMutation,
  usePostProfileMutation,
  useAdminPostProfileMutation,
  useAdminPostStandaloneAuditMutation
} = emailLeakDetectorApi;
