import React, { useEffect, useState } from 'react';

//Externals
import { CircularProgress, Theme, Typography, useMediaQuery } from '@mui/material';
import { Trans, t } from '@lingui/macro';
import toast from 'react-hot-toast';

// Services
import { GetSocialPublicationData } from 'components/legacy/services/socialData-publications';

// Store
import { useSelector } from 'components/legacy/store';
import connectorStorageKeys from 'components/legacy/connectors/connectorStorageKeys';

// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';

// Components
import PublicationTableDesktop from './publication-table-desktop';
import PublicationTableMobile from './publication-table-mobile';

const PublicationTable = () => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));
  const [rows, setRows] = useState<any>(null);
  const [pageSize, setPageSize] = useState<number>(5);
  const [pageNumber, setPageNumber] = useState<number>(0); //first page is 0
  const [totalRows, setTotalRows] = useState<number>(100);
  const [sortColumn, setSortColumn] = useState<string>('PublishedTime'); // Colonne de tri par default
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc'); // Ordre de tri (ascendant ou descendant)
  const [filterAvailable, setFilterAvailable] = useState<string[]>();
  const [filterSelected, SetFilterSelected] = useState<string[]>();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [selectedLinkedin] = useStorageValue<string>(connectorStorageKeys.linkedin.selectedEntity, organizationId);
  const [selectedFacebook] = useStorageValue<string>(connectorStorageKeys.facebook.selectedEntity, organizationId);
  const [selectedInstagram] = useStorageValue<string>(connectorStorageKeys.instagram.selectedEntity, organizationId);
  const hasRsSelected =
    selectedLinkedin.value !== null || selectedFacebook.value !== null || selectedInstagram.value !== null;
  const handleRequestSort = (columnId, order) => {
    setSortColumn(columnId);
    setSortOrder(order);
    setPageNumber(0);
  };

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };

  useEffect(() => {
    (async () => {
      try {
        const publicationsDataResponse = await GetSocialPublicationData({
          pageSize,
          pageNumber,
          sortColumn,
          sortOrder,
          filterSelected
        });

        if (publicationsDataResponse.errors?.length > 0) {
          throw Error(`Erreur GetSocialPublicationData: ${publicationsDataResponse.errors.join(';')}`);
        }
        setRows(publicationsDataResponse.data.result);
        setTotalRows(publicationsDataResponse.data.totalItems);
        setFilterAvailable(publicationsDataResponse.data.networkAvailable);
      } catch (e) {
        console.error(t`erreur lors de la récupération des publications : `, e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [pageSize, pageNumber, sortColumn, sortOrder, organizationId, filterSelected]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(0);
  };
  const handleChangeFilter = (filteredData: string[]) => {
    SetFilterSelected(filteredData);
  };

  if (isLoading) {
    return <CircularProgress />;
  } else {
    if (rows === null || !hasRsSelected) {
      return (
        <Typography variant="body2">
          <Trans>
            Veuillez lier au moins un de vos comptes LinkedIn, Facebook ou Instagram afin d'accéder aux données
            synthétiques de vos publications.
          </Trans>
        </Typography>
      );
    } else {
      if (isMobile) {
        return (
          <PublicationTableMobile
            rows={rows}
            totalRows={totalRows}
            pageSize={pageSize}
            pageNumber={pageNumber}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            sortColumn={sortColumn}
            sortOrder={sortOrder}
            handleRequestSort={handleRequestSort}
          />
        );
      } else {
        return (
          <PublicationTableDesktop
            rows={rows}
            totalRows={totalRows}
            pageSize={pageSize}
            pageNumber={pageNumber}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            sortColumn={sortColumn}
            sortOrder={sortOrder}
            handleRequestSort={handleRequestSort}
            handleChangeFilter={handleChangeFilter}
            filterAvailable={filterAvailable}
          />
        );
      }
    }
  }
};

export default PublicationTable;
