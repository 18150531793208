import { useCallback, useEffect, useState } from 'react';

// Services

import { useSignalRContext } from 'components/legacy/components/providers/SignalRProvider';
import { useRetrieveValueUserMutation } from '../services/storage-user';

const useStorageValueUser = <StorageValueType>(
  key: string,
  organizationId: number
): [StorageValue<StorageValueType>] => {
  // The value to retrieve and subscribe to
  const [value, setValue] = useState<StorageValue<StorageValueType>>({ key: key, value: null, loading: true });
  // Methods to start and stop subscribing to storage value change
  const { registerStorageUserHandler, removeStorageUserHandler } = useSignalRContext();
  const [retrieveValueUser] = useRetrieveValueUserMutation();

  const retrieveValueAsync = useCallback(async () => {
    let response = await retrieveValueUser({
      organizationId: organizationId,
      keys: [key]
    }).unwrap();

    if (response?.data?.values[key]) {
      setValue((oldValue) => ({
        ...oldValue,
        value: JSON.parse(response.data.values[key]),
        loading: false
      }));
    } else {
      setValue((oldValue) => ({
        ...oldValue,
        value: null,
        loading: false,
        error: 'key not found'
      }));
    }
    try {
    } catch (error) {
      setValue((oldValue) => ({
        ...oldValue,
        value: null,
        loading: false,
        error: 'unknown error'
      }));
    }
  }, [key, organizationId]);

  const valueChangeHandler = useCallback(
    (updatedKey: string) => {
      if (updatedKey === key) {
        setValue((oldValue) => ({
          ...oldValue,
          loading: true
        }));
        retrieveValueAsync();
      }
    },
    [key, retrieveValueAsync]
  );

  useEffect(() => {
    registerStorageUserHandler(valueChangeHandler);
    return () => {
      removeStorageUserHandler(valueChangeHandler);
    };
  }, [registerStorageUserHandler, removeStorageUserHandler, valueChangeHandler]);

  useEffect(() => {
    retrieveValueAsync();
  }, [retrieveValueAsync]);

  return [value];
};

interface StorageValue<StorageValueType> {
  key: string;
  value: StorageValueType | null;
  loading: boolean;
  error?: string;
}

export default useStorageValueUser;
export type { StorageValue };
