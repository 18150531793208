import { useEffect } from 'react';

// Externals
import { alpha, Box, Grid, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { t } from '@lingui/macro';

// Services
import NetworkIcon, { NetworkKey } from 'components/legacy/components/network';
import { useGetKeyFigureQuery } from 'components/legacy/services/socialData';

interface BoxAnalyticsProps {
  type: NetworkKey;
  setHaveDataToDisplay: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * @version 1.0.0
 * @author [Prune Blanc]
 * @param props.type type of social network
 * @returns Block of content for analytics views.
 */
function BoxAnalytics({ type, setHaveDataToDisplay }: BoxAnalyticsProps) {
  const { data } = useGetKeyFigureQuery({ socialNetwork: type });

  useEffect(() => {
    if (
      data?.data?.keyFigures &&
      (data?.data?.keyFigures.absoluteFollowers || data?.data?.keyFigures.lastThirtyDailyFollowers)
    ) {
      setHaveDataToDisplay(true);
    }
  }, [data, setHaveDataToDisplay]);

  if (data?.data?.keyFigures === null) {
    return null;
  }

  const absoluteNbrOfTheDayFollowers = data?.data?.keyFigures.absoluteFollowers;
  const differenceNbrFollowers = data?.data?.keyFigures.lastThirtyDailyFollowers;

  return (
    <Grid item={true} sx={{ width: '100%' }}>
      <Paper
        elevation={3}
        sx={{
          position: 'relative',
          borderRadius: '20px',
          breakInside: 'avoid',
          mb: (theme) => theme.spacing(2)
        }}
      >
        <Box
          sx={{
            p: 2,
            background: (theme) => alpha(theme.palette.primary.light, 0.05),
            borderRadius: '20px',
            height: 'max-content'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row'
            }}
          >
            <NetworkIcon networkName={type} displayTitle />
            <Stack display={'flex'} flexDirection={'row'}>
              <Tooltip title={t`Total depuis la création du compte`}>
                <Typography sx={{ pl: 1, fontSize: 36, fontWeight: 700 }}>
                  {new Intl.NumberFormat().format(absoluteNbrOfTheDayFollowers)}
                </Typography>
              </Tooltip>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Tooltip title={t`Progression sur une période de 30 jours`}>
                  <Typography
                    sx={{
                      pl: 1,
                      fontSize: 18,
                      fontWeight: 700,
                      color: (theme) =>
                        differenceNbrFollowers === null
                          ? theme.palette.text.primary
                          : differenceNbrFollowers > 0
                          ? theme.palette.success.main
                          : differenceNbrFollowers === 0
                          ? theme.palette.text.primary
                          : theme.palette.error.main
                    }}
                  >
                    {differenceNbrFollowers === null
                      ? '--'
                      : differenceNbrFollowers > 0
                      ? '+' + differenceNbrFollowers
                      : differenceNbrFollowers === 0
                      ? '--'
                      : differenceNbrFollowers}
                  </Typography>
                </Tooltip>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
}

export default BoxAnalytics;
