// Externals
import { t } from '@lingui/macro';
import { Theme, useMediaQuery } from '@mui/material';

// Components
import { ICustomBlock, UserComponentProps } from '..';
import { Header } from 'components/legacy/pages/audit/questions/header';
import MeetingsScheduler from 'components/legacy/components/meetings-scheduler';

// Store
import { useGetUserProfileQuery } from 'components/legacy/services/userProfiles';

interface ComponentProps {
  name: string;
  description: string;
  tips: string;
}
const UserComponent = ({ name, description, tips }: ComponentProps) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const { data: userProfile, isLoading: loadingUserProfile } = useGetUserProfileQuery({});

  return (
    <>
      <Header isScoreVisible={false} label={description} organizationId={organizationId} title={name} tooltip={tips} />
      <MeetingsScheduler isMobile={isMobile} lang={userProfile?.data?.userProfile?.languageCode} />
    </>
  );
};

class Calendly implements ICustomBlock {
  tips = () =>
    t`Le premier rendez-vous est gratuit, dure de 10 à 30 minutes et se fait sur vidéo-conférence via GoogleMeet.`;
  description = () =>
    t`FreewayTeam vous aide à améliorer votre digitalisation. Prenez rendez-vous avec un de nos coachs pour qu'il vous explique comment on peut vous accompagner dans votre progression.`;
  name = () => t`Prenez rendez-vous`;
  key = () => 'calendly-us';
  UserComponent: React.FC<UserComponentProps> = (props) => (
    <UserComponent description={this.description()} name={this.name()} tips={this.tips()} />
  );
}

export default Calendly;
