// Externals
import { alpha, Box, Typography } from '@mui/material';
import { t } from '@lingui/macro';

// Hooks
import useStorageValueCollection, { Query } from 'components/legacy/hooks/useStorageValueCollection';

// Models
import connectorStorageKeys from 'components/legacy/connectors/connectorStorageKeys';
import ReportPartContainer from '../common';

const ReportGoogleTopPageViews = (props) => {
  if (props.organizationId === null || props.organizationId === undefined)
    throw new Error('ReportGoogleTopPageViews organizationid empty');

  const [mapping] = useStorageValueCollection<Query>(
    [connectorStorageKeys.googleGa4.propertyId, connectorStorageKeys.googleGa4.report.last7DaysTopPageViews],
    props.organizationId
  );

  const selectedGA = mapping[connectorStorageKeys.googleGa4.propertyId];

  if (selectedGA.value === null) return null;

  const Open = (element) => {
    window.open('https://' + element['dimensionValues'][1].value, '_blank');
  };

  const dataGa4 = mapping[connectorStorageKeys.googleGa4.report.last7DaysTopPageViews];

  if (dataGa4.value === null) return null;
  if (selectedGA.value && dataGa4.value === null) return null;

  if (selectedGA) {
    const data = dataGa4.value;
    const last5datas = data.rows?.slice(0, Math.min(5, data.rows.length));
    return (
      <ReportPartContainer displayLink={props.displayLink} route={'/redirect/google-ga-pageviews'}>
        <Typography sx={{ textAlign: 'center', mb: 2, pt: 2 }} variant="h4">
          {t`Pages web populaires*`}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', pb: 2 }}>
          {last5datas &&
            last5datas.map((element, index) => (
              <Typography
                key={index}
                onClick={() => Open(element)}
                sx={{
                  cursor: 'pointer',
                  width: '90%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textAlign: 'center',
                  background: (theme) => alpha(theme.palette.primary.light, 0.1),
                  borderRadius: '20px',
                  mb: 1,
                  ml: 'auto',
                  mr: 'auto'
                }}
              >
                {element['dimensionValues'][1].value}
              </Typography>
            ))}
        </Box>
      </ReportPartContainer>
    );
  }
};

export default ReportGoogleTopPageViews;
