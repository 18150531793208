import React, { useEffect } from 'react';

// Internals
import { ICustomBlock, UserComponentProps } from '../../index';
import { Header } from 'components/legacy/pages/audit/questions/header';

// Externals
import _ from 'lodash';
import { t } from '@lingui/macro';
import {
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { useParams } from 'react-router-dom';

// Stores
import { useSelector } from 'components/legacy/store';
import { QuestionScoreDto } from 'components/legacy/models/questionScore.dto';

// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';
import { useGetQuestionScoresQuery } from 'components/legacy/services/questions';
import useMoment from 'components/legacy/hooks/useMoment';
import { useGetAllHistoricalDataByReferenceQuery } from 'components/legacy/services/historical-data';

interface ComponentProps {
  name: string;
  description: string;
  connectorKey: string;
  tooltip: string;
  questionKey: string;
}

interface historicalData {
  creationDate: string;
  value: number;
}

const DOMAINAUTHORITY = 'seo:domain-strength';
const FINALURL = 'seo:final-url';

const UserComponent = ({ name, description, connectorKey, questionKey, tooltip }: ComponentProps) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));

  const { auditKey } = useParams();

  const { data: scoresQuery, isLoading: loadingScores } = useGetQuestionScoresQuery({
    organizationId,
    auditKey
  });

  const scores: QuestionScoreDto[] = useSelector<QuestionScoreDto[]>((state) =>
    scoresQuery?.data?.questions?.filter((s) => s.key === questionKey)
  );
  const score = _.sumBy(scores, (s) => s.score);
  const [domainStrengthStorage] = useStorageValue<any>(DOMAINAUTHORITY, organizationId);
  const [website] = useStorageValue<string>(FINALURL, organizationId);
  const moment = useMoment();
  const [page, setPage] = React.useState(0);
  const [historical, setHistorical] = React.useState<historicalData[]>();
  const [rowsPerPage, setRowsPerPage] = React.useState(5); // nombre d'éléments par page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const { data: historyData, isLoading: loadingData } = useGetAllHistoricalDataByReferenceQuery({
    connectorName: 'seo',
    key: 'domain-strength'
  });

  useEffect(() => {
    if (!loadingData) {
      //Sort desc by date
      const sortedHistoryData = [...historyData?.data?.values]?.sort((a, b) => {
        const dateA = new Date(a.creationDate);
        const dateB = new Date(b.creationDate);
        return dateB.getTime() - dateA.getTime();
      });
      //Remove consecutive duplicate values
      const uniqueHistoryData = sortedHistoryData.filter((value, index, array) => {
        return index === 0 || value.value !== array[index - 1].value;
      });
      setHistorical(uniqueHistoryData);
    }
  }, [historyData]);

  return (
    <>
      <Header
        isScoreVisible={true}
        label={''}
        organizationId={organizationId}
        score={score}
        title={name}
        tooltip={tooltip}
      />
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid item={true} md={4} sm={4} xs={3}>
          <Typography sx={{ textAlign: 'center', pb: 2 }} variant="h6">
            {website.value}
          </Typography>
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <Box sx={{ position: 'relative' }}>
              <CircularProgress
                size={150}
                sx={{
                  '&.MuiCircularProgress-colorPrimary': {
                    color: '#EEEEEE'
                  }
                }}
                thickness={5}
                value={100}
                variant="determinate"
              />
              <CircularProgress
                size={150}
                sx={{
                  position: 'absolute',
                  left: 0,
                  '&.MuiCircularProgress-colorPrimary': {
                    color: (theme) =>
                      domainStrengthStorage.value >= 0 && domainStrengthStorage.value <= 10
                        ? theme.palette?.error.main
                        : domainStrengthStorage.value >= 11 && domainStrengthStorage.value <= 19
                        ? theme.palette?.orange.main
                        : domainStrengthStorage.value >= 20 && domainStrengthStorage.value <= 49
                        ? theme.palette?.warning.main
                        : domainStrengthStorage.value >= 50 && domainStrengthStorage.value <= 100
                        ? theme.palette?.success.main
                        : theme.palette.text.primary
                  }
                }}
                thickness={5}
                value={domainStrengthStorage.value}
                variant="determinate"
              />
            </Box>
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography component="div" sx={{ fontWeight: 700, fontSize: 20 }}>
                {domainStrengthStorage.value}/100
              </Typography>
            </Box>
          </Box>
        </Grid>

        {historical?.length > 0 && (
          <TableContainer sx={{ maxWidth: 450 }}>
            <Table size="small" sx={{ backgroundColor: '' }}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="right">Score</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {historical.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((history, index) => (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align="left">{moment(history.creationDate).format('L')}</TableCell>
                    <TableCell align="right">{history.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              hidden={historical.length <= 5}
              rowsPerPageOptions={[5]}
              component="div"
              count={historyData.data.values.length}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={``}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
      </Grid>
    </>
  );
};

class SEODomainAuthority implements ICustomBlock {
  tooltip = () =>
    t`L'autorité de domaine (DA) est une mesure de l'influence de votre site. Plus il est reconnu, plus son score sera élevé. Ce score est fortement influencé par le nombre et la qualité des liens externes pointant vers votre site. C'est une des métriques principales pour le référencement sur les moteurs de recherche.`;
  description = () => t`Votre site : `;
  name = () => t`Autorité de domaine`;
  key = () => 'seo-domain-authority';
  UserComponent: React.FC<UserComponentProps> = (props) => (
    <UserComponent
      connectorKey={this.key()}
      description={this.description()}
      name={this.name()}
      questionKey={props.questionKey}
      tooltip={this.tooltip()}
    />
  );
}

export default SEODomainAuthority;
