// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API,
    prepareHeaders: () => {
      return baseHeader(true);
    }
  }),
  tagTypes: ['admin-accounts'],
  endpoints: (build) => ({
    getAdmins: build.query({
      query: () => '/api/admins/getadmins'
    }),
    getAccountsAdmin: build.query({
      query: () => `/api/admin/accounts/getaccounts`,
      providesTags: ['admin-accounts']
    }),
    deleteUserAccountAdmin: build.mutation({
      query: (body: { userId: string; reason: string }) => ({
        url: `/api/admin/accounts/deleteUserAccount`,
        method: 'DELETE',
        body
      }),
      invalidatesTags: ['admin-accounts']
    }),
    updateAdminUserRole: build.mutation({
      query: (body: { currentUserId: string; userId: string; role: string }) => ({
        url: `/api/admins/SetAdminRole`,
        method: 'POST',
        body
      })
    })
  })
});

export const {
  useGetAdminsQuery,
  useDeleteUserAccountAdminMutation,
  useGetAccountsAdminQuery,
  useUpdateAdminUserRoleMutation
} = adminApi;
