// Internals
import { ICustomBlock, UserComponentProps } from '../../index';
import { Header } from 'components/legacy/pages/audit/questions/header';
import GenericList from 'components/legacy/components/layouts/lists';

// Externals
import { t } from '@lingui/macro';
import { isEmpty } from 'lodash';
import _ from 'lodash';

// Models
import { QuestionScoreDto } from 'components/legacy/models/questionScore.dto';

// Redux
import { useSelector } from 'components/legacy/store';

// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';
import { useGetQuestionScoresQuery } from 'components/legacy/services/questions';
import { useParams } from 'react-router';

// We could create a custom Admin Component if necessary
// const AdminComponent = () => {
//   return <div>Hello</div>;
// };

interface ComponentProps {
  name: string;
  description: string;
  connectorKey: string;
  questionKey: string;
}

const MAILTESTER_BROKENLINKS_COLD = 'mailtester:linksbroken-cold';

const UserComponent = ({ name, description, connectorKey, questionKey }: ComponentProps, props) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));

  const { auditKey } = useParams();

  const { data: scoresQuery, isLoading: loadingScores } = useGetQuestionScoresQuery({
    organizationId,
    auditKey
  });

  const scores: QuestionScoreDto[] = useSelector<QuestionScoreDto[]>((state) =>
    scoresQuery?.data?.questions?.filter((s) => s.key === questionKey)
  );
  const score = _.sumBy(scores, (s) => s.score);

  const [brokenLinks] = useStorageValue<any>(MAILTESTER_BROKENLINKS_COLD, organizationId);

  return (
    <>
      {!isEmpty(brokenLinks.value) ? (
        <>
          <Header
            isScoreVisible={true}
            label={description}
            organizationId={organizationId}
            score={score}
            title={name}
          />
          <GenericList
            data={brokenLinks.value.urls}
            nbrElement={`${brokenLinks.value.urls.length}`}
            title={brokenLinks.value.urls.length + ' ' + t` lien(s) brisé(s) ont été trouvé(s).`}
          />
        </>
      ) : (
        ''
      )}
    </>
  );
};

class BrokenLinkColdRecap implements ICustomBlock {
  description = () => t`Nous regardons si des liens brisés figurent dans l'email que vous nous avez envoyé.`;
  name = () => t`Liens brisés dans votre email`;
  key = () => 'brokenlink-cold-recap';
  UserComponent: React.FC<UserComponentProps> = (props) => (
    <UserComponent
      connectorKey={this.key()}
      description={this.description()}
      name={this.name()}
      questionKey={props.questionKey}
    />
  );
}

export default BrokenLinkColdRecap;
