// Internals
import { ICustomBlock, UserComponentProps } from '../../index';
import { Header } from 'components/legacy/pages/audit/questions/header';

// Externals
import _ from 'lodash';
import { t } from '@lingui/macro';
import { CircularProgress } from '@mui/material';

// Models
import { QuestionScoreDto } from 'components/legacy/models/questionScore.dto';

// Redux
import { useSelector } from 'components/legacy/store';

// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';
import { useGetQuestionScoresQuery } from 'components/legacy/services/questions';
import { useParams } from 'react-router';

interface ComponentProps {
  name: string;
  description: string;
  connectorKey: string;
  questionKey: string;
}

const YOUTUBE_VIEW = 'youtube:view-count';
const VIDEO_COUNT = 'youtube:video-count';

const UserComponent = ({ name, description, connectorKey, questionKey }: ComponentProps) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));

  const { auditKey } = useParams();

  const { data: scoresQuery, isLoading: loadingScores } = useGetQuestionScoresQuery({
    organizationId,
    auditKey
  });

  const scores: QuestionScoreDto[] = useSelector<QuestionScoreDto[]>((state) =>
    scoresQuery?.data?.questions?.filter((s) => s.key === questionKey)
  );
  const score = _.sumBy(scores, (s) => s.score);

  const [youtubeViews] = useStorageValue<number>(YOUTUBE_VIEW, organizationId);
  const [youtubeVideos] = useStorageValue<number>(VIDEO_COUNT, organizationId);

  if (youtubeViews.loading || youtubeVideos.loading) {
    return (
      <>
        <Header isScoreVisible={true} label={description} organizationId={organizationId} title={name} />
        <CircularProgress />
      </>
    );
  }

  return (
    <>
      <Header
        isScoreVisible={true}
        label={
          t`Vos ` +
          ' ' +
          (new Intl.NumberFormat().format(youtubeVideos.value) ?? 0) +
          ' ' +
          t` vidéos Youtube ont comptabilisé ` +
          ' ' +
          (new Intl.NumberFormat().format(youtubeViews.value) ?? 0) +
          ' ' +
          t` vues.`
        }
        organizationId={organizationId}
        score={score}
        title={name}
      />
    </>
  );
};

class YoutubeViewers implements ICustomBlock {
  name = () => t`Vidéos vues`;
  description = () => t`FreewayTeam analyse votre nombre de vues sur Youtube.`;
  key = () => 'youtube-viewers';
  UserComponent: React.FC<UserComponentProps> = (props) => (
    <UserComponent
      connectorKey={this.key()}
      description={this.description()}
      name={this.name()}
      questionKey={props.questionKey}
    />
  );
}

export default YoutubeViewers;
