// Common
import { request } from '../common';

interface requestParams {
  pageSize: number;
  pageNumber: number;
  sortColumn: string;
  sortOrder: 'asc' | 'desc';
  filterNetwork: string[];
  filterStatus: string;
}

// filtersNetwork : attention ça veut dire NE CONTIENT PAS

export const GetSocialAdsData = (requestParams: requestParams): Promise<Response> =>
  request({
    method: 'GET',
    api: `/api/social-publication-statistic/social-statistic`,
    params: {
      pageSize: requestParams.pageSize,
      pageNumber: requestParams.pageNumber,
      orderBy: requestParams.sortColumn,
      IsDescending: requestParams.sortOrder === 'desc',
      filterNetwork: requestParams.filterNetwork,
      filterStatus: requestParams.filterStatus
    },
    authorized: true
  })
    .then((response) => response.data)
    .catch((error) => ({ data: { value: [] }, errors: [error.message] }));
interface Response {
  data: any;
  errors: string[];
}
