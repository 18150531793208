import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Externals
import { Trans, t } from '@lingui/macro';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme
} from '@mui/material';
import { format } from 'date-fns';
import getSymbolFromCurrency from 'currency-symbol-map';
import { round } from 'lodash';
import moment from 'moment';
import ReactECharts from 'echarts-for-react';

// Components
import { ICustomBlock, UserComponentProps } from '..';
import { Header } from 'components/legacy/pages/audit/questions/header';
import connectorStorageKeys from 'components/legacy/connectors/connectorStorageKeys';

// Service
import useStorageValueCollection, { Query } from 'components/legacy/hooks/useStorageValueCollection';

interface ComponentProps {
  name: string;
  description: string;
  tips: string;
}

const UserComponent = ({ name, description, tips }: ComponentProps) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));
  const theme = useTheme();
  // const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [alignment, setAlignment] = useState<string>('365');
  const [chartData, setChartData] = useState<any>(null);
  const [adsSummaries, setAdsSummaries] = useState<iAdsSummaries>(null);

  // Organization Related values
  const [mapping] = useStorageValueCollection<Query>(
    [
      connectorStorageKeys.linkedin.adAccount,
      connectorStorageKeys.linkedin.adsSummary7,
      connectorStorageKeys.linkedin.adsSummary30,
      connectorStorageKeys.linkedin.adsSummary90,
      connectorStorageKeys.linkedin.adsSummary365,
      connectorStorageKeys.linkedin.adsSummaryData,
      connectorStorageKeys.facebook.selectedAdEntity,
      connectorStorageKeys.facebook.adsSummary7,
      connectorStorageKeys.facebook.adsSummary30,
      connectorStorageKeys.facebook.adsSummary90,
      connectorStorageKeys.facebook.adsSummary365,
      connectorStorageKeys.facebook.adsSummaryData
    ],
    organizationId
  );
  const isLoading =
    mapping[connectorStorageKeys.linkedin.adAccount].loading ||
    mapping[connectorStorageKeys.linkedin.adsSummary7].loading ||
    mapping[connectorStorageKeys.linkedin.adsSummary30].loading ||
    mapping[connectorStorageKeys.linkedin.adsSummary90].loading ||
    mapping[connectorStorageKeys.linkedin.adsSummary365].loading ||
    mapping[connectorStorageKeys.linkedin.adsSummaryData].loading ||
    mapping[connectorStorageKeys.facebook.selectedAdEntity].loading ||
    mapping[connectorStorageKeys.facebook.adsSummary7].loading ||
    mapping[connectorStorageKeys.facebook.adsSummary30].loading ||
    mapping[connectorStorageKeys.facebook.adsSummary90].loading ||
    mapping[connectorStorageKeys.facebook.adsSummary365].loading ||
    mapping[connectorStorageKeys.facebook.adsSummaryData].loading;

  interface iAdsSummary {
    adAccount: any;
    adsSummary7: any;
    adsSummary30: any;
    adsSummary90: any;
    adsSummary365: any;
    adsSummaryData: any;
    actions?: { action_type: string; value: number }[];
  }

  interface iAdsSummaries {
    linkedin: iAdsSummary;
    facebook: iAdsSummary;
  }

  const connectorNames = useMemo(() => ['linkedin', 'facebook'], []);

  const getPropName = (days: string) => {
    switch (days) {
      case '7':
        return 'adsSummary7';
      case '30':
        return 'adsSummary30';
      case '90':
        return 'adsSummary90';
      case '365':
        return 'adsSummary365';
      default:
        return 'adsSummaryData';
    }
  };

  const handleClickDays = (days: number) => {
    const date = new Date();
    date.setDate(date.getDate() - days);
  };

  const getValue = (connectorName: string, days: string, valueKey: string) => {
    let value = adsSummaries[connectorName]?.[getPropName(days)]?.[valueKey] ?? 0;
    return value;
  };

  const getValueCPC = (connectorName: string, days: string) => {
    let propName = getPropName(days);

    let connector = adsSummaries[connectorName];

    let spend =
      connectorName === 'linkedin' ? connector?.[propName]?.costInLocalCurrency : connector?.[propName]?.spend;

    let value = connector?.[propName] ? spend / connector?.[propName]?.clicks : 0;

    return new Intl.NumberFormat().format(round(value, 2));
  };

  const connectorNamesAdapter = useCallback((): string[] => {
    return connectorNames.map((connectorName) => {
      switch (connectorName) {
        case 'facebook':
          // connector name is facebook but the data are from Facebook and Instagram
          return 'meta';
        default:
          return connectorName;
      }
    });
  }, [connectorNames]);

  const firstLetterInUpperCase = (text: string) => {
    if (text.length === 0) {
      return text;
    } else {
      const firstLetter = text.charAt(0).toUpperCase();
      const rest = text.slice(1);
      return firstLetter + rest;
    }
  };

  const generateRealData = useCallback(
    (numDays: any) => {
      const currentDate = new Date();
      const startDate = new Date();
      startDate.setDate(currentDate.getDate() - numDays);

      const localChartData = [];

      localChartData.push([{ type: 'date', label: 'Dates' }, ...connectorNamesAdapter()]);
      let currentDateCopy = new Date(startDate);
      for (let i = 0; i <= numDays; i++) {
        const formattedDate = new Date(format(currentDateCopy, 'MM/dd/yyyy'));

        let clicks = [];
        connectorNames.forEach((connectorName) => {
          const matchingData = adsSummaries[connectorName].adsSummaryData?.find((item) => {
            if (connectorName === 'linkedin') {
              const start = item.dateRange.start;
              return (
                start.day === formattedDate.getDate() &&
                start.month === formattedDate.getMonth() + 1 &&
                start.year === formattedDate.getFullYear()
              );
            } else if (connectorName === 'facebook') {
              const start = moment(item.date_start);
              return start.isSame(formattedDate);
            }
            return false;
          });
          clicks[connectorName] = matchingData ? matchingData.clicks : 0;
        });
        localChartData.push([formattedDate, clicks['linkedin'], clicks['facebook']]);

        currentDateCopy.setDate(currentDateCopy.getDate() + 1);
      }
      setChartData(localChartData);
    },
    [adsSummaries, connectorNames, connectorNamesAdapter]
  );

  useEffect(() => {
    if (!isLoading) {
      const result = {
        linkedin: {
          adAccount: mapping[connectorStorageKeys.linkedin.adAccount].value,
          adsSummary7: mapping[connectorStorageKeys.linkedin.adsSummary7].value,
          adsSummary30: mapping[connectorStorageKeys.linkedin.adsSummary30].value,
          adsSummary90: mapping[connectorStorageKeys.linkedin.adsSummary90].value,
          adsSummary365: mapping[connectorStorageKeys.linkedin.adsSummary365].value,
          adsSummaryData: mapping[connectorStorageKeys.linkedin.adsSummaryData].value
        },
        facebook: {
          adAccount: mapping[connectorStorageKeys.facebook.selectedAdEntity].value,
          adsSummary7: mapping[connectorStorageKeys.facebook.adsSummary7].value,
          adsSummary30: mapping[connectorStorageKeys.facebook.adsSummary30].value,
          adsSummary90: mapping[connectorStorageKeys.facebook.adsSummary90].value,
          adsSummary365: mapping[connectorStorageKeys.facebook.adsSummary365].value,
          adsSummaryData: mapping[connectorStorageKeys.facebook.adsSummaryData].value
        }
      };

      setAdsSummaries(result);
    }
  }, [isLoading, mapping]);

  useEffect(() => {
    if (!isLoading && adsSummaries !== null) {
      generateRealData(alignment);
    }
  }, [isLoading, adsSummaries, generateRealData]);

  const getClicks = (connector: string, days: string): number => parseInt(getValue(connector, days, 'clicks'));
  const getImpressions = (connector: string, days: string): number =>
    parseInt(getValue(connector, days, 'impressions'));
  const getSpent = (connector: string, days: string): number => {
    const propName = connector === 'linkedin' ? 'costInLocalCurrency' : 'spend';
    return Math.round(parseInt(getValue(connector, days, propName)));
  };
  const getEngagement = (connector: string, days: string): number => {
    if (connector === 'facebook') {
      if (adsSummaries[connector]?.[getPropName(days)] === null) return 0;
      let value: number =
        adsSummaries[connector]?.adsSummaryData
          ?.map((d) => d.actions?.filter((a) => a.action_type === 'post_engagement').map((a) => a.value))
          .reduce((t, c) => t + Number.parseInt(c), 0) ?? 0;
      return value;
    }
    return parseInt(getValue(connector, days, 'totalEngagements'));
  };
  const getCPC = (connector: string, days: string) => getValueCPC(connector, days);

  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: number) => {
    if (newAlignment === null || newAlignment.toString() === alignment) return;
    handleClickDays(newAlignment);
    setAlignment(newAlignment.toString());
  };

  const option = {
    color: ['#00AE65', '#3b5998'],
    smooth: true,
    legend: {
      data: ['Linkedin', 'Meta'],
      textStyle: {
        color: theme.palette.text.primary
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLabel: {
        rotate: 30
      },
      data: chartData?.slice(-alignment, chartData.length).map((element) => moment(element[0]).format('DD/MM/YY'))
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    tooltip: {
      trigger: 'axis'
    },
    yAxis: {
      type: 'value',
      height: '400px'
    },
    series: [
      {
        data: chartData?.slice(-alignment, chartData.length).map((element) => element[1]),
        type: 'bar',
        showSymbol: false,
        stack: 'Total',
        name: 'Linkedin'
      },
      {
        data: chartData?.slice(-alignment, chartData.length).map((element) => element[2]),
        type: 'bar',
        showSymbol: false,
        stack: 'Total',
        name: 'Meta'
      }
    ]
  };

  return (
    <>
      <Header isScoreVisible={false} label={description} organizationId={organizationId} title={name} tooltip={tips} />

      {isLoading === null || isLoading ? (
        <CircularProgress />
      ) : adsSummaries ? (
        adsSummaries['facebook']?.adsSummary365 === null && adsSummaries['linkedin']?.adsSummary365 === null ? (
          <Typography gutterBottom={true} variant="body2">
            <Trans>Pas de données pour le moment.</Trans>
          </Typography>
        ) : (
          <>
            <Typography variant="body2">
              <Trans>Clics sur vos pubs chaque jour.</Trans>
            </Typography>
            <br />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <ToggleButtonGroup color="primary" exclusive={true} onChange={handleChange} value={alignment}>
                <ToggleButton value="7">{t`7 jours`}</ToggleButton>
                <ToggleButton value="30">{t`30 jours`}</ToggleButton>
                <ToggleButton value="90">{t`90 jours`}</ToggleButton>
                <ToggleButton value="365">{t`365 jours`}</ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <ReactECharts option={option} />

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>{t`Dépensé`}</TableCell>
                    <TableCell>{t`Impressions`}</TableCell>
                    <TableCell>{t`Engagement`}</TableCell>
                    <TableCell>{t`Clics`}</TableCell>
                    <TableCell>{t`CPC`}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {connectorNames.map((connectorName: string, index) => {
                    var connectorNameWithFirstUpperCase = firstLetterInUpperCase(connectorNamesAdapter()[index]);
                    var symbolCurrency = getSymbolFromCurrency(adsSummaries[connectorName].adAccount?.currency);
                    var spent = getSpent(connectorName, alignment);
                    var impression = getImpressions(connectorName, alignment);
                    var engagement = getEngagement(connectorName, alignment);
                    var clicks = getClicks(connectorName, alignment);
                    var CPC = getCPC(connectorName, alignment);

                    if (spent === 0 && impression === 0 && engagement === 0 && clicks === 0) {
                      return null;
                    }

                    return (
                      <TableRow key={index}>
                        <TableCell sx={{ fontWeight: 600 }}>{connectorNameWithFirstUpperCase} Ads</TableCell>
                        <TableCell>
                          {spent} {symbolCurrency}
                        </TableCell>
                        <TableCell>{impression}</TableCell>
                        <TableCell>{engagement}</TableCell>
                        <TableCell>{clicks}</TableCell>
                        <TableCell>
                          {CPC} {symbolCurrency}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )
      ) : (
        ''
      )}
    </>
  );
};

class AdsPerfomance implements ICustomBlock {
  tips = () =>
    t`Voici les performances de vos publicités. Les impressions sont le nombre de fois qu'elles ont été vues, les engagements sont toutes les interactions avec vos pubs, les clics le nombre de redirections vers votre lien, et le CPC le coût moyen par clic.`;
  description = () => ``;
  name = () => t`Performances de vos pubs`;
  key = () => 'adsperformance';
  UserComponent: React.FC<UserComponentProps> = () => (
    <UserComponent description={this.description()} name={this.name()} tips={this.tips()} />
  );
}

export default AdsPerfomance;
