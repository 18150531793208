// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const socialDataApi = createApi({
  reducerPath: 'socialDataApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API,
    prepareHeaders: () => {
      return baseHeader(true);
    }
  }),
  tagTypes: ['sn-data'],
  endpoints: (build) => ({
    getKeyFigure: build.query({
      query: ({ organizationId, socialNetwork }) => `/api/social-network/key-figures?network=${socialNetwork}`,
      providesTags: ['sn-data']
    }),
    getKeyFigureProgress: build.query({
      query: ({ network, period }) => `/api/social-network/key-figures-progress?network=${network}&period=${period}`,
      providesTags: ['sn-data']
    }),

    getSocialDataHistory: build.query({
      query: ({ organizationId, days }) => `/api/social-network/all-history/${organizationId}/${days}`,
      providesTags: ['sn-data']
    }),
    // ! elle existe encore ?
    getDailyViewsChart: build.query({
      query: ({ organizationId, socialNetwork }) =>
        `/api/social-network/chart/daily-views?organizationId=${organizationId}&network=${socialNetwork}`,
      providesTags: ['sn-data']
    })
  })
});

export const {
  useGetDailyViewsChartQuery,
  useGetKeyFigureQuery,
  useGetSocialDataHistoryQuery,
  useGetKeyFigureProgressQuery
} = socialDataApi;
