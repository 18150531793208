import { useState } from 'react';

// Externals
import { t } from '@lingui/macro';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Typography,
  TableFooter,
  TablePagination,
  TableHead,
  Theme,
  useMediaQuery
} from '@mui/material';
import { round } from 'lodash';

// Hooks

// Components

import React from 'react';
import KeyWordsTableHead from './header';
import { GoogleAdsKeywordDTO } from 'components/legacy/models/googleAdsKeywords.dto';
import useMoment from 'components/legacy/hooks/useMoment';

/**
 * KeYWordsTableDesktop component
 * Renders a campaign table for desktop.
 *
 * @param {GoogleAdsKeywordDTO[]} rows - Data displayed on the table page.
 * @param {number} totalRows - Number of available items.
 * @param {number} pageSize - Number of items displayed per page.
 * @param {number} pageNumber - Number of pages.
 * @param {string} sortColumn - Name of the sorted column.
 * @param {string} sortOrder - Column sort order (desc or asc).
 * @param {Function} handleChangePage - Action triggered when the user changes the page.
 * @param {Function} handleChangeRowsPerPage - Action triggered when the user changes the number of items per page.
 * @param {Function} handleRequestSort - Action triggered when changing the sort column.
 * @version 1.0.0 [DCH] - Initial version of the KeyWordsTableDesktop component.
 */

const KeyWordsTableDesktop = ({
  rows,
  totalRows,
  pageSize,
  pageNumber,
  sortColumn,
  sortOrder,
  handleChangePage,
  handleChangeRowsPerPage,
  handleRequestSort
}) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));
  const moment = useMoment();
  const upLg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const KeyWords: React.FC<{ row: GoogleAdsKeywordDTO }> = (row) => {
    return (
      <Box
        sx={{
          margin: 0,
          padding: 0,
          maxWidth: 'auto',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'left',
          align: 'left'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            align: 'center',
            verticalAlign: 'center',
            margin: 0
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'left',
              flexDirection: 'column',
              maxWidth: upLg ? '400px' : '250px'
            }}
          >
            <Typography
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                maxHeight: '1.5em',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                fontWeight: 'medium'
              }}
            >
              {row.row.word}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  return rows === null || rows?.length === 0 ? (
    <Typography variant="body2">{t`Pas de données pour le moment.`}</Typography>
  ) : (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <KeyWordsTableHead
              onRequestSort={handleRequestSort}
              sortColumn={sortColumn}
              sortOrder={sortOrder}
            ></KeyWordsTableHead>
          </TableHead>

          <TableBody>
            {rows === null ||
              (rows?.length === 0 && (
                <TableRow key="empty">
                  <Typography variant="body2">{t`Aucune données n'a été trouvée`}</Typography>
                </TableRow>
              ))}
            {rows?.map((keyword: GoogleAdsKeywordDTO) => {
              return (
                <TableRow key={keyword.id}>
                  <TableCell>
                    <KeyWords row={keyword} />
                  </TableCell>
                  <TableCell align="right">
                    {new Intl.NumberFormat().format(round(keyword.spent / 1000000, 2)) +
                      (keyword?.currency === 'USD' ? ' $' : ' €')}
                  </TableCell>
                  <TableCell align="right">{new Intl.NumberFormat().format(keyword.impressions)}</TableCell>
                  <TableCell align="right">
                    {keyword.impressions !== 0
                      ? new Intl.NumberFormat().format(round((keyword.clicks / keyword.impressions) * 100, 2)) + '%'
                      : '0%'}
                  </TableCell>

                  <TableCell align="right">{new Intl.NumberFormat().format(keyword.clicks)}</TableCell>

                  <TableCell align="right">
                    {keyword.clicks !== 0
                      ? new Intl.NumberFormat().format(round(keyword.spent / 1000000 / keyword.clicks, 2)) +
                        (keyword?.currency === 'USD' ? ' $' : ' €')
                      : '0 $'}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={rows !== null && rows?.length > 0 ? [10, 20] : []}
                colSpan={6}
                count={totalRows}
                rowsPerPage={pageSize}
                page={pageNumber}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t`Nombre de campagne par page`}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};
export default KeyWordsTableDesktop;
