import { Check } from '@mui/icons-material';
import { Box, Checkbox, Tooltip, styled, tooltipClasses, TooltipProps } from '@mui/material';
import { FC, useState } from 'react';
import { t } from '@lingui/macro';
import toast from 'react-hot-toast';

type CheckBoxProps = {
  archived: boolean;
  completionMode: string;
  status: string;
  occurrenceId: number;
  title: string;
  handleComplete: (questOccurrenceId: number, isCompleted: boolean, title: string) => void;
};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    background: '#6F6F6F',
    maxWidth: 250,
    borderRadius: 5,
    fontSize: 14
  }
});

const CheckBoxQuest: FC<CheckBoxProps> = (checkBoxProps) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  function isCompleted(): boolean {
    return checkBoxProps.status === 'completed';
  }

  function isManual() {
    return checkBoxProps.completionMode === 'manual';
  }

  const handleMouseEnter = () => {
    if (!isTooltipVisible && !isManual() && !isCompleted()) {
      setIsTooltipVisible(true);
    }
  };

  const handleMouseLeave = () => {
    if (isTooltipVisible && !isManual() && !isCompleted()) {
      setIsTooltipVisible(false);
    }
  };

  function GetIconManual(props) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '30px',
          height: '30px',
          borderRadius: '10px',
          border: (theme) =>
            props.checkedIcon && !props.archived
              ? `2px solid  ${theme.palette.success.main}`
              : `2px solid  ${theme.palette.primary.main}`,
          background: (theme) => props.checkedIcon && !props.archived && theme.palette.background.default
        }}
      >
        {props.checkedIcon && (
          <Check
            color={props.archived ? 'primary' : 'success'}
            height={'36px'}
            sx={{
              stroke: (theme) => (props.archived ? theme.palette.primary.main : theme.palette.success.main),
              strokeWidth: 2
            }}
            width={'36px'}
          />
        )}
      </Box>
    );
  }

  const GetIconA = (props) => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '30px',
        height: '30px',
        borderRadius: '10px',
        border: (theme) =>
          props.checkedIcon && !props.archived
            ? `2px solid  ${theme.palette.success.main}`
            : !props.archived && !props.checkedIcon
            ? `2px solid #C8C8C8`
            : `2px solid  ${theme.palette.primary.main}`,
        background: (theme) =>
          props.checkedIcon && !props.archived
            ? theme.palette.background.default
            : !props.archived && !props.checkedIcon
            ? '#3D4044'
            : 'white'
      }}
    >
      {props.checkedIcon && (
        <Check
          color={props.archived ? 'primary' : 'success'}
          height={'36px'}
          sx={{
            stroke: (theme) => (props.archived ? theme.palette.primary.main : theme.palette.success.main),
            strokeWidth: 2
          }}
          width={'36px'}
        />
      )}
    </Box>
  );

  return (
    <CustomWidthTooltip
      title={t`Ne vous inquiétez pas ! Cette tâche se validera automatiquement une fois terminée`}
      open={isTooltipVisible}
    >
      <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Checkbox
          checked={isCompleted()}
          checkedIcon={
            isManual() ? (
              <GetIconManual archived={checkBoxProps.archived} checkedIcon={true} />
            ) : (
              <GetIconA archived={checkBoxProps.archived} checkedIcon={true} />
            )
          }
          disabled={!isManual() || checkBoxProps.archived ? true : false}
          icon={
            isManual() ? (
              <GetIconManual archived={checkBoxProps.archived} checkedIcon={false} />
            ) : (
              <GetIconA archived={checkBoxProps.archived} checkedIcon={false} />
            )
          }
          onChange={(e) => {
            e.target.checked
              ? toast.success(checkBoxProps.title + ' ' + t`: tâche validée`)
              : toast.error(checkBoxProps.title + ' ' + t`: validation de la tâche annulée`);
            checkBoxProps.handleComplete(checkBoxProps.occurrenceId, e.target.checked, checkBoxProps.title);
          }}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            mr: 1,
            '&:hover': {
              backgroundColor: 'transparent !important'
            }
          }}
        />
      </span>
    </CustomWidthTooltip>
  );
};

export default CheckBoxQuest;
