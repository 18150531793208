import { useState } from 'react';

// Internals
import { ICustomBlock, UserComponentProps } from '../index';
import { Header } from 'components/legacy/pages/audit/questions/header';

// Externals
import _ from 'lodash';
import { t, Trans } from '@lingui/macro';
import { Button, MenuItem, TextField, Stack, Typography, CircularProgress } from '@mui/material';

import GoogleConnect from '../google/connect';

// Stores
import { useSelector } from 'components/legacy/store';
import { QuestionScoreDto } from 'components/legacy/models/questionScore.dto';

// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';
import connectorStorageKeys from 'components/legacy/connectors/connectorStorageKeys';
import ConnectorAuthValues from 'components/legacy/connectors/connectorAuthValues';
import useStorageValueUser from 'components/legacy/hooks/useStorageValueUser';
import { useSelectSiteGoogleSearchMutation } from 'components/legacy/services/connectors';
import {
  useDeleteSelectTokenUserMutation,
  usePostSelectTokenUserMutation
} from 'components/legacy/services/organizations';
import { useGetQuestionScoresQuery } from 'components/legacy/services/questions';
import { useParams } from 'react-router';
import { useGetUserProfileQuery } from 'components/legacy/services/userProfiles';
import { useGetSingleRoleQuery } from 'components/legacy/services/organization-roles';
import { useGetOrganizationMemberQuery } from 'components/legacy/services/organizationMember';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { socialDataApi } from 'components/legacy/services/socialData';
import { storageApi } from 'components/legacy/services/storages';

interface ComponentProps {
  name: string;
  description: string;
  connectorKey: string;
  tooltip: string;
  questionKey: string;
}

const UserComponent = ({ name, description, connectorKey, questionKey, tooltip }: ComponentProps) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));
  const dispatch = useDispatch();
  const [loader, setLoader] = useState<any>(false);

  const { data: userProfile, isLoading: loadingUserProfile } = useGetUserProfileQuery({});
  const { data: role, isLoading: loadingRoleAndPermissions } = useGetOrganizationMemberQuery({
    organizationId: userProfile?.data?.userProfile?.currentOrganizationId,
    userId: userProfile?.data?.userProfile?.id
  });
  const { data: roleDetail, isLoading: loadingRoleDetail } = useGetSingleRoleQuery({
    roleId: role?.data?.member?.roleId
  });

  const [connectionStatus] = useStorageValue<string>(connectorStorageKeys.google.connectionStatus, organizationId);
  const [getAcessToken] = useStorageValueUser<any>(connectorStorageKeys.google.accessToken, 0);
  const [tokenSelectUser] = useStorageValue<string>(connectorStorageKeys.googleSearch.selectedUserId, organizationId);

  const [domainUrl] = useStorageValue<string>(connectorStorageKeys.googleSearch.domainUrl, organizationId);
  const [domainUrls] = useStorageValueUser<{ url: string; domain: string }[]>(
    connectorStorageKeys.googleSearch.domainUrls,
    0
  );

  const { auditKey } = useParams();

  const { data: scoresQuery, isLoading: loadingScores } = useGetQuestionScoresQuery({
    organizationId,
    auditKey
  });

  const scores: QuestionScoreDto[] = useSelector<QuestionScoreDto[]>((state) =>
    scoresQuery?.data?.questions?.filter((s) => s.key === questionKey)
  );
  const score = _.sumBy(scores, (s) => s.score);

  const connectionExpired = connectionStatus.value === ConnectorAuthValues.expired;

  const accessToken = getAcessToken.value ? getAcessToken.value.token : null;

  const [selectSite] = useSelectSiteGoogleSearchMutation();
  const [deleteSelectTokenUser] = useDeleteSelectTokenUserMutation();
  const [postSelectTokenUser] = usePostSelectTokenUserMutation();

  const handleAssociateEntity = async (url) => {
    setLoader(true);
    await postSelectTokenUser({
      namespace: 'google-search',
      organizationId: organizationId,
      userId: userProfile?.data?.userProfile?.id
    });
    await selectSite({ googleSearchUrl: url });
    dispatch(socialDataApi.util.invalidateTags(['sn-data']));
    setLoader(false);
  };

  const handleDissociateEntity = async () => {
    setLoader(true);
    await deleteSelectTokenUser({ namespace: 'google-search', organizationId: organizationId });
    dispatch(socialDataApi.util.invalidateTags(['sn-data']));
    setLoader(false);
  };

  if (loader) return <CircularProgress />;

  return (
    <>
      <Header
        isScoreVisible={true}
        label={domainUrl.value || accessToken ? '' : description}
        organizationId={organizationId}
        score={score}
        title={name}
        tooltip={tooltip}
      />
      {accessToken && !connectionExpired ? (
        <>
          {(domainUrl.value || accessToken) && (
            <>
              {domainUrl.value ? (
                <Stack direction="row" spacing={2} sx={{ alignItems: 'center', mb: 2 }}>
                  <Typography>{domainUrl.value.split(':')[1]}</Typography>
                  <LoadingButton
                    loading={loader}
                    color="primary"
                    onClick={() => handleDissociateEntity()}
                    variant="contained"
                  >
                    <Trans>Dissocier ce compte</Trans>
                  </LoadingButton>
                </Stack>
              ) : (
                <>
                  <Stack direction="row" spacing={1} sx={{ mt: 2, mb: 2 }}>
                    <TextField
                      disabled={!roleDetail?.data?.role?.permissions.find((r) => r.key === 'answer') ? true : false}
                      id={'Dd_organization'}
                      label={t`Sélectionner un compte`}
                      margin="normal"
                      select={true}
                      sx={{
                        '& label': {
                          color: '#FF0000'
                        },
                        mb: 0,
                        width: '50%'
                      }}
                      value={''}
                    >
                      {_.map(domainUrls.value, (du) => {
                        return (
                          <MenuItem
                            onClick={() => {
                              handleAssociateEntity(du.url);
                            }}
                            key={du.url}
                            value={du.url}
                          >
                            {du.domain}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Stack>
                  <Typography sx={{ mt: 2, mb: 2 }} variant="body2">
                    <Trans>
                      Si la propriété de votre organisation n'apparait pas dans la liste, reconnectez un compte Google
                      ayant accès à la page de votre organisation.
                    </Trans>
                  </Typography>
                  <GoogleConnect
                    accessTokenKey={connectorStorageKeys.google.accessToken}
                    connectionStatusKey={connectorStorageKeys.google.connectionStatus}
                    tokenSelectUserKey={connectorStorageKeys.google.selectedUserId}
                  />
                </>
              )}
            </>
          )}
        </>
      ) : connectionExpired && tokenSelectUser.value === userProfile?.data?.userProfile.id ? (
        <Typography sx={{ color: (theme) => theme.palette.red.main }}>
          <Trans>Il semble que votre session Google ait expiré. Vous devez vous reconnecter à votre compte.</Trans>
        </Typography>
      ) : (
        ''
      )}
      {(!accessToken && !domainUrl.value) || connectionExpired ? (
        <>
          <GoogleConnect
            accessTokenKey={connectorStorageKeys.google.accessToken}
            connectionStatusKey={connectorStorageKeys.google.connectionStatus}
            tokenSelectUserKey={connectorStorageKeys.google.selectedUserId}
          />
        </>
      ) : (
        ''
      )}
    </>
  );
};

class GoogleSearch implements ICustomBlock {
  tooltip =
    () => t`Connectez le compte Google d'une personne ayant accès à la propriété Google Search Console de l'entreprise, afin d'avoir une analyse de ses métriques principales. Si vous ne trouvez pas votre site dans la liste, assurez-vous d'avoir accès au compte Google Search Console de votre entreprise (https://search.google.com/search-console), et qu'il soit validé par Google.
    FreewayTeam ne partage pas vos données avec des tiers, ne fait aucune action sur votre compte. Cette connexion est validée et contrôlée par Google, et les données sont supprimées de FreewayTeam si vous déconnectez votre compte Google.`;
  description = () => t`Connectez le compte de votre entreprise pour obtenir une analyse de ses données clés.`;
  name = () => t`Google Search Console`;
  key = () => 'google-search-connect';
  UserComponent: React.FC<UserComponentProps> = (props) => (
    <UserComponent
      connectorKey={this.key()}
      description={this.description()}
      name={this.name()}
      questionKey={props.questionKey}
      tooltip={this.tooltip()}
    />
  );
}

export default GoogleSearch;
