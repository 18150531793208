import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  lighten
} from '@mui/material';
import DisplayVulnerabilities from '../DispayVulnerabilities';
import { Trans } from '@lingui/macro';
import VulnerabilitiesTable from '../vulnerabilitiesTable';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Severity from 'components/legacy/models/types/severitiesType';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ServerTechnology } from 'components/legacy/models/domain-tech.dto';

interface Props {
  technologies: ServerTechnology[];
  selectedRowKey: string;
  selectedSubRowKey: string | null;
  setSelectedSubRowKey: (key: string | null) => void;
}

const getVulnerabilityCount = (technology: ServerTechnology, severity: string) =>
  technology?.Vulnerabilities?.reduce((count, vuln) => count + (vuln.Severity === severity ? 1 : 0), 0) ?? 0;

function TechnologiesTable({ technologies, selectedRowKey, selectedSubRowKey, setSelectedSubRowKey }: Props) {
  return technologies ? (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableCell></TableCell>
          <TableCell>
            <Trans>Technologies</Trans>
          </TableCell>
          <TableCell>
            <Trans>Version</Trans>
          </TableCell>
          <TableCell>
            <Trans>Dernière version</Trans>
          </TableCell>
          <TableCell>
            <Trans>Vulnérabilitées</Trans>
          </TableCell>
        </TableHead>
        <TableBody>
          {technologies
            .sort((techA, techB) => techA.Name.localeCompare(techB.Name))
            .map((tech) => {
              const subRowKey = selectedRowKey + '-' + tech.Name;
              const hasVulnerabilities = tech.Vulnerabilities && tech.Vulnerabilities.length !== 0;
              const isSelected = selectedSubRowKey === subRowKey;
              return (
                <>
                  <TableRow key={subRowKey}>
                    <TableCell>
                      {hasVulnerabilities && (
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => setSelectedSubRowKey(isSelected ? null : subRowKey)}
                        >
                          {isSelected ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      )}{' '}
                    </TableCell>
                    <TableCell>{tech.Name}</TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}
                      >
                        {tech.Version && tech.LatestVersion ? (
                          <>
                            <Box
                              sx={{
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                height: '30px',
                                width: '30px',
                                minWidth: '30px',
                                mr: 2
                              }}
                            >
                              {tech.Version === tech.LatestVersion ? (
                                <Box
                                  sx={{
                                    border: 1.5,
                                    borderColor: (theme) => theme.palette.success.main,
                                    display: 'block',
                                    background: (theme) => theme.palette.success.light,
                                    height: '100%',
                                    width: '100%',
                                    borderRadius: '20px'
                                  }}
                                >
                                  <CheckIcon
                                    sx={{
                                      fontSize: '20px',
                                      position: 'absolute',
                                      top: '50%',
                                      left: '50%',
                                      transform: 'translate(-50%,-50%)',
                                      color: (theme) => theme.palette.success.main
                                    }}
                                  />
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    border: 1.5,
                                    borderColor: (theme) => theme.palette.error.main,
                                    display: 'block',
                                    background: (theme) => theme.palette.error.light,
                                    height: '100%',
                                    width: '100%',
                                    borderRadius: '20px'
                                  }}
                                >
                                  <CloseIcon
                                    sx={{
                                      fontSize: '20px',
                                      position: 'absolute',
                                      top: '50%',
                                      left: '50%',
                                      transform: 'translate(-50%,-50%)',
                                      color: (theme) => theme.palette.error.main
                                    }}
                                  />
                                </Box>
                              )}
                            </Box>
                            {tech.Version}
                          </>
                        ) : (
                          <>
                            <Box
                              sx={{
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                height: '30px',
                                width: '30px',
                                minWidth: '30px',
                                mr: 2
                              }}
                            >
                              <Box
                                sx={{
                                  border: 1.5,
                                  borderColor: (theme) => theme.palette.info.main,
                                  display: 'block',
                                  background: (theme) => lighten(theme.palette.info.main, 0.7),
                                  height: '100%',
                                  width: '100%',
                                  borderRadius: '20px'
                                }}
                              >
                                <QuestionMarkIcon
                                  sx={{
                                    fontSize: '20px',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%,-50%)',
                                    color: (theme) => theme.palette.info.main
                                  }}
                                />
                              </Box>
                            </Box>
                            {tech.Version}
                          </>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>{tech.LatestVersion}</TableCell>
                    <TableCell>
                      {Object.values(Severity).map((severity, index) => (
                        <DisplayVulnerabilities
                          key={index}
                          value={getVulnerabilityCount(tech, severity)}
                          severity={severity}
                        />
                      ))}
                      {!hasVulnerabilities && 'Aucune'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                      <Collapse in={selectedSubRowKey === subRowKey} timeout="auto" unmountOnExit>
                        <Box
                          sx={{
                            py: 2
                          }}
                        >
                          <VulnerabilitiesTable vulnerabilities={tech.Vulnerabilities} />
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <h5>
      <Trans>Pas de technologie disponible</Trans>
    </h5>
  );
}
export default TechnologiesTable;
