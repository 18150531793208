// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const tipsApi = createApi({
  reducerPath: 'tipsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API,
    prepareHeaders: () => {
      return baseHeader(true);
    }
  }),
  endpoints: (build) => ({
    // ! on s'en sert encore ?
    getTipConditions: build.query({
      query: ({ auditKey }) => `/api/tips/conditions?auditKey=${auditKey}`
    }),
    getTip: build.query({
      query: ({ tipId }) => `/api/tips/${tipId}`
    }),
    // ! est ce qu'on l'utilise encore ?
    getAdminTips: build.query({
      query: ({ auditId, languageCode }) => `/api/tips/admin/audit/${auditId}?lg=${languageCode}`
    }),
    getAdminTip: build.query({
      query: ({ tipId }) => `/api/tips/admin/${tipId}`
    }),
    uploadTipImage: build.mutation({
      query: (formData) => ({
        url: `/api/tips/image`,
        method: 'POST',
        body: formData,
        formData: true
      })
    }),
    updateTipsValidationTranslation: build.mutation({
      query: (body: { tipsId: string; languageCode: string; isValidate: boolean }) => ({
        url: `/api/tips/translations/validation`,
        method: 'PUT',
        body
      })
    }),
    // ! est ce qu'on l'utilise encore ?
    updateTipsIndex: build.mutation({
      query: (body: { indexes: { id: number; index: number }[] }) => ({
        url: `/api/tips/indexes`,
        method: 'PUT',
        body
      })
    }),
    syncTipTranslations: build.mutation({
      query: (body: {
        translations: { title: string; content: string; languageCode: string; tipId: number; isValidate: boolean }[];
      }) => ({
        url: `/api/tips/translations`,
        method: 'PUT',
        body
      })
    }),
    editTip: build.mutation({
      query: (body: {
        id: number;
        imageUrl: string;
        learnMoreUrl: string;
        translations: { title: string; content: string; languageCode: string; tipId: number; isValidate: boolean }[];
      }) => ({
        url: `/api/tips`,
        method: 'PUT',
        body
      })
    }),
    // ! est ce qu'on l'utilise quelque part ?
    deleteTip: build.mutation({
      query: ({ id }) => ({
        url: `/api/tips/${id}`,
        method: 'DELETE'
      })
    }),
    addTip: build.mutation({
      query: (body: {
        imageUrl: string;
        learnMoreUrl: string;
        conditions: {
          conditionQuestionKey?: string;
          choiceKey?: string;
        }[];
        auditId: number;
      }) => ({
        url: `/api/tips`,
        method: 'POST',
        body
      })
    }),
    updateTipConditions: build.mutation({
      query: (body: {
        tipId: number;
        conditions: { tipId: number; conditionQuestionKey: string; choiceKey: string }[];
      }) => ({
        url: `/api/tips/conditions`,
        method: 'PUT',
        body
      })
    })
  })
});

export const {
  useAddTipMutation,
  useDeleteTipMutation,
  useEditTipMutation,
  useGetAdminTipQuery,
  useGetAdminTipsQuery,
  useGetTipConditionsQuery,
  useGetTipQuery,
  useUploadTipImageMutation,
  useUpdateTipsValidationTranslationMutation,
  useUpdateTipsIndexMutation,
  useSyncTipTranslationsMutation,
  useUpdateTipConditionsMutation,
  useLazyGetAdminTipQuery,
  useLazyGetAdminTipsQuery,
  useLazyGetTipConditionsQuery,
  useLazyGetTipQuery
} = tipsApi;
