import { t } from '@lingui/macro';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Typography,
  TableFooter,
  TablePagination,
  Button
} from '@mui/material';

import { useState } from 'react';
import AdsTableHead from './header';
import React from 'react';

import { round } from 'lodash';
import useMoment from 'components/legacy/hooks/useMoment';
import { PlayCircleOutline, PauseCircleOutline } from '@mui/icons-material';

import useStorageValue from 'components/legacy/hooks/useStorageValue';

import { Ad } from 'components/legacy/models/google-ads-ad';
import { DialogDetails } from './options-mobile';

const AdsTableMobile = ({
  rows,
  totalRows,
  pageSize,
  pageNumber,
  handleChangePage,
  handleChangeRowsPerPage,
  sortColumn,
  sortOrder,
  handleRequestSort
}) => {
  const [hoveredLinkId, setHoveredLinkId] = useState(null);
  const organizationId = Number(window.localStorage.getItem('organizationId'));

  const moment = useMoment();
  const datas = [];

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  rows?.map((element) => datas.push(JSON.parse(element?.data)));

  const AdsDetails = ({ row, index }) => {
    return (
      <Box sx={{ maxWidth: 'auto', display: 'flex', flexDirection: 'row', alignitem: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            align: 'center',
            verticalAlign: 'center'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              align: 'center',

              margin: 0
            }}
          >
            <Box
              sx={{
                display: 'flex',

                flexDirection: 'column',
                maxWidth: '250px'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {row.status === 'ACTIVE' ? (
                  <PlayCircleOutline color="success" />
                ) : (
                  <PauseCircleOutline color="disabled" />
                )}
                <Typography
                  sx={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    display: 'inline-block',
                    maxHeight: '1.5em',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 1
                  }}
                >
                  {/* row.name = titre de la campagne
              row.text = description de la campagne
              (row.name !== null ? row.adsId : 'titre')
              */}
                  {row.name}
                </Typography>
              </Box>
              <Typography
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  maxHeight: '1.5em',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1
                }}
              >
                {row.text}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return rows === null || rows?.length === 0 ? (
    <Typography variant="body2">{t`Pas de données pour le moment.`}</Typography>
  ) : (
    <TableContainer sx={{ maxHeight: '500px' }}>
      <Table size="small" stickyHeader>
        <AdsTableHead onRequestSort={handleRequestSort} sortColumn={sortColumn} sortOrder={sortOrder}></AdsTableHead>
        <TableBody>
          {rows === null ||
            (rows?.length === 0 && (
              <TableRow key="empty">
                <Typography variant="body2">{t`Aucune données n'a été trouvée`}</Typography>
              </TableRow>
            ))}
          {rows?.map((adDetails, index) => {
            return (
              <TableRow key={adDetails.adsId}>
                <TableCell>
                  <AdsDetails row={adDetails} index={index} />
                </TableCell>
                <TableCell align="right">
                  {new Intl.NumberFormat().format(round(adDetails.spent, 2)) +
                    (datas[index]?.currencyCode === 'USD' ? ' $' : ' €')}
                </TableCell>
                <TableCell align="right">{new Intl.NumberFormat().format(adDetails.impressions)}</TableCell>
                <TableCell align="right">{new Intl.NumberFormat().format(adDetails.clicks)}</TableCell>
                <TableCell align="right">
                  {new Intl.NumberFormat().format(round(datas[index].metrics?.averageCpc / 1000000, 2)) +
                    (datas[index]?.currencyCode === 'USD' ? ' $' : ' €')}
                </TableCell>
                <TableCell align="right">
                  {!(adDetails.spent / adDetails.impressions)
                    ? '0' + (datas[index]?.currencyCode === 'USD' ? ' $' : ' €')
                    : new Intl.NumberFormat().format(round(adDetails.spent / adDetails.impressions, 2)) +
                      (datas[index]?.currencyCode === 'USD' ? ' $' : ' €')}
                </TableCell>

                <TableCell align="right">{new Intl.NumberFormat().format(datas[index].metrics?.conversions)}</TableCell>
                <TableCell align="right">
                  <Button onClick={handleClickOpen}>Voir détails</Button>
                  <DialogDetails data={datas[index]} open={open} onClose={handleClose} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={rows !== null && rows?.length > 0 ? [10, 20] : []}
              colSpan={2}
              count={totalRows}
              rowsPerPage={pageSize}
              page={pageNumber}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={''}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
export default AdsTableMobile;
