// Externals
import { t } from '@lingui/macro';
import { CircularProgress } from '@mui/material';

// Internals
import { ICustomBlock, UserComponentProps } from '../../index';
import { Header } from 'components/legacy/pages/audit/questions/header';
import TableTaskTodo from './tableTaskTodo';

// Redux
import { useGetOrganizationTaskItemsQuery } from 'components/legacy/services/taskitems';
import { useGetUserProfileQuery } from 'components/legacy/services/userProfiles';

interface ComponentProps {
  name: string;
  description: string;
  connectorKey: string;
  questionKey: string;
  tooltip: string;
  isScoreVisible: boolean;
}

const UserComponent = (props: ComponentProps) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));

  const { data: userProfile, isLoading: loadingUserProfile } = useGetUserProfileQuery({});
  const { data: row, isLoading: rowLoading } = useGetOrganizationTaskItemsQuery({ organizationId });

  if (rowLoading) {
    return (
      <>
        <Header
          isScoreVisible={props.isScoreVisible}
          label={props.description}
          organizationId={organizationId}
          title={props.name}
          tooltip={props.tooltip}
        />
        <CircularProgress />
      </>
    );
  }

  return (
    <>
      <Header
        isScoreVisible={props.isScoreVisible}
        label={props.description}
        organizationId={organizationId}
        title={props.name}
        tooltip={props.tooltip}
      />
      <TableTaskTodo
        displayLink={true}
        isAdmin={userProfile?.data?.userProfile?.role}
        rows={row.data.taskItems}
      ></TableTaskTodo>
    </>
  );
};

class TaskItemsList implements ICustomBlock {
  name = () => t`Tasks List`;
  description = () =>
    t`Voici les tâches prioritaires à accomplir afin d'améliorer la digitalisation de votre entreprise.`;
  key = () => 'taskitem-display';
  tooltip = () =>
    t`Ces 10 tâches sont celles que nous considérons les plus critiques. Si vous n'avez pas le temps ou la compétence pour les réaliser, nous pouvons vous aider. Sinon, il vous est aussi possible de reporter cette tâche à plus tard, et une autre tâche apparaitra.`;
  isScoreVisible = () => false;
  UserComponent: React.FC<UserComponentProps> = (props) => (
    <UserComponent
      connectorKey={this.key()}
      description={this.description()}
      isScoreVisible={this.isScoreVisible()}
      name={this.name()}
      questionKey={props.questionKey}
      tooltip={this.tooltip()}
    />
  );
}
export default TaskItemsList;
