import * as React from 'react';

// Internals
import { ICustomBlock, UserComponentProps } from '../../index';
import { Header } from 'components/legacy/pages/audit/questions/header';

// Externals
import { t } from '@lingui/macro';
import { Box, CircularProgress, Rating, Typography } from '@mui/material';

// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';

// Redux
import { useSelector } from 'components/legacy/store';

// Services
import { QuestionScoreDto } from 'components/legacy/models/questionScore.dto';
import _ from 'lodash';
import ReviewProgress from './components/ReviewProgress';
import { useGetQuestionScoresQuery } from 'components/legacy/services/questions';
import { useParams } from 'react-router-dom';

interface ComponentProps {
  name: string;
  description: string;
  toolTip: string;
  questionKey: string;
}

export interface GoogleBusinessProfileReviewsDTO {
  reviewScore: number;
  numberReviews: number;
  reviewScore1Count: number;
  reviewScore2Count: number;
  reviewScore3Count: number;
  reviewScore4Count: number;
  reviewScore5Count: number;
}

const GOOGLE_BUSINESS_PROFILE_REVIEWS = 'seo:google-business-profile-reviews';

const ReviewsMax = (reviews: GoogleBusinessProfileReviewsDTO) => {
  let max = 0;

  if (reviews.reviewScore5Count > max) max = reviews.reviewScore5Count;
  if (reviews.reviewScore4Count > max) max = reviews.reviewScore4Count;
  if (reviews.reviewScore3Count > max) max = reviews.reviewScore3Count;
  if (reviews.reviewScore2Count > max) max = reviews.reviewScore2Count;
  if (reviews.reviewScore1Count > max) max = reviews.reviewScore1Count;

  return max;
};

const UserComponent = ({ name, description, toolTip, questionKey }: ComponentProps) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));

  const { auditKey } = useParams();

  const { data: scoresQuery, isLoading: loadingScores } = useGetQuestionScoresQuery({
    organizationId,
    auditKey
  });

  const scores: QuestionScoreDto[] = useSelector<QuestionScoreDto[]>((state) =>
    scoresQuery?.data?.questions?.filter((s) => s.key === questionKey)
  );
  const score = _.sumBy(scores, (s) => s.score);

  const [gbpReviews] = useStorageValue<GoogleBusinessProfileReviewsDTO>(
    GOOGLE_BUSINESS_PROFILE_REVIEWS,
    organizationId
  );

  return (
    <>
      <Header
        isScoreVisible={true}
        label={description}
        organizationId={organizationId}
        score={score}
        title={name}
        tooltip={toolTip}
      />
      {gbpReviews.loading && <CircularProgress />}
      {!gbpReviews.loading && gbpReviews.value && (
        <Box sx={{ mb: 2 }}>
          <Box display="flex" alignItems="center">
            <Typography variant="h3">
              {t`Score`}: {gbpReviews.value.reviewScore}
            </Typography>
            <Rating
              name="half-rating"
              defaultValue={gbpReviews.value.reviewScore}
              precision={0.1}
              sx={{ ml: 1 }}
              readOnly={true}
            />
            <Typography sx={{ ml: 1 }}>
              {gbpReviews.value.numberReviews} {t` avis`}
            </Typography>
          </Box>
          <ReviewProgress
            label="5"
            reviewScore={gbpReviews.value.reviewScore5Count}
            reviewMax={ReviewsMax(gbpReviews.value)}
          />
          <ReviewProgress
            label="4"
            reviewScore={gbpReviews.value.reviewScore4Count}
            reviewMax={ReviewsMax(gbpReviews.value)}
          />
          <ReviewProgress
            label="3"
            reviewScore={gbpReviews.value.reviewScore3Count}
            reviewMax={ReviewsMax(gbpReviews.value)}
          />
          <ReviewProgress
            label="2"
            reviewScore={gbpReviews.value.reviewScore2Count}
            reviewMax={ReviewsMax(gbpReviews.value)}
          />
          <ReviewProgress
            label="1"
            reviewScore={gbpReviews.value.reviewScore1Count}
            reviewMax={ReviewsMax(gbpReviews.value)}
          />
        </Box>
      )}
    </>
  );
};

class GoogleBusinessProfileReviews implements ICustomBlock {
  description = () => t`Voici les avis laissés sur Google concernant votre entreprise : `;
  name = () => t`Avis Google`;
  key = () => 'google-bp-reviews';
  UserComponent: React.FC<UserComponentProps> = (props) => (
    <UserComponent
      description={this.description()}
      name={this.name()}
      toolTip={props.toolTip}
      questionKey={props.questionKey}
    />
  );
}

export default GoogleBusinessProfileReviews;
