// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const translationApi = createApi({
  reducerPath: 'translationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API,
    prepareHeaders: () => {
      return baseHeader(true);
    }
  }),
  endpoints: (build) => ({
    getUsageTranslation: build.query({
      query: () => `/api/translations/usage`
    }),
    getTipsTranslation: build.query({
      query: () => `/api/translations/getTips`
    }),
    getTaskItemsTranslation: build.query({
      query: () => `/api/translations/getTaskItems`
    }),
    getQuestsTranslation: build.query({
      query: () => `/api/translations/getQuests`
    }),
    getQuestionsTranslation: build.query({
      query: () => `/api/translations/getQuestions`
    }),
    getBlogTranslation: build.query({
      query: () => `/api/translations/getBlog`
    }),
    getSectionTranslation: build.query({
      query: () => `/api/translations/getSections`
    }),
    editAutoTranslation: build.mutation({
      query: () => ({
        url: `/api/translations/translate`,
        method: 'POST'
      })
    })
  })
});

export const {
  useEditAutoTranslationMutation,
  useGetBlogTranslationQuery,
  useGetSectionTranslationQuery,
  useGetQuestionsTranslationQuery,
  useGetQuestsTranslationQuery,
  useGetTaskItemsTranslationQuery,
  useGetTipsTranslationQuery,
  useGetUsageTranslationQuery,
  useLazyGetBlogTranslationQuery,
  useLazyGetQuestionsTranslationQuery,
  useLazyGetQuestsTranslationQuery,
  useLazyGetTaskItemsTranslationQuery,
  useLazyGetTipsTranslationQuery,
  useLazyGetUsageTranslationQuery,
  useLazyGetSectionTranslationQuery
} = translationApi;
