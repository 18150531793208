// Externals
import { t } from '@lingui/macro';

// Internals
import { ICustomBlock, UserComponentProps } from '../../index';
import { Header } from 'components/legacy/pages/audit/questions/header';

// Redux
import { useSelector } from 'components/legacy/store';
import TableQuest from './table-quests';

interface ComponentProps {
  name: string;
  description: string;
  connectorKey: string;
  questionKey: string;
  tooltip: string;
  isScoreVisible: boolean;
}

const UserComponent = (props: ComponentProps) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));

  return (
    <>
      <Header
        isScoreVisible={props.isScoreVisible}
        label={props.description}
        organizationId={organizationId}
        title={props.name}
        tooltip={props.tooltip}
      />
      <TableQuest displayLink={true} />
    </>
  );
};

class TaskItemsList implements ICustomBlock {
  name = () => t`To-do List`;
  description = () => t`Voici la liste des tâches à effectuer cette semaine :`;
  key = () => 'questitem-display';
  tooltip = () =>
    t`Le nombre de tâches hebdomadaires peut être changé dans vos paramètres. Postposez ou ignorez les tâches qui ne vous concerne pas actuellement. Certaines tâches se valident automatiquement, d'autres doivent être cochées manuellement une fois finalisées. Certaines tâches sont récurrentes et reviendront dans le futur.`;
  isScoreVisible = () => false;
  UserComponent: React.FC<UserComponentProps> = (props) => (
    <UserComponent
      connectorKey={this.key()}
      description={this.description()}
      isScoreVisible={this.isScoreVisible()}
      name={this.name()}
      questionKey={props.questionKey}
      tooltip={this.tooltip()}
    />
  );
}
export default TaskItemsList;
