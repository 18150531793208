import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';

// Externals
import * as signalR from '@microsoft/signalr';
import toast from 'react-hot-toast';

// Store
import { useDispatch } from '../../store';

// Slices
import { t } from '@lingui/macro';
import { Verified } from '@mui/icons-material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { organizationsApi } from 'components/legacy/services/organizations';
import { taskItemApi } from 'components/legacy/services/taskitems';
import { questsApi } from 'components/legacy/services/quests';
import { questionsApi } from 'components/legacy/services/questions';
import { useGetUserProfileQuery } from 'components/legacy/services/userProfiles';
import { socialDataApi } from 'components/legacy/services/socialData';
interface SignalRProviderProps {
  children: ReactNode;
}

interface SignalRProviderContext {
  registerStorageHandler: (handler: (data: any) => void) => void;
  removeStorageHandler: (handler: (data: any) => void) => void;
  registerSocialAdsHandler: (handler: (data: any) => void) => void;
  removeSocialAdsHandler: (handler: (data: any) => void) => void;
  registerSocialDataHandler: (handler: (data: any) => void) => void;
  removeSocialDataHandler: (handler: (data: any) => void) => void;
  registerKeywordHandler: (handler: (data: any) => void) => void;
  removeKeywordHandler: (handler: (data: any) => void) => void;
  registerStorageUserHandler: (handler: (data: any) => void) => void;
  removeStorageUserHandler: (handler: (data: any) => void) => void;
}

const SignalRContext = createContext<SignalRProviderContext>({
  registerStorageHandler: () => {},
  removeStorageHandler: () => {},
  registerSocialAdsHandler: () => {},
  removeSocialAdsHandler: () => {},
  registerSocialDataHandler: () => {},
  removeSocialDataHandler: () => {},
  registerKeywordHandler: () => {},
  removeKeywordHandler: () => {},
  registerStorageUserHandler: () => {},
  removeStorageUserHandler: () => {}
});

export const useSignalRContext = () => useContext(SignalRContext);

const SignalRProvider: FC<SignalRProviderProps> = (props) => {
  const dispatch = useDispatch();
  const { data: userProfile, isLoading: loadingUserProfile } = useGetUserProfileQuery({});

  const isConnected = !!userProfile?.data?.userProfile?.currentOrganizationId;
  const fallbackLanguage = 'fr';
  const isProdEnvironment = process.env.NEXT_PUBLIC_ENV === 'production';
  const [oldOrganizationId, setOldOrganizationId] = useState<number>(0);

  const [ready, setReady] = useState<boolean>(false);

  const connectionRef = useRef<signalR.HubConnection>();

  const connectSignalR = useCallback(async () => {
    connectionRef.current = new signalR.HubConnectionBuilder()
      .withUrl(process.env.NEXT_PUBLIC_API + '/hub/seo', { withCredentials: false })
      .withAutomaticReconnect()
      .configureLogging(
        !isProdEnvironment && userProfile?.data?.userProfile?.role ? signalR.LogLevel.None : signalR.LogLevel.Debug
      )
      .build();

    try {
      await connectionRef.current.start();
      if (connectionRef.current.state === signalR.HubConnectionState.Connected) {
        window.sessionStorage.setItem('SR-ConnectionId', connectionRef.current.connectionId);
        setReady(true);
      }
    } catch (e) {
      document.write(e);
      setReady(false);
    }
  }, [userProfile, isProdEnvironment]);

  const unconnectSignalR = useCallback(async () => {
    if (connectionRef.current && connectionRef.current.state === signalR.HubConnectionState.Connected) {
      if (oldOrganizationId) {
        await unsubscribeFromOrganization(String(oldOrganizationId));
      }

      try {
        await connectionRef.current.stop();
      } catch (error) {
        console.log('connection closed error', error);
      } finally {
        setReady(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldOrganizationId]);

  const subscribeToOrganization = (organizationId: string) =>
    connectionRef.current.invoke('register', organizationId, userProfile?.data?.userProfile?.id);
  const unsubscribeFromOrganization = (organizationId: string) =>
    connectionRef.current.invoke('signout', organizationId, userProfile?.data?.userProfile?.id);

  useEffect(() => {
    if (isConnected) {
      connectSignalR();
    } else {
      unconnectSignalR();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  const toastAccomplishment = (message: string, AccomplishmentType: 'task' | 'quest', isPublish: boolean) => {
    const informUser = isPublish || userProfile?.data?.userProfile?.role;
    if (informUser) {
      toast.success(`${message}`, {
        duration: 5000,
        icon:
          AccomplishmentType === 'task' ? (
            <Verified fontSize="large" sx={{ m: 1, color: '#D1840D', width: 50, height: 50 }} />
          ) : AccomplishmentType === 'quest' ? (
            <TaskAltIcon fontSize="large" sx={{ m: 1, color: '#D1840D', width: 50, height: 50 }} />
          ) : null,
        id: 'Accomplishment-info-' + Math.floor(1000 + Math.random() * 9000),
        style: {
          background: '#FEEBC8',
          color: '#D1840D',
          border: userProfile?.data?.userProfile?.role && !isPublish ? '3px dotted #727BFE' : undefined
        }
      });
    }
  };

  useEffect(() => {
    if (ready) {
      connectionRef.current.off('SeoResultAvailable');
      connectionRef.current.off('UpdateAnswer');
      connectionRef.current.off('RemoveAnswer');
      connectionRef.current.off('TaskItemCompleted');
      connectionRef.current.off('QuestCompleted');

      connectionRef.current.on('SeoResultAvailable', (message: string) => {
        dispatch(organizationsApi.util.invalidateTags(['OrganizationDetails']));
        toast.success(t`Les résultats de l'audit ont été récupérés.`);
      });

      connectionRef.current.on('UpdateAnswer', (answer: any) => {
        dispatch(questionsApi.util.invalidateTags(['answers', 'question-scores', 'questions-condition']));
        dispatch(organizationsApi.util.invalidateTags(['OrganizationDetails']));
        dispatch(socialDataApi.util.invalidateTags(['sn-data']));
      });

      connectionRef.current.on('RemoveAnswer', (answerId: number) => {
        dispatch(questionsApi.util.invalidateTags(['answers', 'question-scores', 'questions-condition']));
        dispatch(organizationsApi.util.invalidateTags(['OrganizationDetails']));
        dispatch(socialDataApi.util.invalidateTags(['sn-data']));
      });

      connectionRef.current.on(
        'TaskItemCompleted',
        (tasksCompleted: [{ [key: string]: { [key: string]: string }[] }][]) => {
          dispatch(taskItemApi.util.invalidateTags(['taskitems']));
          dispatch(taskItemApi.util.invalidateTags(['taskitemsAccomplishment']));

          if (tasksCompleted) {
            const userLanguage = window.localStorage.getItem('preferred-language');
            for (let task in tasksCompleted) {
              const traduction = tasksCompleted[task][userLanguage] || tasksCompleted[task][fallbackLanguage];
              const message = t`Vous avez complété la tâche: ` + traduction;
              toastAccomplishment(message, 'task', true);
            }
          }
        }
      );

      connectionRef.current.on(
        'QuestItemCompleted',
        (questCompleted: [{ [key: string]: { [key: string]: string }[] }][], isPublish: boolean) => {
          dispatch(questsApi.util.invalidateTags(['organization-quests']));

          if (questCompleted) {
            const userLanguage = window.localStorage.getItem('preferred-language');
            for (let quest in questCompleted) {
              const translation = questCompleted[quest][userLanguage] || questCompleted[quest][fallbackLanguage];
              const hasTranslation = translation !== undefined;
              const message = hasTranslation ? translation + t` : tâche validée` : 'translation not found: ' + quest;
              toastAccomplishment(message, 'quest', isPublish && hasTranslation);
            }
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, ready]);

  useEffect(() => {
    if (ready && userProfile?.data?.userProfile?.currentOrganizationId && userProfile?.data?.userProfile?.id) {
      (async () => {
        await subscribeToOrganization(String(userProfile?.data?.userProfile?.currentOrganizationId));
        setOldOrganizationId(userProfile?.data?.userProfile?.currentOrganizationId);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile, ready]);

  const registerStorageHandler = useCallback((handler) => {
    if (connectionRef.current) {
      connectionRef.current.on('StorageValueChanged', handler);
    }
  }, []);

  const removeStorageHandler = useCallback((handler) => {
    if (connectionRef.current) {
      connectionRef.current.off('StorageValueChanged', handler);
    }
  }, []);

  const registerSocialAdsHandler = useCallback((handler) => {
    if (connectionRef.current) {
      connectionRef.current.on('SocialAdsValueChanged', handler);
    }
  }, []);

  const removeSocialAdsHandler = useCallback((handler) => {
    if (connectionRef.current) {
      connectionRef.current.off('SocialAdsValueChanged', handler);
    }
  }, []);

  const registerSocialDataHandler = useCallback((handler) => {
    if (connectionRef.current) {
      connectionRef.current.on('SocialDataValueChanged ', handler);
    }
  }, []);

  const removeSocialDataHandler = useCallback((handler) => {
    if (connectionRef.current) {
      connectionRef.current.off('SocialDataValueChanged ', handler);
    }
  }, []);

  const registerKeywordHandler = useCallback((handler) => {
    if (connectionRef.current) {
      connectionRef.current.on('KeywordValueChanged', handler);
    }
  }, []);

  const removeKeywordHandler = useCallback((handler) => {
    if (connectionRef.current) {
      connectionRef.current.off('KeywordValueChanged', handler);
    }
  }, []);

  const registerStorageUserHandler = useCallback((handler) => {
    if (connectionRef.current) {
      connectionRef.current.on('UserStorageValueChanged', handler);
    }
  }, []);

  const removeStorageUserHandler = useCallback((handler) => {
    if (connectionRef.current) {
      connectionRef.current.off('UserStorageValueChanged', handler);
    }
  }, []);

  return (
    <SignalRContext.Provider
      value={{
        registerStorageHandler,
        removeStorageHandler,
        registerStorageUserHandler,
        removeStorageUserHandler,
        registerKeywordHandler,
        removeKeywordHandler,
        registerSocialAdsHandler,
        removeSocialAdsHandler,
        registerSocialDataHandler,
        removeSocialDataHandler
      }}
    >
      {props.children}
    </SignalRContext.Provider>
  );
};

export default SignalRProvider;
