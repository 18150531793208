// Internals
import { ICustomBlock, UserComponentProps } from '../../index';
import { Header } from 'components/legacy/pages/audit/questions/header';
import ConnectedEntitySelection from 'components/legacy/components/connected-entity-selection';

// Externals
import _ from 'lodash';
import { t, Trans } from '@lingui/macro';
import { Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { v4 as uuid } from 'uuid';

// Models
import { QuestionScoreDto } from 'components/legacy/models/questionScore.dto';
import { ComponentProps } from '../models';

// Redux
import { useSelector } from 'components/legacy/store';

// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';
import connectorStorageKeys, { connectorStorageProperties } from 'components/legacy/connectors/connectorStorageKeys';
import ConnectorAuthValues from 'components/legacy/connectors/connectorAuthValues';
import useStorageValueUser from 'components/legacy/hooks/useStorageValueUser';

// Query
import { useAddLinkedinStateMutation, useRemoveConnectionMutation } from 'components/legacy/services/connectors';
import { useDeleteSelectTokenUserMutation } from 'components/legacy/services/organizations';
import { useGetQuestionScoresQuery } from 'components/legacy/services/questions';
import { useParams } from 'react-router-dom';
import { useGetUserProfileQuery } from 'components/legacy/services/userProfiles';
import { useGetSingleRoleQuery } from 'components/legacy/services/organization-roles';
import { useGetOrganizationMemberQuery } from 'components/legacy/services/organizationMember';

const UserComponent = ({ name, description, connectorKey, questionKey, tooltip }: ComponentProps) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));
  const { data: userProfile, isLoading: loadingUserProfile } = useGetUserProfileQuery({});
  const { data: role, isLoading: loadingRoleAndPermissions } = useGetOrganizationMemberQuery({
    organizationId: userProfile?.data?.userProfile?.currentOrganizationId,
    userId: userProfile?.data?.userProfile?.id
  });
  const { data: roleDetail, isLoading: loadingRoleDetail } = useGetSingleRoleQuery({
    roleId: role?.data?.member?.roleId
  });

  const [connectionStatus] = useStorageValue<string>(connectorStorageKeys.linkedin.connectionStatus, organizationId);
  const [getAcessToken] = useStorageValueUser<any>(connectorStorageKeys.linkedin.accessToken, 0);
  const [tokenSelectUser] = useStorageValue<string>(connectorStorageKeys.linkedin.selectedUserId, organizationId);

  const { auditKey } = useParams();

  const { data: scoresQuery, isLoading: loadingScores } = useGetQuestionScoresQuery({
    organizationId,
    auditKey
  });

  const scores: QuestionScoreDto[] = useSelector<QuestionScoreDto[]>((state) =>
    scoresQuery?.data?.questions?.filter((s) => s.key === questionKey)
  );
  const score = _.sumBy(scores, (s) => s.score);

  const connectionExpired = connectionStatus.value === ConnectorAuthValues.expired;

  const accessToken = getAcessToken.value ? getAcessToken.value.token : null;

  const [addState, { isLoading: loadingConnect }] = useAddLinkedinStateMutation();
  const [removeConnection, { isLoading: loadingDisconnect }] = useRemoveConnectionMutation();
  const [deleteSelectTokenUser, { isLoading: loadingDeleteToken }] = useDeleteSelectTokenUserMutation();

  const getConnectUrl = (stateCode: string) => {
    const url = new URL('https://www.linkedin.com/oauth/v2/authorization');
    url.searchParams.append('redirect_uri', `${window.location.origin}/connectors/linkedin/auth`);
    url.searchParams.append('prompt', 'consent');
    url.searchParams.append('response_type', 'code');
    url.searchParams.append('client_id', `${process.env.NEXT_PUBLIC_LINKEDIN_CLIENT_ID}`);
    url.searchParams.append(
      'scope',
      'r_basicprofile,rw_organization_admin,r_ads_reporting,r_organization_social,r_ads'
    );
    url.searchParams.append('state', stateCode); // A value used to test for possible CSRF attacks.

    return url.toString();
  };

  const handleLogin = async () => {
    const stateCode = uuid().slice(0, 8);
    await addState({
      state: stateCode
    });

    const url = await getConnectUrl(stateCode);
    const newTabInstance = window.open('', '_blank');
    (await newTabInstance) && (newTabInstance.location.href = url);
  };

  const handleLogout = async () => {
    if (tokenSelectUser.value) await deleteSelectTokenUser({ namespace: 'linkedin', organizationId: organizationId });
    await removeConnection({
      connectorAuthName: 'linkedin'
    });
  };

  return (
    <>
      <Header
        isScoreVisible={true}
        label={''}
        organizationId={organizationId}
        score={score}
        title={name}
        tooltip={tooltip}
      />
      {accessToken ? (
        <>
          {!tokenSelectUser.value ||
          tokenSelectUser.value !== userProfile?.data?.userProfile.id ||
          !connectionExpired ? (
            <ConnectedEntitySelection
              connector="linkedin"
              connectedEntityPropKey={connectorStorageProperties.connectedEntities}
              selectedEntityPropKeys={connectorStorageProperties.selectedEntity}
            />
          ) : (
            ''
          )}
          {!tokenSelectUser.value || connectionExpired ? (
            <>
              <Typography sx={{ pb: 2 }} variant="body2">
                {connectionExpired &&
                tokenSelectUser.value &&
                tokenSelectUser.value === userProfile?.data?.userProfile.id ? (
                  <Typography sx={{ color: (theme) => theme.palette.red.main }}>
                    <Trans>
                      Il semble que votre session LinkedIn ait expiré. Vous devez vous reconnecter à votre compte.
                    </Trans>
                  </Typography>
                ) : accessToken ? (
                  <Trans>
                    Si le compte de votre organisation n'apparait pas dans la liste, reconnectez un compte LinkedIn
                    ayant accès à la page de votre organisation.
                  </Trans>
                ) : (
                  <Trans>Connectez le compte de votre entreprise pour obtenir une analyse de ses données clés.</Trans>
                )}
              </Typography>
              <LoadingButton
                disabled={
                  loadingConnect ||
                  loadingDisconnect ||
                  !roleDetail?.data?.role?.permissions.find((r) => r.key === 'answer')
                    ? true
                    : false
                }
                loading={loadingConnect || loadingDisconnect}
                onClick={() => (!connectionExpired && accessToken ? handleLogout() : handleLogin())}
                startIcon={
                  <Box
                    component="img"
                    src="/static/icons/brand/linkedin_color.png"
                    sx={{
                      height: '24px'
                    }}
                  />
                }
                sx={{
                  borderColor: (theme) => theme.palette.text.primary,
                  border: 1.5,
                  color: (theme) => theme.palette.text.primary,
                  borderRadius: '18px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  py: 0.5,
                  px: 1.5,
                  whiteSpace: 'nowrap',
                  maxWidth: 'max-content',
                  height: '44px'
                }}
              >
                {!connectionExpired && accessToken ? t`Se déconnecter` : t`Se connecter à Linkedin`}
              </LoadingButton>
            </>
          ) : (
            ''
          )}
        </>
      ) : (
        <>
          <Typography sx={{ pb: 2 }} variant="body2">
            {connectionExpired &&
            tokenSelectUser.value &&
            tokenSelectUser.value === userProfile?.data?.userProfile.id ? (
              <Typography sx={{ color: (theme) => theme.palette.red.main }}>
                <Trans>
                  Il semble que votre session LinkedIn ait expiré. Vous devez vous reconnecter à votre compte.
                </Trans>
              </Typography>
            ) : accessToken ? (
              <Trans>
                Si le compte de votre organisation n'apparait pas dans la liste, reconnectez un compte LinkedIn ayant
                accès à la page de votre organisation.
              </Trans>
            ) : (
              <Trans>Connectez le compte de votre entreprise pour obtenir une analyse de ses données clés.</Trans>
            )}
          </Typography>
          <LoadingButton
            disabled={
              loadingConnect ||
              loadingDisconnect ||
              !roleDetail?.data?.role?.permissions.find((r) => r.key === 'answer')
                ? true
                : false
            }
            loading={loadingConnect || loadingDisconnect}
            onClick={() => (!connectionExpired && accessToken ? handleLogout() : handleLogin())}
            startIcon={
              <Box
                component="img"
                src="/static/icons/brand/linkedin_color.png"
                sx={{
                  height: '24px'
                }}
              />
            }
            sx={{
              borderColor: (theme) => theme.palette.text.primary,
              border: 1.5,
              color: (theme) => theme.palette.text.primary,
              borderRadius: '18px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              py: 0.5,
              px: 1.5,
              whiteSpace: 'nowrap',
              maxWidth: 'max-content',
              height: '44px'
            }}
          >
            {!connectionExpired && accessToken ? t`Se déconnecter` : t`Se connecter à Linkedin`}
          </LoadingButton>
        </>
      )}
    </>
  );
};

class LinkedInConnect implements ICustomBlock {
  description = () => t`Connectez le compte de votre entreprise pour obtenir une analyse de ses données clés.`;
  name = () => t`Linkedin`;
  key = () => 'linkedin-connect';
  tooltip =
    () => t`Connectez un compte personnel administrateur de la page LinkedIn de l'entreprise pour avoir accès à ses chiffres clés , tels que le nombre de followers, le nombre de visites sur votre page et le nombre de likes, et des graphes de leur évolution. 
    FreewayTeam ne partage pas vos données avec des tiers, ne fait aucune action avec votre compte entreprise, et n'a pas accès à votre compte privé. Cette connexion est validée et contrôlée par LinkedIn, et les données sont supprimées de FreewayTeam si vous déconnectez votre compte LinkedIn.`;
  UserComponent: React.FC<UserComponentProps> = (props) => (
    <UserComponent
      connectorKey={this.key()}
      description={this.description()}
      name={this.name()}
      questionKey={props.questionKey}
      tooltip={this.tooltip()}
    />
  );
}

export default LinkedInConnect;
