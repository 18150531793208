// Internals
import { ICustomBlock, UserComponentProps } from '../../index';
import { Header } from 'components/legacy/pages/audit/questions/header';

// Externals
import { t } from '@lingui/macro';
import { isEmpty } from 'lodash';
import _ from 'lodash';

// Models
import { QuestionScoreDto } from 'components/legacy/models/questionScore.dto';

// Redux
import { useSelector } from 'components/legacy/store';

// Services
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import useStorageValue from 'components/legacy/hooks/useStorageValue';
import { useGetQuestionScoresQuery } from 'components/legacy/services/questions';
import { useParams } from 'react-router';

// We could create a custom Admin Component if necessary
// const AdminComponent = () => {
//   return <div>Hello</div>;
// };

interface ComponentProps {
  name: string;
  description: string;
  connectorKey: string;
  questionKey: string;
}

const BACKLINKS = 'seo:backlink-list';

const UserComponent = ({ name, description, connectorKey, questionKey }: ComponentProps, props) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));
  const { auditKey } = useParams();

  const { data: scoresQuery, isLoading: loadingScores } = useGetQuestionScoresQuery({
    organizationId,
    auditKey
  });

  const scores: QuestionScoreDto[] = useSelector<QuestionScoreDto[]>((state) =>
    scoresQuery?.data?.questions?.filter((s) => s.key === questionKey)
  );
  const score = _.sumBy(scores, (s) => s.score);

  const [mapping] = useStorageValue<any>(BACKLINKS, organizationId);
  const data = mapping.value;

  if (mapping.loading) {
    return <CircularProgress />;
  }

  const columns = [
    { id: 'index', label: '', minWidth: 30 },
    { id: 'domain', label: t`Domaine`, minWidth: 100 },
    { id: 'link', label: t`Lien`, minWidth: 150 },
    { id: 'authority', label: t`DA`, minWidth: 50 }
  ];

  return (
    <>
      <Header isScoreVisible={false} label={description} organizationId={organizationId} score={score} title={name} />
      {!isEmpty(data) ? (
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {_.map(columns, (column) => (
                  <TableCell key={column.id} sx={{ paddingTop: 1, paddingBottom: 1, minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(data, (backlink, i) => (
                <TableRow key={backlink.url}>
                  <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }}>{i + 1}</TableCell>
                  <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }}>
                    {new URL(`https://${backlink.url}`).hostname}
                  </TableCell>
                  <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }}>
                    <a href={`https://${backlink.url}`}>{backlink.anchorText}</a>
                  </TableCell>
                  <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }}>{backlink.domainAuthority}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        t`Nous n'avons trouvé aucun backlinks pour votre site`
      )}
    </>
  );
};

class SEOptimerBacklinks implements ICustomBlock {
  description = () => t`TOP 10 des backlinks les plus influents sur votre site.`;
  name = () => t`TOP 10 des backlinks.`;
  key = () => 'seo-backlinks';
  UserComponent: React.FC<UserComponentProps> = (props) => (
    <UserComponent
      connectorKey={this.key()}
      description={this.description()}
      name={this.name()}
      questionKey={props.questionKey}
    />
  );
}

export default SEOptimerBacklinks;
