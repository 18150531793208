// Libraries
export const firebase = {
  apiKey: process.env.NEXT_PUBLIC_API_KEY,
  appId: process.env.NEXT_PUBLIC_APP_ID,
  authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
  messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
  projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET
};

// Variables
export const appCheckToken = process.env.NEXT_PUBLIC_APP_CHECK_TOKEN;
export const toastOptions = { success: { duration: 5000 }, error: { duration: 8000 }, style: {} };
export const toastLimit = 5;
