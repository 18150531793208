// Externals
import { Box, LinearProgress, Typography } from '@mui/material';
import { t } from '@lingui/macro';

interface ReviewProgressProps {
  label: string;
  reviewScore: number;
  reviewMax: number;
}

const ReviewProgress = (props: ReviewProgressProps) => {
  return (
    <Box display="flex" alignItems="center">
      <Typography>{props.label}</Typography>
      <Box sx={{ width: '100%', maxWidth: '250px', ml: 2 }}>
        <LinearProgress
          variant="determinate"
          sx={{ mt: 1, mb: 1 }}
          value={(props.reviewScore * 100) / props.reviewMax}
        />
      </Box>
      <Typography sx={{ ml: 1, whiteSpace: 'nowrap' }}>
        {props.reviewScore} {t` avis`}
      </Typography>
    </Box>
  );
};

export default ReviewProgress;
