// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const userPrivateApi = createApi({
  reducerPath: 'userPrivateApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API,
    prepareHeaders: () => {
      return baseHeader(true);
    }
  }),
  tagTypes: ['user-pref', 'user-profile'],
  endpoints: (build) => ({
    getUserPreferences: build.query({
      query: () => `/api/users/preferences`,
      providesTags: ['user-pref']
    }),
    getUsersByPermission: build.query({
      query: ({ organizationId, permissionKey }) => `/api/users/listByPermission/${organizationId}/${permissionKey}`
    }),
    getUserProfileAdmin: build.query({
      query: ({ userId }) => `/api/users/profile?userId=${userId}`
    }),
    getUserProfile: build.query({
      query: () => `/api/users/self`,
      providesTags: ['user-profile']
    }),
    updateUserPreferences: build.mutation({
      query: (body: {
        subscribedToEmailAlerts: boolean;
        subscribedToWeeklyEmailReports: boolean;
        languageCode: string;
      }) => ({
        url: `/api/users/preferences`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['user-pref']
    }),
    updateProfilePicture: build.mutation({
      query: (formData) => ({
        url: `/api/users/picture`,
        method: 'PUT',
        body: formData,
        formData: true
      }),
      invalidatesTags: ['user-profile']
    }),
    updateUserLanguageCode: build.mutation({
      query: (body: { languageCode: string }) => ({
        url: `/api/users/language`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['user-pref']
    }),
    updateUserProfile: build.mutation({
      query: (body: {
        firstname: string;
        lastname: string;
        email: string;
        functionName: string;
        phoneNumber: string;
      }) => ({
        url: `/api/users`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['user-profile']
    }),
    selectCurrentOrganization: build.mutation({
      query: (body: { organizationId: number }) => ({
        url: `/api/organizations/select`,
        method: 'POST',
        body
      })
    }),
    addUserProfile: build.mutation({
      query: (body: {
        email: string;
        firstname: string;
        lastname: string;
        subscribedToEmailAlerts?: boolean;
        subscribedToWeeklyEmailReports?: boolean;
        code: string;
      }) => ({
        url: `/api/users/add`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['user-pref', 'user-profile']
    })
  })
});

export const userPublicApi = createApi({
  reducerPath: 'userPublicApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API,
    prepareHeaders: () => {
      return baseHeader(false);
    }
  }),
  endpoints: (build) => ({
    postUserProfileExists: build.mutation({
      query: (body: { email: string }) => ({
        url: `/api/users/exists`,
        method: 'POST',
        body
      })
    })
  })
});

export const {
  useAddUserProfileMutation,
  useGetUserPreferencesQuery,
  useGetUserProfileAdminQuery,
  useGetUserProfileQuery,
  useGetUsersByPermissionQuery,
  useUpdateUserProfileMutation,
  useUpdateUserPreferencesMutation,
  useUpdateUserLanguageCodeMutation,
  useUpdateProfilePictureMutation,
  useSelectCurrentOrganizationMutation,
  useLazyGetUserPreferencesQuery,
  useLazyGetUserProfileAdminQuery,
  useLazyGetUserProfileQuery,
  useLazyGetUsersByPermissionQuery
} = userPrivateApi;

export const { usePostUserProfileExistsMutation } = userPublicApi;
