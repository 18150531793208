import { FC, useState } from 'react';

// Externals
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import toast from 'react-hot-toast';

// Services

// Stores
import { LoadingButton } from '@mui/lab';
import { t } from '@lingui/macro';

// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';
import ConnectorAuthValues from 'components/legacy/connectors/connectorAuthValues';

// Redux
import { useSelector } from 'components/legacy/store';
import useStorageValueUser from 'components/legacy/hooks/useStorageValueUser';
import { useRemoveConnectionMutation } from 'components/legacy/services/connectors';
import { useDeleteSelectTokenUserMutation } from 'components/legacy/services/organizations';
import { useGetSingleRoleQuery } from 'components/legacy/services/organization-roles';
import { useGetOrganizationMemberQuery } from 'components/legacy/services/organizationMember';
import { useGetUserProfileQuery } from 'components/legacy/services/userProfiles';

interface GoogleConnectProps {
  connectionStatusKey: string;
  accessTokenKey: string;
  tokenSelectUserKey: string;
}

const GoogleConnect: FC<GoogleConnectProps> = (props) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));
  const { data: userProfile, isLoading: loadingUserProfile } = useGetUserProfileQuery({});
  const { data: role, isLoading: loadingRoleAndPermissions } = useGetOrganizationMemberQuery({
    organizationId: userProfile?.data?.userProfile?.currentOrganizationId,
    userId: userProfile?.data?.userProfile?.id
  });
  const { data: roleDetail, isLoading: loadingRoleDetail } = useGetSingleRoleQuery({
    roleId: role?.data?.member?.roleId
  });

  // Organization Related values
  const [connectionStatus] = useStorageValue<string>(props.connectionStatusKey, organizationId);
  const [tokenSelectUser] = useStorageValue<string>(props.tokenSelectUserKey, organizationId);

  // User Related values
  const [getAcessToken] = useStorageValueUser<any>(props.accessTokenKey, 0);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const connectionExpired = connectionStatus.value === ConnectorAuthValues.expired;

  const accessToken = getAcessToken.value ? getAcessToken.value.token : null;

  const [removeConnection, { isLoading: loadingConnect }] = useRemoveConnectionMutation();
  const [deleteSelectTokenUser, { isLoading: loadingDisconnect }] = useDeleteSelectTokenUserMutation();

  const handleLogin = () => {
    const connectUrl = getConnectUrl();

    window.open(
      connectUrl,
      'Google',
      `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=700,left=100,top=100`
    );
  };

  const handleLogout = async () => {
    if (tokenSelectUser.value) await deleteSelectTokenUser({ namespace: 'google', organizationId: organizationId });
    await removeConnection({
      connectorAuthName: 'google'
    });
    closeDeleteModal();
    toast.success(t`Votre compte Google a été déconnecté.`);
  };

  const handleDelete = () => {
    openDeleteModal();
  };

  const openDeleteModal = () => setDeleteModalOpen(true);
  const closeDeleteModal = () => setDeleteModalOpen(false);

  const getConnectUrl = () => {
    const url = new URL('https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount');
    url.searchParams.append('redirect_uri', `${window.location.origin}/connectors/google-search/auth`);
    url.searchParams.append('prompt', 'consent');
    url.searchParams.append('response_type', 'code');
    url.searchParams.append('client_id', `${process.env.NEXT_PUBLIC_GOOGLE_SEARCH_CLIENT_ID}`);
    url.searchParams.append(
      'scope',
      'https://www.googleapis.com/auth/webmasters https://www.googleapis.com/auth/webmasters.readonly https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/adwords'
    );
    url.searchParams.append('access_type', 'offline');

    return url.toString();
  };

  return (
    <>
      {loadingConnect || loadingDisconnect ? (
        <CircularProgress />
      ) : (
        <>
          <LoadingButton
            disabled={
              loadingConnect ||
              loadingDisconnect ||
              !roleDetail?.data?.role?.permissions.find((r) => r.key === 'answer')
                ? true
                : false
            }
            loading={loadingConnect || loadingDisconnect}
            onClick={() => (!connectionExpired && accessToken ? handleDelete() : handleLogin())}
            startIcon={
              <Box
                component="img"
                src="/static/icons/brand/google_color.svg"
                sx={{
                  width: '24px'
                }}
              />
            }
            sx={{
              borderColor: (theme) => theme.palette.text.primary,
              border: 1.5,
              color: (theme) => theme.palette.text.primary,
              borderRadius: '18px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              py: 0.5,
              px: 1.5,
              whiteSpace: 'nowrap',
              maxWidth: 'max-content',
              height: '44px'
            }}
          >
            {!connectionExpired && accessToken ? t`Se déconnecter` : t`Se connecter à Google`}
          </LoadingButton>
        </>
      )}

      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={closeDeleteModal}
        open={deleteModalOpen}
      >
        <DialogTitle id="alert-dialog-title">{t`Avertissement`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t`Cette action supprimera vos données de compte lié à Google.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            autoFocus={true}
            color="primary"
            loading={loadingDisconnect}
            onClick={() => {
              handleLogout();
            }}
            variant="contained"
          >
            {t`Confirmer`}
          </LoadingButton>
          <Button color="primary" onClick={closeDeleteModal} type="reset" variant="outlined">{t`Annuler`}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GoogleConnect;
