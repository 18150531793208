// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const answersApi = createApi({
  reducerPath: 'answersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API,
    prepareHeaders: () => {
      return baseHeader(true);
    }
  }),
  endpoints: (build) => ({
    updateSingleAnswer: build.mutation({
      query: (body: { id: number; choiceKey: string }) => ({
        url: `/api/answers/single`,
        method: 'PUT',
        body
      })
    }),
    updateMultipleAnswer: build.mutation({
      query: (body: { choiceKey: string; choiceKeys: string[]; questionKey: string; organizationId: number }) => ({
        url: `/api/answers/multiple`,
        method: 'POST',
        body
      })
    }),
    updateFreeAnswer: build.mutation({
      query: (body: { id: number; input: string }) => ({
        url: `/api/answers/free`,
        method: 'PUT',
        body
      })
    }),
    updateKpiAnswer: build.mutation({
      query: (body: { id: number; input: string }) => ({
        url: `/api/answers/kpi`,
        method: 'PUT',
        body
      })
    }),
    addSingleAnswer: build.mutation({
      query: (body: { choiceKey: string; organizationId: number; questionKey: string }) => ({
        url: `/api/answers/add-single`,
        method: 'POST',
        body
      })
    }),
    addFreeAnswer: build.mutation({
      query: (body: { input: string; organizationId: number; questionKey: string }) => ({
        url: `/api/answers/add-free`,
        method: 'POST',
        body
      })
    }),
    addKpiAnswer: build.mutation({
      query: (body: { input: string; organizationId: number; questionKey: string }) => ({
        url: `/api/answers/add-kpi`,
        method: 'POST',
        body
      })
    })
  })
});

export const {
  useAddKpiAnswerMutation,
  useAddFreeAnswerMutation,
  useAddSingleAnswerMutation,
  useUpdateFreeAnswerMutation,
  useUpdateKpiAnswerMutation,
  useUpdateSingleAnswerMutation,
  useUpdateMultipleAnswerMutation
} = answersApi;
