// Externals
import { t, Trans } from '@lingui/macro';
import { Typography } from '@mui/material';

// Components
import { ICustomBlock, UserComponentProps } from '..';
import { Header } from 'components/legacy/pages/audit/questions/header';
import { useEffect, useState } from 'react';
import { useGetActivityCenterQuery } from 'components/legacy/services/connectors';
import QuoteDisplay from '../../quote-display';

interface ComponentProps {
  name: string;
  description: string;
  tips: string;
}
const UserComponent = ({ name, description, tips }: ComponentProps) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));
  const [information, setInformation] = useState<string>();
  const { data: response, isLoading: isLoading } = useGetActivityCenterQuery({});

  useEffect(() => {
    fetchUsage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const fetchUsage = async () => {
    setInformation(response?.data);
  };

  return (
    <>
      <Header isScoreVisible={false} label={description} organizationId={organizationId} title={name} tooltip={tips} />
      <Typography sx={{ pt: 2, pb: 2 }} variant="body2">
        <Trans>
          D'après une analyse par intelligence artificielle, sur base de votre page web, votre secteur d'activité semble
          être le suivant :
        </Trans>
        <QuoteDisplay text={information} isLoading={isLoading} />
      </Typography>
    </>
  );
};

class ActivityCenter implements ICustomBlock {
  tips = () => t``;
  description = () => t``;
  name = () => t`Secteur d'activité`;
  key = () => 'ai-activity-center';
  UserComponent: React.FC<UserComponentProps> = (props) => (
    <UserComponent description={this.description()} name={this.name()} tips={this.tips()} />
  );
}

export default ActivityCenter;
