import { Box, Fab, Grid, Paper } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router';

const ReportPartContainer = ({ children, route, displayLink = true }) => {
  const navigate = useNavigate();

  return (
    <Grid item={true} md={4} sm={4} xs={3} sx={{ breakInside: 'avoid' }}>
      <Paper
        elevation={3}
        sx={{
          pb: 5,
          position: 'relative',
          height: '100%',
          background: '#FBF9FF',
          borderRadius: '20px',
          breakInside: 'avoid'
        }}
      >
        {children}
        {displayLink && (
          <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
            <Fab color="inherit" size="small" sx={{ boxShadow: 'none', mb: 1, mr: 1 }}>
              <Visibility
                onClick={() => navigate(route)}
                sx={{ color: (theme) => theme.palette.primary.main, cursor: 'pointer', height: 24, width: 24 }}
              />
            </Fab>
          </Box>
        )}
      </Paper>
    </Grid>
  );
};

export default ReportPartContainer;
