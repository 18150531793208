import type { FC } from 'react';

// Externals
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Trans } from '@lingui/macro';

interface Props {
  title: string;
  open: boolean;
  message: string | JSX.Element;
  onAccept: () => void;
  onClose?: () => void;
  onCancel?: () => void;
}

const MessageConfirm: FC<Props> = ({ title, message, open, onClose, onAccept, onCancel }) => {
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleAccept = () => {
    if (onAccept) {
      onAccept();
    }
    handleClose();
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
    handleClose();
  };
  const handleDialogClick = (e) => {
    e.stopPropagation();
  };

  return (
    <Dialog onClick={handleDialogClick} open={open}>
      <DialogTitle
        sx={{
          color: (theme) => theme.palette.text.primary
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{
            color: (theme) => theme.palette.text.primary
          }}
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleCancel}>
          <Trans>Annuler</Trans>
        </Button>
        <Button autoFocus={true} color="primary" onClick={handleAccept}>
          <Trans>Accepter</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MessageConfirm;
