import React, { FC } from 'react';

// Externals
import _ from 'lodash';

// Hooks
import useStorageValueCollection from 'components/legacy/hooks/useStorageValueCollection';
import useOrganizationValue from 'components/legacy/hooks/useOrganizationValueCollection';

var Mustache = require('mustache');
interface DynamicTextProps {
  text: string;
  organizationId: number;
}

const DynamicText: FC<DynamicTextProps> = ({ text, organizationId }: DynamicTextProps) => {
  const keys = React.useMemo(() => {
    const regex = /{{.*?}}/g;
    var regexArray = text.match(regex);
    if (regexArray && regexArray.length) {
      const trimRegex = /\{|\}|\s/g;
      return regexArray.map((e) => e.replace(trimRegex, ''));
    }
    const regexOrganization = /(\[\[.+?\]\])/g;
    var regexArrayOrganization = text.match(regexOrganization);
    if (regexArrayOrganization && regexArrayOrganization.length) {
      const trimRegexOrganization = /\[|\]|\s/g;
      return regexArrayOrganization.map((e) => e.replace(trimRegexOrganization, ''));
    }
    return [];
  }, [text]);

  const [mapping] = useStorageValueCollection<{ [key: string]: any }>(keys, organizationId);
  const [mappingOrganization] = useOrganizationValue<{ [key: string]: any }>(keys, organizationId);

  const data = _.reduce(
    keys,
    (values, key) => {
      // dont remove null control, JS treat zero value as null

      // if value is number display number with local format.
      var mappingKey = Number.isFinite(mapping[key]?.value)
        ? new Intl.NumberFormat().format(mapping[key]?.value) ?? 0
        : mapping[key]?.value;
      var mappingOrganizationKey = Number.isFinite(mappingOrganization[key]?.value)
        ? new Intl.NumberFormat().format(mappingOrganization[key]?.value) ?? 0
        : mappingOrganization[key]?.value;

      if (mappingKey != null) return { ...values, [key]: mappingKey };
      if (mappingOrganizationKey) return { ...values, [key]: mappingOrganizationKey };

      return { ...values, [key]: '' };
    },
    {}
  );
  return <>{Mustache.render(text, data)}</>;
};

export default React.memo(DynamicText);
