import React, { useEffect, useState } from 'react';

// Externals
import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material';
import { CircularProgress, Grid } from '@mui/material';

import { t } from '@lingui/macro';
import ReactECharts from 'echarts-for-react';

// Components
import { Header } from 'components/legacy/pages/audit/questions/header';
import { ICustomBlock, UserComponentProps } from '../..';
import { ComponentProps } from '../../google/connect/models';
import * as StorageDataKeys from '../../google/constants';

// Services
import useStorageValueCollection, { Query } from 'components/legacy/hooks/useStorageValueCollection';
import { StorageValue } from 'components/legacy/hooks/useStorageValue';

interface IStorageValueCollection extends Query {
  [StorageDataKeys.CHART_LANDINGPAGE_GA]: StorageValue<any>;
  [StorageDataKeys.CHART_EXITPAGE_GA]: StorageValue<any>;
}

interface ICustomBlockForGA extends ICustomBlock {
  descriptionGA4: () => string;
  nameGA4: () => string;
  tooltipGA4: () => string;
}
interface ComponentPropsCustom extends ComponentProps {
  descriptionGA4: string;
  nameGA4: string;
  tooltipGA4: string;
}
const UserComponent = ({
  name,
  description,
  descriptionGA4,
  nameGA4,
  tooltipGA4,
  isScoreVisible,
  tooltip
}: ComponentPropsCustom) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [categoriesLanding, setCategoriesLanding] = useState<any>([]);
  const [dataLanding, setDataLanding] = useState<any>([]);

  const [mapping] = useStorageValueCollection<IStorageValueCollection>(
    [StorageDataKeys.PROPERTY_ID_GA, StorageDataKeys.CHART_LANDINGPAGE_GA, StorageDataKeys.CHART_EXITPAGE_GA],
    organizationId
  );
  const loading =
    mapping[StorageDataKeys.PROPERTY_ID_GA].loading ||
    mapping[StorageDataKeys.CHART_LANDINGPAGE_GA].loading ||
    mapping[StorageDataKeys.CHART_EXITPAGE_GA].loading;
  const chartDataLandingPageGA = mapping[StorageDataKeys.CHART_LANDINGPAGE_GA].value;
  const selectedGA = mapping[StorageDataKeys.PROPERTY_ID_GA];

  useEffect(() => {
    if (selectedGA.value && chartDataLandingPageGA) {
      const tmpCategories = [];
      const tmpData = [];

      chartDataLandingPageGA?.slice(1).map((element) => {
        tmpCategories.push(element[0]);
        tmpData.push(element[1]);
      });

      setCategoriesLanding(tmpCategories.reverse());
      setDataLanding(tmpData.reverse());
    }
  }, [chartDataLandingPageGA, selectedGA]);

  const option = {
    color: ['rgb(77, 119, 255)'],
    smooth: true,
    yAxis: {
      type: 'category',
      data: categoriesLanding,
      left: 'center'
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    grid: { containLabel: isMobile ? false : true },
    tooltip: {
      trigger: 'axis'
    },
    xAxis: {
      type: 'value'
    },
    series: [
      {
        data: dataLanding,
        type: 'bar',
        stack: 'total'
      }
    ]
  };

  if (loading) {
    return (
      <>
        <Header
          isScoreVisible={isScoreVisible}
          label={description}
          organizationId={organizationId}
          title={name}
          tooltip={tooltip}
        />
        <CircularProgress />
      </>
    );
  }
  return (
    <>
      <Header
        isScoreVisible={isScoreVisible}
        label={selectedGA.value !== null ? descriptionGA4 : description}
        organizationId={organizationId}
        title={selectedGA.value !== null ? nameGA4 : name}
        tooltip={selectedGA.value !== null ? tooltipGA4 : tooltip}
      />
      <Grid container={true} spacing={2}>
        <Grid item={true} md={9} sm={9} xs={11}>
          <ReactECharts option={option} />
        </Grid>
      </Grid>
    </>
  );
};

export const optionsLandingPage = (isMobile) => ({
  title: t`Landing Page`,
  chartArea: { width: isMobile ? '35%' : null },
  align: 'start',
  hAxis: {
    title: t`Total Landing Page`,
    minValue: 0
  },
  legend: { position: 'none' }
});

class GoogleGA4ChartLandingExitPage implements ICustomBlockForGA {
  name = () => t`Pages d'arrivée et de sortie`;
  nameGA4 = () => t`Pages d'arrivée`;
  description = () => t`Principales pages de landing et d'exit ces 30 derniers jours`;
  descriptionGA4 = () => t`Principales pages de landing ces 30 derniers jours`;
  tooltip = () =>
    t`Une landing page est la 1ère page sur laquelle arrive un visiteur. L'exit page est la dernière page qu'il visite avant de quitter votre site. Les landings pages peuvent être différentes de la page d'accueil si le visiteur provient d'un autre site ou a fait une recherche spécifique. Surveillez les exit pages pour vous assurer que les visiteurs partent en fin de parcours et pas suite à une page problématique.`;
  tooltipGA4 = () =>
    t`Une landing page est la 1ère page sur laquelle arrive un visiteur. Les landings pages peuvent être différentes de la page d'accueil si le visiteur provient d'un autre site ou a fait une recherche spécifique.`;
  isScoreVisible = () => false;
  key = () => 'google-ga-chart-chartlandingexitpage';
  UserComponent: React.FC<UserComponentProps> = (props) => (
    <UserComponent
      connectorKey={this.key()}
      description={this.description()}
      descriptionGA4={this.descriptionGA4()}
      isScoreVisible={this.isScoreVisible()}
      name={this.name()}
      nameGA4={this.nameGA4()}
      questionKey={props.questionKey}
      tooltip={this.tooltip()}
      tooltipGA4={this.tooltipGA4()}
    />
  );
}

export default GoogleGA4ChartLandingExitPage;

function AdaptDataTableFormat(chartDataCollection) {
  const table = [];
  const headers = [];
  const limit = 10;
  chartDataCollection[0].forEach((element, index) => {
    headers.push(GetHeaderName(element));
  });
  table.push(headers);

  if (chartDataCollection.rows?.length === 0) {
    table.push('', 0);
  }

  chartDataCollection.forEach((element, index) => {
    if (index > limit) return;
    if (index === 0) return; //skip header row
    const data = [];
    element.forEach((element, index) => {
      if (index === 0) {
        //Country Name column
        data.push(element);
      } else {
        //value column
        data.push(parseInt(element));
      }
    });

    table.push(data);
  });
  return table;
}
function GetHeaderName(value: string) {
  switch (value) {
    case 'activeUsers':
    case 'ga:sessions':
      return 'Utilisateurs';
    default:
      return value;
  }
}
