// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const blogPublicApi = createApi({
  reducerPath: 'blogPublicApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API,
    prepareHeaders: () => {
      return baseHeader(false);
    }
  }),
  tagTypes: ['articles'],
  endpoints: (build) => ({
    getArticles: build.query({
      query: ({ languageCode }) => `/api/blogs/getall?lg=${languageCode}`,
      providesTags: ['articles'],
      transformResponse: (response: any) => {
        if (response.data.blog) {
          // Triez les articles par date de manière descendante (du plus récent au plus ancien)
          response.data.blog.sort(
            (a, b) => new Date(b.publicationDate).getTime() - new Date(a.publicationDate).getTime()
          );
        }
        return response;
      }
    }),
    getAvailableLanguageBySlug: build.query({
      query: ({ slug }) => `/api/blogs/language/${slug}`
    })
  })
});

export const blogPrivateApi = createApi({
  reducerPath: 'blogPrivateApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API,
    prepareHeaders: () => {
      return baseHeader(true);
    }
  }),
  tagTypes: ['admin-articles'],
  endpoints: (build) => ({
    getArticleBySlug: build.query({
      query: ({ slug }) => `/api/blogs/slug/${slug}`
    }),
    getAdminArticle: build.query({
      query: ({ articleId, languageCode }) => `/api/blogs/admin/${articleId}?lg=${languageCode}`,
      providesTags: ['admin-articles']
    }),
    uploadArticleImage: build.mutation({
      query: (formData) => ({
        url: `/api/blogs/image`,
        method: 'POST',
        body: formData,
        formData: true
      })
    }),
    updateBlogValidationTranslation: build.mutation({
      query: (body: { blogId: number; languageCode: string; isValidate: boolean }) => ({
        url: `/api/blogs/translations/validation`,
        method: 'PUT',
        body
      })
    }),
    updateArticlesIndex: build.mutation({
      query: (body: { indexes: { id: number; index: number }[] }) => ({
        url: `/api/audits/indexes`,
        method: 'PUT',
        body
      })
    }),
    syncBlogTranslations: build.mutation({
      query: (body: {
        translations: {
          title: string;
          shortDescription: string;
          description: string;
          metaTitle: string;
          metaDescription: string;
          languageCode: string;
          blogId: number;
          isValidate: boolean;
          altImage: string;
        }[];
      }) => ({
        url: `/api/blogs/translations`,
        method: 'PUT',
        body
      })
    }),
    editArticle: build.mutation({
      query: (body: {
        id: number;
        imageUrl: string;
        slug: string;
        //status : { Draft: 0; Public: 1; Archive: 2 }
        status: number;
        publicationDate: Date;
      }) => ({
        url: `/api/blogs/edit`,
        method: 'PUT',
        body
      })
    }),
    deleteArticle: build.mutation({
      query: ({ id }) => ({
        url: `/api/blogs/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['admin-articles']
    }),
    addArticle: build.mutation({
      query: (body: { imageUrl: string; slug: string; status: number; publishDate: Date }) => ({
        url: `/api/blogs/add`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['admin-articles']
    })
  })
});

export const { useGetArticlesQuery, useGetAvailableLanguageBySlugQuery } = blogPublicApi;

export const {
  useAddArticleMutation,
  useDeleteArticleMutation,
  useEditArticleMutation,
  useGetAdminArticleQuery,
  useGetArticleBySlugQuery,
  useUploadArticleImageMutation,
  useUpdateBlogValidationTranslationMutation,
  useUpdateArticlesIndexMutation,
  useSyncBlogTranslationsMutation,
  useLazyGetArticleBySlugQuery
} = blogPrivateApi;
