import { FC, useState } from 'react';

// Externals
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import toast from 'react-hot-toast';

// Services

// Stores
import { LoadingButton } from '@mui/lab';
import { t } from '@lingui/macro';

// Redux
import { useConnectYoutubeMutation, useRemoveConnectionMutation } from 'components/legacy/services/connectors';
import { useGetSingleRoleQuery } from 'components/legacy/services/organization-roles';
import { useGetOrganizationMemberQuery } from 'components/legacy/services/organizationMember';
import { useGetUserProfileQuery } from 'components/legacy/services/userProfiles';
import ConnectorAuthValues from 'components/legacy/connectors/connectorAuthValues';
import useStorageValue from 'components/legacy/hooks/useStorageValue';

interface YoutubeConnectProps {
  connectionStatusKey: string;
  url: string;
  isUrlValid: boolean;
}

const YoutubeConnect: FC<YoutubeConnectProps> = (props) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [connectYoutube, { data: dataConnect, isLoading: loadingConnect }] = useConnectYoutubeMutation();
  const [removeConnection, { isLoading: loadingDisconnect }] = useRemoveConnectionMutation();
  const [connectionStatus] = useStorageValue<string>(props.connectionStatusKey, organizationId);

  const connectionSucceeded = connectionStatus.value === ConnectorAuthValues.succeeded;

  const { data: userProfile, isLoading: loadingUserProfile } = useGetUserProfileQuery({});
  const { data: role, isLoading: loadingRoleAndPermissions } = useGetOrganizationMemberQuery({
    organizationId: userProfile?.data?.userProfile?.currentOrganizationId,
    userId: userProfile?.data?.userProfile?.id
  });
  const { data: roleDetail, isLoading: loadingRoleDetail } = useGetSingleRoleQuery({
    roleId: role?.data?.member?.roleId
  });

  const handleLogin = async () => {
    await connectYoutube({
      url: props.url
    });
  };

  const handleLogout = async () => {
    await removeConnection({
      connectorAuthName: 'youtube'
    });

    closeDeleteModal();
    toast.success(t`Votre compte Youtube a été déconnecté.`);
  };

  const handleDelete = () => {
    openDeleteModal();
  };

  const openDeleteModal = () => setDeleteModalOpen(true);
  const closeDeleteModal = () => setDeleteModalOpen(false);

  return (
    <>
      <LoadingButton
        disabled={
          !props.isUrlValid ||
          loadingConnect ||
          loadingDisconnect ||
          !roleDetail?.data?.role?.permissions.find((r) => r.key === 'answer')
            ? true
            : false
        }
        loading={loadingConnect || loadingDisconnect}
        onClick={() => (connectionSucceeded ? handleDelete() : handleLogin())}
        startIcon={
          <Box
            component="img"
            src="/static/icons/brand/youtube_color.png"
            sx={{
              height: '24px'
            }}
          />
        }
        sx={{
          borderColor: 'black',
          border: 1.5,
          color: 'black',
          borderRadius: '18px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          py: 0.5,
          px: 1.5,
          whiteSpace: 'nowrap',
          maxWidth: 'max-content',
          height: '44px'
        }}
      >
        {connectionSucceeded ? t`Dissocier son compte` : t`Associer son compte`}
      </LoadingButton>

      {connectionSucceeded && (
        <Dialog
          aria-describedby="alert-dialog-description"
          aria-labelledby="alert-dialog-title"
          onClose={closeDeleteModal}
          open={deleteModalOpen}
        >
          <DialogTitle id="alert-dialog-title">{t`Avertissement`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t`Cette action supprimera vos données de compte lié à Youtube.`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              autoFocus={true}
              color="primary"
              loading={loadingDisconnect}
              onClick={() => {
                handleLogout();
              }}
              variant="contained"
            >
              {t`Confirmer`}
            </LoadingButton>
            <Button color="primary" onClick={closeDeleteModal} type="reset" variant="outlined">{t`Annuler`}</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default YoutubeConnect;
