// Internals
import { ICustomBlock, UserComponentProps } from '../../index';
import { Header } from 'components/legacy/pages/audit/questions/header';

// Externals
import _ from 'lodash';
import { t } from '@lingui/macro';
import { CircularProgress } from '@mui/material';

// Models
import { QuestionScoreDto } from 'components/legacy/models/questionScore.dto';

// Redux
import { useSelector } from 'components/legacy/store';

// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';
import { useGetQuestionScoresQuery } from 'components/legacy/services/questions';
import { useParams } from 'react-router';

interface ComponentProps {
  name: string;
  description: string;
  connectorKey: string;
  questionKey: string;
}

const FOLLOWERS_COUNT = 'linkedin:followers-count';

const UserComponent = ({ name, description, connectorKey, questionKey }: ComponentProps) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));

  const { auditKey } = useParams();

  const { data: scoresQuery, isLoading: loadingScores } = useGetQuestionScoresQuery({
    organizationId,
    auditKey
  });

  const scores: QuestionScoreDto[] = useSelector<QuestionScoreDto[]>((state) =>
    scoresQuery?.data?.questions?.filter((s) => s.key === questionKey)
  );
  const score = _.sumBy(scores, (s) => s.score);

  const [linkedInData] = useStorageValue<number>(FOLLOWERS_COUNT, organizationId);

  if (linkedInData.loading) {
    return (
      <>
        <Header isScoreVisible={true} label={description} organizationId={organizationId} title={name} />
        <CircularProgress />
      </>
    );
  }

  const followerCount = linkedInData.value || 0;

  return (
    <>
      <Header
        isScoreVisible={true}
        label={t`Vous avez ` + ' ' + new Intl.NumberFormat().format(followerCount) + ' ' + t` followers sur LinkedIn.`}
        organizationId={organizationId}
        score={score}
        title={name}
      />
    </>
  );
};

class LinkedInFollowers implements ICustomBlock {
  name = () => t`Abonnés LinkedIn`;
  description = () => t`FreewayTeam analyse votre nombre d'abonnés sur LinkedIn.`;
  key = () => 'linkedin-followers';
  UserComponent: React.FC<UserComponentProps> = (props) => (
    <UserComponent
      connectorKey={this.key()}
      description={this.description()}
      name={this.name()}
      questionKey={props.questionKey}
    />
  );
}

export default LinkedInFollowers;
