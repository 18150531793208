import { useEffect } from 'react';

// Externals
import { alpha, Box, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { t } from '@lingui/macro';

// Services
import NetworkIcon, { NetworkKey } from 'components/legacy/components/network';
import { useGetKeyFigureQuery } from 'components/legacy/services/socialData';

interface BoxAnalyticsProps {
  type: NetworkKey;
  setHaveDataToDisplay: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * @version 1.0.0
 * @author [Prune Blanc]
 * @param props.title Title of content
 * @param props.logo logo depending on the social network
 * @param props.absoluteNbrOfTheDayViews Number of views on facebook on the day for exemple
 * @param props.differenceNbrViews Difference number between yesterday and today
 * @returns Block of content for analytics views.
 */
function BoxAnalytics({ type, setHaveDataToDisplay }: BoxAnalyticsProps) {
  const { data } = useGetKeyFigureQuery({ socialNetwork: type });

  useEffect(() => {
    if (
      data?.data?.keyFigures &&
      (data?.data?.keyFigures.lastThirtyDailyViews || data?.data?.keyFigures.lastThirtyDailyViewsDiff)
    ) {
      setHaveDataToDisplay(true);
    }
  }, [data, setHaveDataToDisplay]);

  if (data?.data?.keyFigures === null) {
    return null;
  }

  const absoluteNbrOfTheDayViews = data?.data?.keyFigures?.lastThirtyDailyViews;
  const differenceNbrViews = data?.data?.keyFigures?.lastThirtyDailyViewsDiff;

  return (
    <Grid item={true} sx={{ width: '100%' }}>
      <Paper
        elevation={3}
        sx={{
          position: 'relative',
          borderRadius: '20px',
          breakInside: 'avoid',
          mb: (theme) => theme.spacing(2)
        }}
      >
        <Box
          sx={{
            p: 2,
            background: (theme) => alpha(theme.palette.primary.light, 0.05),
            borderRadius: '20px',
            height: 'max-content'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row'
            }}
          >
            <NetworkIcon networkName={type} displayTitle />

            <Box
              sx={{
                display: 'flex',

                justifyContent: 'center',
                flexDirection: 'row'
              }}
            >
              <Tooltip title={t`30 derniers jours`}>
                <Typography sx={{ pl: 1, fontSize: 36, fontWeight: 700 }}>
                  {new Intl.NumberFormat().format(absoluteNbrOfTheDayViews)}
                </Typography>
              </Tooltip>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Tooltip title={t`Progression sur une période de 30 jours`}>
                  <Typography
                    sx={{
                      pl: 1,
                      fontSize: 18,
                      fontWeight: 700,
                      color: (theme) =>
                        differenceNbrViews === null
                          ? theme.palette.text.primary
                          : differenceNbrViews > 0
                          ? theme.palette.success.main
                          : differenceNbrViews === 0
                          ? theme.palette.text.primary
                          : theme.palette.error.main
                    }}
                  >
                    {differenceNbrViews === null
                      ? '--'
                      : differenceNbrViews > 0
                      ? '+' + differenceNbrViews
                      : differenceNbrViews === 0
                      ? '--'
                      : differenceNbrViews}
                  </Typography>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
}

export default BoxAnalytics;
