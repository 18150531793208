import React from 'react';

// Externals
import { t } from '@lingui/macro';

// Internals
import { ICustomBlock, UserComponentProps } from '..';
import { Header } from 'components/legacy/pages/audit/questions/header';
import PublicationTable from './publication-table';

// Redux
import { useSelector } from 'components/legacy/store';

interface ComponentProps {
  name: string;
  description: string;
  tips: string;
}

const UserComponent = ({ name, description, tips }: ComponentProps) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));

  return (
    <>
      <Header isScoreVisible={false} label={description} organizationId={organizationId} title={name} tooltip={tips} />

      <PublicationTable />
    </>
  );
};

class Publications implements ICustomBlock {
  tips = () =>
    t`Les informations principales sur les performances de vos posts Facebook, Instagram et LinkedIn sont mises à jour une fois par jour. Cliquez sur l'entête d'une colonne pour les classer selon un autre critère. Les vues sont le nombre d'impressions de votre publication, les réactions sont les likes et autres réactions (love, funny, ...) et comments donne le nombre de commentaires sur la publication.`;
  description = () => ``;
  name = () => t`Publications`;
  key = () => 'publications';
  UserComponent: React.FC<UserComponentProps> = () => (
    <UserComponent description={this.description()} name={this.name()} tips={this.tips()} />
  );
}

export default Publications;
