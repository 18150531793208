export type ConnectorKeys =
  | 'facebook'
  | 'instagram'
  | 'linkedin'
  | 'youtube'
  | 'hibp'
  | 'google'
  | 'googleGa4'
  | 'googleUA'
  | 'googleSearch'
  | 'seo'
  | 'googleAds'
  | 'webTxtAnalyser';

export type EntityConnectorKeys = 'facebook' | 'instagram' | 'linkedin';

interface ConnectorAuth {
  connectionStatus: string;
  auditStatus: string;
}
interface EntityConnectorAuth extends ConnectorAuth {
  connectedEntities: string;
  selectedEntity: string;
  connectedAdEntities: string;
  selectedAdEntity: string;
}

export interface IConnectedEntity {
  name: string;
  id: string;
  picture: string;
}

type EntityConnectorAuthConfig = {
  [key in EntityConnectorKeys]: EntityConnectorAuth;
};

export const connectorStorageProperties = {
  connectedEntities: 'connectedEntities',
  selectedEntity: 'selectedEntity',
  connectedAdEntities: 'connectedAdEntities',
  selectedAdEntity: 'selectedAdEntity'
};

const connectorStorageKeys = {
  facebook: {
    connectionStatus: 'facebook:connection-status',
    auditStatus: 'facebook:audit-status',
    selectedEntity: 'facebook:selected-entity',
    connectedEntities: 'facebook:connected-entities',
    accessToken: 'facebook:access-token',
    selectedUserId: 'facebook:selected-userId',
    publication: 'facebook:publication',
    adAccount: 'facebook:selected-ad-entity',
    connectedAdEntities: 'facebook:connected-ad-entities',
    selectedAdEntity: 'facebook:selected-ad-entity',
    adsSummary7: 'facebook:content-statistic-ads-7',
    adsSummary30: 'facebook:content-statistic-ads-30',
    adsSummary90: 'facebook:content-statistic-ads-90',
    adsSummary365: 'facebook:content-statistic-ads-365',
    adsSummaryData: 'facebook:content-statistic-ads-data'
  },
  instagram: {
    connectionStatus: 'instagram:connection-status',
    auditStatus: 'instagram:audit-status',
    selectedEntity: 'instagram:selected-entity',
    connectedEntities: 'instagram:connected-entities',
    accessToken: 'instagram:access-token',
    selectedUserId: 'instagram:selected-userId',
    publication: 'instagram:publication',
    connectedAdEntities: 'instagram:connected-ad-entities',
    selectedAdEntity: 'instagram:selected-ad-entity'
  },
  linkedin: {
    connectionStatus: 'linkedin:connection-status',
    auditStatus: 'linkedin:audit-status',
    accountRecap: 'linkedin:linkedin-account-recap',
    organizationSelected: 'linkedin:linkedin-organization-selected',
    selectedEntity: 'linkedin:selected-entity',
    connectedEntities: 'linkedin:connected-entities',
    connectedAdEntities: 'linkedin:connected-ad-entities',
    selectedAdEntity: 'linkedin:selected-ad-entity',
    accessToken: 'linkedin:linkedin-token',
    selectedUserId: 'linkedin:selected-userId',
    publication: 'linkedin:publication',
    adAccount: 'linkedin:ad-account-info',
    adsSummary7: 'linkedin:content-statistic-ads-7',
    adsSummary30: 'linkedin:content-statistic-ads-30',
    adsSummary90: 'linkedin:content-statistic-ads-90',
    adsSummary365: 'linkedin:content-statistic-ads-365',
    adsSummaryData: 'linkedin:content-statistic-ads-data'
  },
  youtube: {
    connectionStatus: 'youtube:connection-status',
    auditStatus: 'youtube:audit-status',
    accountRecap: 'youtube:youtube-account-recap',
    channelId: 'youtube:channel-id',
    channelForUsername: 'youtube:channel-for-username',
    channelWatch: 'youtube:channel-watch',
    channelType: 'youtube:channel-type',
    subscriberCount: 'youtube:subscriber-count',
    videoCount: 'youtube:video-count',
    viewCount: 'youtube:view-count',
    followerCount: 'youtube:subscriber-count',
    profileImageUrl: 'youtube:profile-image-url',
    publication: 'youtube:publication'
  },
  hibp: {
    breachesData: 'haveibeenpwned:breaches-data'
  },
  google: {
    connectionStatus: 'google:connection-status',
    authToken: 'shared-storage:google_token',
    accessToken: 'google:google_token',
    selectedUserId: 'google:selected-userId'
  },
  googleGa4: {
    connectionStatus: 'google-ga4:connection-status',
    auditStatus: 'google-ga4:audit-status',
    propertyId: 'google-ga4:ga4_property_id',
    propertyName: 'google-ga4:ga4_property_name',
    listProperties: 'google-ga4:ga4_list_properties',
    report: {
      lastWeekDaySessions: 'google-ga4:last_week_days_report',
      lastThirtyDaySessions: 'google-ga4:last_thirty_days_report',
      lastYearDaySessions: 'google-ga4:last_year_days_report',
      sessions: 'google-ga4:chart_sessions',
      last30DaysTopPageViews: 'google-ga4:last_30_days_top_page_views',
      last7DaysTopPageViews: 'google-ga4:last_7_days_top_page_views'
    },
    chart: {
      sessionsCountry: 'google-ga4:chart_sessions_country',
      landingPage: 'google-ga4:chart_landing_page',
      exitPage: 'google-ga4:chart_exit_page',
      gender: 'google-ga4:chart_gender',
      age: 'google-ga4:chart_age',
      browsers: 'google-ga4:chart_browsers',
      devices: 'google-ga4:chart_devices',
      sources: 'google-ga4:chart_sources',
      mediums: 'google-ga4:chart_mediums'
    },
    selectedUserId: 'google-ga4:selected-userId'
  },
  googleUA: {
    propertyId: 'google-ua:ua_property_id',
    propertyName: 'google-ua:ua_property_name',
    listProperties: 'google-ua:ua_list_properties'
  },
  googleSearch: {
    connectionStatus: 'google-search:connection-status',
    auditStatus: 'google-search:audit-status',
    report: {
      lastYearDailyReport: 'last_365_days_daily_report',
      last7DaysReportProgress: 'google-search:last_7_days_report_progress',
      last30DaysReportProgress: 'google-search:last_30_days_report_progress',
      last90DaysReportProgress: 'google-search:last_90_days_report_progress',
      last365DaysReportProgress: 'google-search:last_365_days_report_progress',
      lastNinetyDays: 'google-search:last_ninety_days_report',
      lastYear: 'google-search:last_year_daily_report',
      lastThirtyDaysTotal: 'google-search:last_thirty_days_total_report',
      lastWeekTotal: 'google-search:last_week_total_report',
      previousWeekTotal: 'google-search:previous_week_total_report',
      topTenQueriesOnLastThirtyDays: 'google-search:top_ten_queries_on_last_thirty_days_report',
      TopTenQueriesOnLastWeek: 'google-search:top_ten_queries_on_last_week_report'
    },
    domainUrl: 'google-search:domain_url',
    domainUrls: 'google-search:domain_urls',
    selectedUserId: 'google-search:selected-userId'
  },
  seo: {
    auditStatus: 'seo:audit-status',
    lastAuditReceivedAt: 'seo:last-audit-received-at',
    mozda: 'seo:mozda',
    domainStrength: 'seo:domain-strength',
    pageStrength: 'seo:page-strength'
  },
  googleAds: {
    adAccount: 'google-ads:ad-account',
    connectionStatus: 'google-ads:connection-status',
    auditStatus: 'google-ads:audit-status',
    selectedEntity: 'google-ads:selected-entity',
    selectedEntityName: 'google-ads:selected-entity-name',
    listProperties: 'google-ads:list_properties',
    connectedEntities: 'google-ads:connected-entities',
    accessToken: 'google-ads:access-token',
    selectedUserId: 'google-ads:selected-userId',
    publication: 'google-ads:publication',
    adsSummary7: 'google-ads:content-statistic-ads-7',
    adsSummary30: 'google-ads:content-statistic-ads-30',
    adsSummary90: 'google-ads:content-statistic-ads-90',
    adsSummary365: 'google-ads:content-statistic-ads-365',
    adsSummaryData: 'google-ads:content-statistic-ads-data'
  },
  emailLeakDetector: {
    settings: 'email-leak-detector:settings',
    profiles: 'email-leak-detector:profiles'
  },
  webTxtAnalyser: {
    reportData: 'web-txt-analyser:report-data'
  },
  cveCvss: {
    domainTech: 'domainTech:cve'
  }
};

export const entityConnectorAuthConfig = connectorStorageKeys as unknown as EntityConnectorAuthConfig;
export default connectorStorageKeys;
