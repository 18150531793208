// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const connectorsApi = createApi({
  reducerPath: 'connectorsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API,
    prepareHeaders: () => {
      return baseHeader(true);
    }
  }),
  tagTypes: ['organization-id'],
  endpoints: (build) => ({
    getWPScanStatusAdmin: build.query({
      query: () => `/api/connectors/wpscan/status`
    }),
    getAllDetailsUptimeRobot: build.query({
      query: (body: { organizationId: number }) => ({
        url: `/api/connectors/uptimerobot/GetDetails`,
        method: 'POST',
        body
      })
    }),

    getDetailsUptimeRobot: build.mutation({
      query: (body: { organizationId: number }) => ({
        url: `/api/connectors/uptimerobot/GetDetails`,
        method: 'POST',
        body
      })
    }),
    getUptimeRobotMonitorsAdmin: build.mutation({
      query: () => ({
        url: `/api/connectors/uptimerobot/GetAllMonitorsAdmin`,
        method: 'POST'
      })
    }),
    getUptimeRobotAccountDetails: build.mutation({
      query: () => ({
        url: `/api/connectors/uptimerobot/GetAccountDetails`,
        method: 'POST'
      })
    }),
    deleteUptimeRobotMonitors: build.mutation({
      query: (body: { monitorsId: number[] }) => ({
        url: `/api/connectors/uptimerobot/cleandataadmin`,
        method: 'POST',
        body
      })
    }),
    triggerAudit: build.mutation({
      query: (body) => ({
        url: `/api/connectors/audit/trigger`,
        method: 'POST',
        body
      })
    }),
    removeConnection: build.mutation({
      query: (body: { connectorAuthName: string }) => ({
        url: `/api/connectors/${body.connectorAuthName}`,
        method: 'DELETE'
      })
    }),
    //TODO : pourquoi en POST ?
    getDetailsMailTester: build.mutation({
      query: (body: { OrganizationId: number; EmailAccount: string; Context: string }) => ({
        url: `/api/connectors/MailTester/GetInfos`,
        method: 'POST',
        body
      })
    }),
    connectLinkedin: build.mutation({
      query: (body: { code: string; state: string }) => ({
        url: `/api/connectors/linkedin/connect`,
        method: 'POST',
        body
      })
    }),
    addLinkedinState: build.mutation({
      query: (body: { state: string }) => ({
        url: `/api/connectors/linkedin/addState`,
        method: 'POST',
        body
      })
    }),
    updateResolvedBreachHibp: build.mutation({
      query: (body: { organizationId: number; email: string; breachName: string; status: boolean }) => ({
        url: `/api/connectors/hibp/update`,
        method: 'POST',
        body
      })
    }),
    selectSiteGoogleSearch: build.mutation({
      query: (body: { googleSearchUrl: string }) => ({
        url: `/api/connectors/google-search/domain-url`,
        method: 'POST',
        body
      })
    }),
    connectGoogle: build.mutation({
      query: (body: { code: string }) => ({
        url: `/api/connectors/google/connect`,
        method: 'POST',
        body
      })
    }),
    selectPropertyGUA: build.mutation({
      query: (body: { propertyId: string; propertyName: string }) => ({
        url: `/api/connectors/google-ua/property`,
        method: 'POST',
        body
      })
    }),
    selectPropertyGA4: build.mutation({
      query: (body: { propertyId: string; propertyName: string }) => ({
        url: `/api/connectors/google-ga4/property`,
        method: 'POST',
        body
      })
    }),
    selectPropertyAds: build.mutation({
      query: (body: { propertyId: string; propertyName: string }) => ({
        url: `/api/connectors/google-ads/property`,
        method: 'POST',
        body
      })
    }),
    connectYoutube: build.mutation({
      query: (body: { url: string }) => ({
        url: `/api/connectors/youtube/connect`,
        method: 'POST',
        body
      })
    }),
    connectInstagram: build.mutation({
      query: (body: { userId: string; token: string }) => ({
        url: `/api/connectors/instagram/connect`,
        method: 'POST',
        body
      })
    }),
    connectFacebook: build.mutation({
      query: (body: { facebookUserId: string; facebookToken: string }) => ({
        url: `/api/connectors/facebook/connect`,
        method: 'POST',
        body
      })
    }),
    updateSelectedEntity: build.mutation({
      query: (body: { entityId: string; connector: string; connectorStorageKeys: string }) => ({
        url: `/api/connectors/select-entity`,
        method: 'POST',
        body
      })
    }),
    dissociateSelectedEntity: build.mutation({
      query: (body: { connector: string; connectorStorageKeys: string }) => ({
        url: `/api/connectors/unselect-entity`,
        method: 'POST',
        body
      })
    }),
    getActivityCenter: build.query({
      query: () => `/api/connectors/aiconversation/activity`,
      providesTags: ['organization-id']
    }),
    getWebSiteSummary: build.query({
      query: () => `/api/connectors/aiconversation/summary`,
      providesTags: ['organization-id']
    }),
    generatePostFromUrlAndIdea: build.query<
      { post: string },
      { subject: string; url: string; nbHashTags: string; tonality: string; postLength: string; language: string }
    >({
      query: ({ url, subject, postLength, tonality, nbHashTags, language }) =>
        `/api/connectors/aiconversation/post?url=${url}&subject=${subject}&postLength=${postLength}&tonality=${tonality}&nbHashTags=${nbHashTags}&language=${language}`
    }),
    getTriggerAllAudit: build.query({
      query: () => `/api/connectors/auditmanagement/`
    }),
    triggerAuditAll: build.mutation({
      query: (body) => ({
        url: `/api/connectors/auditmanagement/`,
        method: 'POST',
        body
      })
    }),
    triggerAuditOne: build.mutation({
      query: (body) => ({
        url: `/api/connectors/auditmanagement/name`,
        method: 'POST',
        body
      })
    }),
    triggerAuditByOrganization: build.mutation({
      query: (body) => ({
        url: `/api/connectors/auditmanagement/organization`,
        method: 'POST',
        body
      })
    }),
    seoptimerStandalone: build.mutation({
      query: (body: { url: string; email: string }) => ({
        url: `/api/connectors/seo/standalone/audit`,
        method: 'POST',
        body
      })
    }),
    getSeoptimerStandalone: build.query({
      query: (body: { url: string; email: string }) => `/api/connectors/seo/standalone/audit`
    })
  })
});

export const {
  useAddLinkedinStateMutation,
  useConnectFacebookMutation,
  useConnectGoogleMutation,
  useConnectInstagramMutation,
  useConnectLinkedinMutation,
  useConnectYoutubeMutation,
  useDeleteUptimeRobotMonitorsMutation,
  useDissociateSelectedEntityMutation,
  useGetDetailsMailTesterMutation,
  useGetAllDetailsUptimeRobotQuery,
  useGetDetailsUptimeRobotMutation,
  useGetUptimeRobotAccountDetailsMutation,
  useGetUptimeRobotMonitorsAdminMutation,
  useGetWPScanStatusAdminQuery,
  useUpdateSelectedEntityMutation,
  useUpdateResolvedBreachHibpMutation,
  useTriggerAuditMutation,
  useSelectSiteGoogleSearchMutation,
  useSelectPropertyGUAMutation,
  useSelectPropertyGA4Mutation,
  useSelectPropertyAdsMutation,
  useRemoveConnectionMutation,
  useGetActivityCenterQuery,
  useGetWebSiteSummaryQuery,
  useLazyGeneratePostFromUrlAndIdeaQuery,
  useGeneratePostFromUrlAndIdeaQuery,
  useGetTriggerAllAuditQuery,
  useTriggerAuditAllMutation,
  useTriggerAuditOneMutation,
  useTriggerAuditByOrganizationMutation,
  useSeoptimerStandaloneMutation
} = connectorsApi;
