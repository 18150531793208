import _ from 'lodash';
import { Box, Typography } from '@mui/material';
import { t } from '@lingui/macro';

// Constants
import ConnectorAuthValues from 'components/legacy/connectors/connectorAuthValues';
import connectorStorageKeys from 'components/legacy/connectors/connectorStorageKeys';

// Hooks
import useSocialDataHistory from 'components/legacy/hooks/useSocialDataHistory';
import useStorageValueCollection from 'components/legacy/hooks/useStorageValueCollection';

// Components
import ReportPartContainer from '../common';

const ReportImpressionsProgress = (props) => {
  if (props.organizationId === null || props.organizationId === undefined)
    throw new Error('ReportImpressionsProgress organizationid empty');
  const [mapping] = useStorageValueCollection(
    [
      connectorStorageKeys.facebook.connectionStatus,
      connectorStorageKeys.instagram.connectionStatus,
      connectorStorageKeys.linkedin.connectionStatus,
      connectorStorageKeys.youtube.connectionStatus
    ],
    props.organizationId
  );

  const [data] = useSocialDataHistory({ days: 15, property: 'impressions' }, props.organizationId);

  // if there isn't any at least on social network connected, hide this block
  if (!_.find(_.values(mapping), (storageValue) => storageValue.value === ConnectorAuthValues.succeeded)) {
    return null;
  }

  // set a break point 8 days ago
  const lastWeekThreshold = new Date();
  lastWeekThreshold.setDate(new Date().getDate() - 8);

  const lastWeekValues = _.filter(data, (d) => new Date(d.date) <= lastWeekThreshold);
  const previousWeekValues = _.filter(data, (d) => new Date(d.date) > lastWeekThreshold);

  const lastWeekCount = _.sumBy(lastWeekValues, (d) => d.impressions);
  const previousWeekCount = _.sumBy(previousWeekValues, (d) => d.impressions);

  const difference = lastWeekCount - previousWeekCount;

  return (
    <ReportPartContainer displayLink={props.displayLink} route={'/redirect/social-network-impressions'}>
      <Typography sx={{ textAlign: 'center', pt: 2 }} variant="h4">
        {t`Vues*`}
      </Typography>
      <Typography sx={{ textAlign: 'center' }}>{t`sur tous vos réseaux sociaux`}</Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          py: 2
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            fontFamily: 'Inter, sans-serif',
            fontSize: 58,
            color: (theme) => theme.palette.primary.main,
            fontStyle: 'normal',
            fontWeight: 700,
            letterSpacing: 'normal',
            lineHeight: 50 / 42
          }}
        >
          {new Intl.NumberFormat().format(lastWeekCount)}
        </Typography>
        <Typography
          sx={{
            ml: 1,
            textAlign: 'center',
            fontFamily: 'Inter, sans-serif',
            fontSize: 24,
            fontStyle: 'normal',
            fontWeight: 700,
            letterSpacing: 'normal',
            lineHeight: 50 / 42,
            color: (theme) =>
              difference === null
                ? theme.palette.text.primary
                : difference > 0
                ? theme.palette.success.main
                : difference === 0
                ? theme.palette.text.primary
                : difference < 0
                ? theme.palette.error.main
                : theme.palette.error.main
          }}
        >
          {difference === null
            ? '--'
            : difference > 0
            ? '+' + difference
            : difference === 0
            ? '--'
            : difference < 0
            ? difference
            : difference}
        </Typography>
      </Box>
    </ReportPartContainer>
  );
};

export default ReportImpressionsProgress;
