// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const taskItemApi = createApi({
  reducerPath: 'taskItemApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API,
    prepareHeaders: () => {
      return baseHeader(true);
    }
  }),
  tagTypes: ['taskitems', 'taskitemsAccomplishment'],
  endpoints: (build) => ({
    getOrganizationTaskItemsAccomplishment: build.query({
      query: ({ id }) => `/api/taskitems/getaccomplishment/${id}`,
      providesTags: ['taskitemsAccomplishment']
    }),
    getOrganizationTaskItems: build.query({
      query: ({ id }) => `/api/taskitems/getlist/${id}`,
      providesTags: ['taskitems']
    }),
    getTaskItems: build.query({
      query: ({ languageCode }) => `/api/taskitems/getall?lg=${languageCode}`,
      providesTags: ['taskitems']
    }),
    getAdminTaskItem: build.query({
      query: ({ taskItemKey, languageCode }) => `/api/taskItems/edit/${taskItemKey}?lg=${languageCode}`
    }),
    updateTaskItemValidationTranslation: build.mutation({
      query: (body: { taskItemKey: string; languageCode: string; isValidate: boolean }) => ({
        url: `/api/taskitems/translations/validation`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['taskitems']
    }),
    updateTaskItemIndex: build.mutation({
      query: (body: { indexes: { id: number; index: number }[] }) => ({
        url: `/api/taskitems/indexes`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['taskitems']
    }),
    updateAllTaskItems: build.mutation({
      query: () => ({
        url: `/api/taskitems/checkAllTaskItems`,
        method: 'PUT'
      }),
      invalidatesTags: ['taskitems']
    }),
    syncTaskItemTranslations: build.mutation({
      query: (body: {
        translations: {
          title: string;
          description: string;
          messageDone: string;
          languageCode: string;
          taskItemKey: string;
        }[];
      }) => ({
        url: `/api/taskitems/translations`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['taskitems']
    }),
    editTaskItem: build.mutation({
      query: (body: { key: string; url: string; publicationStatus: 'published' | 'draft' }) => ({
        url: `/api/taskitems/edit`,
        method: 'PUT',
        body
      })
    }),
    deleteTaskItem: build.mutation({
      query: ({ key }) => ({
        url: `/api/taskitems/${key}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['taskitems']
    }),
    // condition type : { visibility: 1; completion: 2 }
    updateTaskItemConditions: build.mutation({
      query: (body: {
        taskItemKey: string;
        conditionType: number;
        conditions: {
          conditionType: number;
          sourceType: string;
          sourceKey: string;
          operator: string;
          target: string;
        }[];
      }) => ({
        url: `/api/taskItems/conditions`,
        method: 'PUT',
        body
      })
    }),
    AddTaskItem: build.mutation({
      query: (body: { key: string; url: string; publicationStatus: 'published' | 'draft' }) => ({
        url: `/api/taskitems/add`,
        method: 'POST',
        body
      })
    })
  })
});

export const {
  useAddTaskItemMutation,
  useDeleteTaskItemMutation,
  useEditTaskItemMutation,
  useGetAdminTaskItemQuery,
  useGetTaskItemsQuery,
  useUpdateTaskItemValidationTranslationMutation,
  useUpdateTaskItemIndexMutation,
  useUpdateTaskItemConditionsMutation,
  useUpdateAllTaskItemsMutation,
  useSyncTaskItemTranslationsMutation,
  useGetOrganizationTaskItemsAccomplishmentQuery,
  useGetOrganizationTaskItemsQuery,
  useLazyGetAdminTaskItemQuery
} = taskItemApi;
