// Common
import { request } from '../../common';

// Models

export const getTopTenOrganizationDetails = (body): Promise<Response> =>
  request({ method: 'POST', api: `/api/organizations/top-ten-details`, authorized: true, body: body });

interface Response {
  data: {
    organizations: [];
  };
  errors: string[];
}
