import { forwardRef } from 'react';

// Externals
import { Box, Theme, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import type { ScrollBarProps } from 'react-perfect-scrollbar';

const Scrollbar = forwardRef<HTMLDivElement, ScrollBarProps>((props, ref) => {
  const { children, ...other } = props;

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <Box ref={ref} sx={{ overflowX: 'auto' }}>
        {children}
      </Box>
    );
  }

  return (
    <PerfectScrollbar
      {...other}
      // @ts-ignore
      ref={ref}
    >
      {children}
    </PerfectScrollbar>
  );
});

Scrollbar.propTypes = {
  children: PropTypes.node
};

export default Scrollbar;
