import { useEffect, useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import _ from 'lodash';
import moment from 'moment';

// Models
import { ISocialData, SocialDataKey } from 'components/legacy/models/socialData.dto';
import { NetworkKey, networks } from 'components/legacy/components/network';
import { useGetSocialDataHistoryQuery } from '../services/socialData';

const useSocialDataHistory = (
  {
    days = null,
    property
  }: {
    days: number | null;
    property: SocialDataKey;
  },
  organizationId: number
): [ISocialData[], (string | number)[][] | null] => {
  const { data } = useGetSocialDataHistoryQuery({ days, organizationId });

  const arrayData = useMemo(() => {
    if (data?.data?.values === null) return null;

    const groupedData = _.groupBy(data?.data?.values, (d) => moment(d.date).format('DD/MM'));

    const titles = [t`Date`, ..._.map(_.keys(networks), (key: NetworkKey) => networks[key].title)];
    const namespaces = _.map(_.keys(networks), (key: NetworkKey) => networks[key].namespace);

    const values = _.map(groupedData, (group, key) => {
      return [
        key,
        ..._.map(namespaces, (namespace) => {
          const socialData = _.find(group, (item) => item.network === namespace);
          return socialData ? socialData[property] : 0;
        })
      ];
    });

    return [titles, ...values];
  }, [data, property]);

  return [data?.data?.values, arrayData];
};

export default useSocialDataHistory;
