import { Trans } from '@lingui/macro';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';

const AdsTableHead = ({ onRequestSort, sortColumn, sortOrder }) => {
  const handleSort = (columnId) => {
    const isAsc = sortColumn === columnId && sortOrder === 'asc';

    onRequestSort(columnId, isAsc ? 'desc' : 'asc');
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          key="Pub"
          align="left"
          padding="none"
          sx={{
            position: 'sticky',
            top: 0,
            backgroundColor: (theme) => theme.palette.background.default,
            zIndex: (theme) => theme.zIndex.appBar
          }}
        >
          <TableSortLabel active={sortColumn === 'Pub'} direction={sortOrder} onClick={() => handleSort('Pub')}>
            <Trans>Campagne</Trans>
          </TableSortLabel>
        </TableCell>
        <TableCell
          key="CostMicros"
          align="right"
          padding="none"
          sx={{
            position: 'sticky',
            top: 0,
            backgroundColor: (theme) => theme.palette.background.default,
            zIndex: (theme) => theme.zIndex.appBar
          }}
        >
          <TableSortLabel
            active={sortColumn === 'CostMicros'}
            direction={sortOrder}
            onClick={() => handleSort('CostMicros')}
          >
            <Trans>Budget</Trans>
          </TableSortLabel>
        </TableCell>
        <TableCell
          key="Impressions"
          align="right"
          padding="none"
          sx={{
            position: 'sticky',
            top: 0,
            backgroundColor: (theme) => theme.palette.background.default,
            zIndex: (theme) => theme.zIndex.appBar
          }}
        >
          <TableSortLabel
            active={sortColumn === 'Impressions'}
            direction={sortOrder}
            onClick={() => handleSort('Impressions')}
          >
            <Trans>Impressions</Trans>
          </TableSortLabel>
        </TableCell>
        <TableCell
          key="Clicks"
          align="right"
          padding="none"
          sx={{
            position: 'sticky',
            top: 0,
            backgroundColor: (theme) => theme.palette.background.default,
            zIndex: (theme) => theme.zIndex.appBar
          }}
        >
          <TableSortLabel active={sortColumn === 'Clicks'} direction={sortOrder} onClick={() => handleSort('Clicks')}>
            <Trans>Clics</Trans>
          </TableSortLabel>
        </TableCell>
        <TableCell
          key="CPC"
          align="right"
          padding="none"
          sx={{
            position: 'sticky',
            top: 0,
            backgroundColor: (theme) => theme.palette.background.default,
            zIndex: (theme) => theme.zIndex.appBar
          }}
        >
          <TableSortLabel active={sortColumn === 'CPC'} direction={sortOrder} onClick={() => handleSort('CPC')}>
            <Trans>CPC moyen</Trans>
          </TableSortLabel>
        </TableCell>
        <TableCell
          key="CPM"
          align="right"
          padding="none"
          sx={{
            position: 'sticky',
            top: 0,
            backgroundColor: (theme) => theme.palette.background.default,
            zIndex: (theme) => theme.zIndex.appBar
          }}
        >
          <TableSortLabel active={sortColumn === 'CPM'} direction={sortOrder} onClick={() => handleSort('CPM')}>
            <Trans>CPM</Trans>
          </TableSortLabel>
        </TableCell>
        <TableCell
          key="Conversions"
          align="right"
          padding="none"
          sx={{
            position: 'sticky',
            top: 0,
            backgroundColor: (theme) => theme.palette.background.default,
            zIndex: (theme) => theme.zIndex.appBar
          }}
        >
          <TableSortLabel
            active={sortColumn === 'Conversions'}
            direction={sortOrder}
            onClick={() => handleSort('Conversions')}
          >
            <Trans>Conversions</Trans>
          </TableSortLabel>
        </TableCell>
        <TableCell
          key="Details"
          align="right"
          padding="none"
          sx={{
            position: 'sticky',
            top: 0,
            backgroundColor: (theme) => theme.palette.background.default,
            zIndex: (theme) => theme.zIndex.appBar
          }}
        >
          <TableSortLabel active={sortColumn === 'Details'} direction={sortOrder} onClick={() => handleSort('Details')}>
            <Trans> Données(166)</Trans>
          </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default AdsTableHead;
