import { useEffect, useState } from 'react';

// Internals
import { ICustomBlock, UserComponentProps } from '../../index';
import { Header } from 'components/legacy/pages/audit/questions/header';
import * as StorageDataKeys from '../../google/constants';

// Externals
import _ from 'lodash';
import { Box, Grid, Paper, Theme, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery } from '@mui/material';

import { t, Trans } from '@lingui/macro';
import { useParams } from 'react-router';
import moment from 'moment';
import ReactECharts from 'echarts-for-react';

// Stores
import { useSelector } from 'components/legacy/store';
import { QuestionScoreDto } from 'components/legacy/models/questionScore.dto';
import { GoogleSearchReport, GoogleSearchReportProgress } from '../models';
import { StorageValue } from 'components/legacy/hooks/useStorageValue';
import useStorageValueCollection, { Query } from 'components/legacy/hooks/useStorageValueCollection';
import { useGetQuestionScoresQuery } from 'components/legacy/services/questions';

interface ComponentProps {
  name: string;
  description: string;
  connectorKey: string;
  tooltip: string;
  questionKey: string;
}

interface IStorageValueCollection extends Query {
  [StorageDataKeys.LAST_YEAR_DAILY_REPORT]: StorageValue<GoogleSearchReport>;
  [StorageDataKeys.LAST_7_DAYS_REPORT_PROGRESS]: StorageValue<GoogleSearchReportProgress>;
  [StorageDataKeys.LAST_30_DAYS_REPORT_PROGRESS]: StorageValue<GoogleSearchReportProgress>;
  [StorageDataKeys.LAST_365_DAYS_REPORT_PROGRESS]: StorageValue<GoogleSearchReportProgress>;
}

const UserComponent = ({ name, description, connectorKey, questionKey, tooltip }: ComponentProps) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const betweenSmallAndMd = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'md'));
  const betweenMdAndLg = useMediaQuery((theme: Theme) => theme.breakpoints.between('md', 'lg'));

  const [alignment, setAlignment] = useState('365');
  const [categories, setCategories] = useState<any>([]);
  const [data, setData] = useState<any>([]);

  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setAlignment(newAlignment);
  };

  const [mapping] = useStorageValueCollection<IStorageValueCollection>(
    [
      StorageDataKeys.LAST_YEAR_DAILY_REPORT,
      StorageDataKeys.LAST_7_DAYS_REPORT_PROGRESS,
      StorageDataKeys.LAST_30_DAYS_REPORT_PROGRESS,
      StorageDataKeys.LAST_365_DAYS_REPORT_PROGRESS
    ],
    organizationId
  );
  const last7daysClick = mapping['google-search:last_7_days_report_progress'].value?.last.clicks;
  const last7daysClickProgression = mapping['google-search:last_7_days_report_progress'].value?.progress?.clicks;

  const last30daysClick = mapping['google-search:last_30_days_report_progress'].value?.last.clicks;
  const last30daysClickProgression = mapping['google-search:last_30_days_report_progress'].value?.progress?.clicks;

  const last365daysClick = mapping['google-search:last_365_days_report_progress'].value?.last.clicks;
  const last365daysClickProgression = mapping['google-search:last_365_days_report_progress'].value?.progress?.clicks;

  const { auditKey } = useParams();

  const { data: scoresQuery, isLoading: loadingScores } = useGetQuestionScoresQuery({
    organizationId,
    auditKey
  });

  const scores: QuestionScoreDto[] = useSelector<QuestionScoreDto[]>((state) =>
    scoresQuery?.data?.questions?.filter((s) => s.key === questionKey)
  );
  const score = _.sumBy(scores, (s) => s.score);

  const lastNinetyDaysReport = mapping[StorageDataKeys.LAST_YEAR_DAILY_REPORT];

  const clickValues =
    lastNinetyDaysReport.value !== null
      ? lastNinetyDaysReport.value.rows.map((row) => {
          return [row.keys[0], row.clicks];
        })
      : null;

  useEffect(() => {
    if (clickValues) {
      const categoriesTmp = [];
      const dataTmp = [];
      clickValues?.slice(-parseInt(alignment), clickValues.length).map((element) => {
        categoriesTmp.push(moment(element[0]).format('DD/MM/YY'));
        dataTmp.push(element[1]);
      });
      setCategories(categoriesTmp);
      setData(dataTmp);
    }
  }, [clickValues, alignment]);

  const option = {
    color: ['rgb(77, 119, 255)'],
    smooth: true,
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: categories,
      axisLabel: {
        rotate: 30
      }
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    tooltip: {
      trigger: 'axis'
    },
    yAxis: {
      type: 'value',
      height: '400px'
    },
    series: [
      {
        showSymbol: false,
        data: data,
        type: 'line',
        stack: 'Total'
      }
    ]
  };

  return (
    <>
      <Header
        isScoreVisible={true}
        label={description}
        organizationId={organizationId}
        score={score}
        title={name}
        tooltip={tooltip}
      />
      {clickValues !== null ? (
        <Grid container spacing={3}>
          {isMobile || betweenSmallAndMd || betweenMdAndLg ? (
            ''
          ) : (
            <>
              <Grid lg={4}></Grid>
              <Grid lg={8}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    py: 2,
                    pl: 4,
                    justifyContent: 'start'
                  }}
                >
                  <ToggleButtonGroup color="primary" exclusive={true} onChange={handleChange} value={alignment}>
                    <ToggleButton value="30">{t`30 jours`}</ToggleButton>
                    <ToggleButton value="90">{t`90 jours`}</ToggleButton>
                    <ToggleButton value="365">{t`365 jours`}</ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Grid>
            </>
          )}

          {/* bulles */}
          <Grid
            item
            lg={4}
            xs={12}
            sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mt: isMobile ? 2 : 0 }}
          >
            <Grid sx={{ mb: 2, width: '100%' }} item={true}>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  position: 'relative',
                  height: '100%',
                  background: '#FBF9FF',
                  borderRadius: '20px',
                  breakInside: 'avoid'
                }}
              >
                <Typography sx={{ textAlign: 'center', pt: 1, fontWeight: 400 }} variant="h6">
                  {t`7 derniers jours`}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',

                    flexDirection: 'row'
                  }}
                >
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      textAlign: 'center',
                      fontFamily: 'Inter, sans-serif',
                      fontSize: 32,
                      fontStyle: 'normal',
                      fontWeight: 700,
                      letterSpacing: 'normal'
                    }}
                  >
                    {new Intl.NumberFormat().format(last7daysClick)}
                  </Typography>
                  <Typography
                    sx={{
                      ml: 1,
                      textAlign: 'center',
                      fontFamily: 'Inter, sans-serif',
                      fontSize: 20,
                      fontStyle: 'normal',
                      fontWeight: 700,
                      letterSpacing: 'normal',

                      color: (theme) =>
                        last7daysClick === null
                          ? theme.palette.text.primary
                          : last7daysClickProgression > 0
                          ? theme.palette.success.main
                          : last7daysClickProgression === 0
                          ? theme.palette.text.primary
                          : last7daysClickProgression < 0
                          ? theme.palette.error.main
                          : theme.palette.error.main
                    }}
                  >
                    {last7daysClickProgression === null
                      ? '--'
                      : last7daysClickProgression > 0
                      ? '+' + new Intl.NumberFormat().format(last7daysClickProgression)
                      : last7daysClickProgression === 0
                      ? '--'
                      : last7daysClickProgression < 0
                      ? new Intl.NumberFormat().format(last7daysClickProgression)
                      : new Intl.NumberFormat().format(last7daysClickProgression)}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid sx={{ mb: 2, width: '100%' }} item={true}>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  position: 'relative',
                  height: '100%',
                  background: '#FBF9FF',
                  borderRadius: '20px',
                  breakInside: 'avoid'
                }}
              >
                <Typography sx={{ textAlign: 'center', pt: 1, fontWeight: 400 }} variant="h6">
                  {t`30 derniers jours`}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',

                    flexDirection: 'row'
                  }}
                >
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      textAlign: 'center',
                      fontFamily: 'Inter, sans-serif',
                      fontSize: 32,
                      fontStyle: 'normal',
                      fontWeight: 700,
                      letterSpacing: 'normal'
                    }}
                  >
                    {new Intl.NumberFormat().format(last30daysClick)}
                  </Typography>
                  <Typography
                    sx={{
                      ml: 1,
                      textAlign: 'center',
                      fontFamily: 'Inter, sans-serif',
                      fontSize: 20,
                      fontStyle: 'normal',
                      fontWeight: 700,
                      letterSpacing: 'normal',

                      color: (theme) =>
                        last30daysClickProgression === null
                          ? theme.palette.text.primary
                          : last30daysClickProgression > 0
                          ? theme.palette.success.main
                          : last30daysClickProgression === 0
                          ? theme.palette.text.primary
                          : last30daysClickProgression < 0
                          ? theme.palette.error.main
                          : theme.palette.error.main
                    }}
                  >
                    {last30daysClickProgression === null
                      ? '--'
                      : last30daysClickProgression > 0
                      ? '+' + new Intl.NumberFormat().format(last30daysClickProgression)
                      : last30daysClickProgression === 0
                      ? '--'
                      : last30daysClickProgression < 0
                      ? new Intl.NumberFormat().format(last30daysClickProgression)
                      : new Intl.NumberFormat().format(last30daysClickProgression)}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid sx={{ width: '100%' }} item={true}>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  position: 'relative',
                  height: '100%',
                  background: '#FBF9FF',
                  borderRadius: '20px',
                  breakInside: 'avoid'
                }}
              >
                <Typography sx={{ textAlign: 'center', pt: 1, fontWeight: 400 }} variant="h6">
                  {t`365 derniers jours`}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',

                    flexDirection: 'row'
                  }}
                >
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      textAlign: 'center',
                      fontFamily: 'Inter, sans-serif',
                      fontSize: 32,
                      fontStyle: 'normal',
                      fontWeight: 700,
                      letterSpacing: 'normal'
                    }}
                  >
                    {new Intl.NumberFormat().format(last365daysClick)}
                  </Typography>
                  <Typography
                    sx={{
                      ml: 1,
                      textAlign: 'center',
                      fontFamily: 'Inter, sans-serif',
                      fontSize: 20,
                      fontStyle: 'normal',
                      fontWeight: 700,
                      letterSpacing: 'normal',

                      color: (theme) =>
                        last7daysClick === null
                          ? theme.palette.text.primary
                          : last365daysClickProgression > 0
                          ? theme.palette.success.main
                          : last365daysClickProgression === 0
                          ? theme.palette.text.primary
                          : last365daysClickProgression < 0
                          ? theme.palette.error.main
                          : theme.palette.error.main
                    }}
                  >
                    {last365daysClickProgression === null
                      ? '--'
                      : last365daysClickProgression > 0
                      ? '+' + new Intl.NumberFormat().format(last365daysClickProgression)
                      : last365daysClickProgression === 0
                      ? '--'
                      : last365daysClickProgression < 0
                      ? new Intl.NumberFormat().format(last365daysClickProgression)
                      : new Intl.NumberFormat().format(last365daysClickProgression)}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>

          {!isMobile ? (
            ''
          ) : (
            <>
              <Grid item md={12} xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    pt: 4,
                    justifyContent: 'center'
                  }}
                >
                  <ToggleButtonGroup color="primary" exclusive={true} onChange={handleChange} value={alignment}>
                    <ToggleButton value="30">{t`30 jours`}</ToggleButton>
                    <ToggleButton value="90">{t`90 jours`}</ToggleButton>
                    <ToggleButton value="365">{t`365 jours`}</ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Grid>
            </>
          )}
          {betweenSmallAndMd || betweenMdAndLg ? (
            <>
              <Grid item md={12} xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    pt: 4,
                    justifyContent: 'center'
                  }}
                >
                  <ToggleButtonGroup color="primary" exclusive={true} onChange={handleChange} value={alignment}>
                    <ToggleButton value="30">{t`30 jours`}</ToggleButton>
                    <ToggleButton value="90">{t`90 jours`}</ToggleButton>
                    <ToggleButton value="365">{t`365 jours`}</ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Grid>
            </>
          ) : (
            ''
          )}

          {/* chart */}
          <Grid item lg={8} xs={12}>
            <ReactECharts option={option} />
          </Grid>
        </Grid>
      ) : (
        <Trans>Pas de données</Trans>
      )}
    </>
  );
};

class GoogleSearchNbrClic implements ICustomBlock {
  tooltip = () =>
    t`Ce graphique représente le nombre de clics pour chaque jour durant des périodes différentes. Un clic est compté lorsqu'une personne voit votre page dans une recherche sur Google et clique sur le lien.`;
  description = () => t``;
  name = () => t`Nombre de clics vers votre site `;
  key = () => 'google-search-nbr-clic';
  UserComponent: React.FC<UserComponentProps> = (props) => (
    <UserComponent
      connectorKey={this.key()}
      description={this.description()}
      name={this.name()}
      questionKey={props.questionKey}
      tooltip={this.tooltip()}
    />
  );
}

export default GoogleSearchNbrClic;
