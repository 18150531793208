import { Trans } from '@lingui/macro';
import { TableRow, TableCell, TableSortLabel } from '@mui/material';

const KeyWordsTableHead = ({ onRequestSort, sortColumn, sortOrder }) => {
  const handleSort = (columnId) => {
    const isAsc = sortColumn === columnId && sortOrder === 'asc';

    onRequestSort(columnId, isAsc ? 'desc' : 'asc');
  };

  return (
    <TableRow>
      <TableCell key="KeyWords" align="left">
        <Trans>Mots-clés</Trans>
      </TableCell>
      <TableCell key="Spent" align="right">
        <TableSortLabel active={sortColumn === 'Spent'} direction={sortOrder} onClick={() => handleSort('Spent')}>
          <Trans>Dépensé</Trans>
        </TableSortLabel>
      </TableCell>
      <TableCell key="Impressions" align="right">
        <TableSortLabel
          active={sortColumn === 'Impressions'}
          direction={sortOrder}
          onClick={() => handleSort('Impressions')}
        >
          <Trans>Impressions</Trans>
        </TableSortLabel>
      </TableCell>
      <TableCell key="CTR" align="right">
        <TableSortLabel active={sortColumn === 'CTR'} direction={sortOrder} onClick={() => handleSort('CTR')}>
          <Trans>CTR</Trans>
        </TableSortLabel>
      </TableCell>
      <TableCell key="Clicks" align="right">
        <TableSortLabel active={sortColumn === 'Clicks'} direction={sortOrder} onClick={() => handleSort('Clicks')}>
          <Trans>Clics</Trans>
        </TableSortLabel>
      </TableCell>
      <TableCell key="CPC" align="right">
        <TableSortLabel active={sortColumn === 'CPC'} direction={sortOrder} onClick={() => handleSort('CPC')}>
          <Trans>CPC</Trans>
        </TableSortLabel>
      </TableCell>
    </TableRow>
  );
};

export default KeyWordsTableHead;
