// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const auditLevelGridsApi = createApi({
  reducerPath: 'auditLevelGridsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API,
    prepareHeaders: () => {
      return baseHeader(true);
    }
  }),
  endpoints: (build) => ({
    getLevelGrids: build.query({
      query: () => '/api/audits/level-grids'
    }),
    addLevelGrid: build.mutation({
      query: (body) => ({
        url: `/api/audits/level-grids/add`,
        method: 'POST',
        body
      })
    })
  })
});

export const { useAddLevelGridMutation, useGetLevelGridsQuery } = auditLevelGridsApi;
