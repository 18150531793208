import * as React from 'react';
import { useState } from 'react';

// Internals
import { ICustomBlock, UserComponentProps } from '../index';
import { Header } from 'components/legacy/pages/audit/questions/header';
import BoxAnalytics from './boxAnalytics';

// Externals
import { t } from '@lingui/macro';
import { Grid, Typography } from '@mui/material';

interface ComponentProps {
  name: string;
  description: string;
  connectorKey: string;
  questionKey: string;
  tooltip: string;
}

const UserComponent = ({ name, description, connectorKey, questionKey, tooltip }: ComponentProps) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));
  const [haveDataToDisplay, setHaveDataToDisplay] = useState(false);

  return (
    <>
      <Header isScoreVisible={false} label={description} organizationId={organizationId} title={name} />

      <Grid
        container
        spacing={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: haveDataToDisplay ? 'center' : 'start',
          ml: haveDataToDisplay ? 0 : '4px'
        }}
      >
        {!haveDataToDisplay && <Typography variant="body2">{t`Pas de données pour le moment.`}</Typography>}
        <BoxAnalytics type="facebook" setHaveDataToDisplay={setHaveDataToDisplay} />
        <BoxAnalytics type="instagram" setHaveDataToDisplay={setHaveDataToDisplay} />
        <BoxAnalytics type="linkedin" setHaveDataToDisplay={setHaveDataToDisplay} />
      </Grid>
    </>
  );
};

class SocialNetworkViewsAndLikesRecap implements ICustomBlock {
  description = () => '';
  name = () => t`Likes & Réactions`;
  tooltip = () =>
    t`Voici la somme des réactions à vos publications pour différents réseaux sociaux. Pour les 30 derniers jours, nous vous indiquons la différence par rapport au mois précédent.`;
  key = () => 'socialNetworkLikes-recap';
  UserComponent: React.FC<UserComponentProps> = (props) => (
    <UserComponent
      connectorKey={this.key()}
      description={this.description()}
      name={this.name()}
      questionKey={props.questionKey}
      tooltip={this.tooltip()}
    />
  );
}

export default SocialNetworkViewsAndLikesRecap;
