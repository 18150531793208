import { t } from '@lingui/macro';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Typography,
  TableFooter,
  TablePagination
} from '@mui/material';

import { useState } from 'react';
import KeyWordsTableHead from './header';
import React from 'react';

import { round } from 'lodash';
import useMoment from 'components/legacy/hooks/useMoment';
import { PlayCircleOutline, PauseCircleOutline } from '@mui/icons-material';
import { GoogleAdsKeywordDTO } from 'components/legacy/models/googleAdsKeywords.dto';

const KeyWordsTableMobile = ({
  rows,
  totalRows,
  pageSize,
  pageNumber,
  handleChangePage,
  handleChangeRowsPerPage,
  sortColumn,
  sortOrder,
  handleRequestSort
}) => {
  const organizationId = Number(window.localStorage.getItem('organizationId'));

  const moment = useMoment();

  const KeyWords: React.FC<{ row: GoogleAdsKeywordDTO }> = (row) => {
    return (
      <Box sx={{ maxWidth: 'auto', display: 'flex', flexDirection: 'row', alignitem: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            align: 'center',
            verticalAlign: 'center'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              align: 'center',
              verticalAlign: 'center',
              margin: 0
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'left',
                flexDirection: 'column',
                maxWidth: '250px'
              }}
            >
              <Typography
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  maxHeight: '1.5em',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1
                }}
              >
                {/* row.name = titre de la campagne
              row.text = description de la campagne
              (row.name !== null ? row.adsId : 'titre')
              */}
                {row.row.word}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return rows === null || rows?.length === 0 ? (
    <Typography variant="body2">{t`Pas de données pour le moment.`}</Typography>
  ) : (
    <TableContainer sx={{ maxHeight: '500px' }}>
      <Table size="small" stickyHeader>
        <KeyWordsTableHead
          onRequestSort={handleRequestSort}
          sortColumn={sortColumn}
          sortOrder={sortOrder}
        ></KeyWordsTableHead>
        <TableBody>
          {rows === null ||
            (rows?.length === 0 && (
              <TableRow key="empty">
                <Typography variant="body2">{t`Aucune données n'a été trouvée`}</Typography>
              </TableRow>
            ))}
          {rows?.map((keyword: GoogleAdsKeywordDTO) => {
            return (
              <TableRow key={keyword.id}>
                <TableCell>
                  <KeyWords row={keyword} />
                </TableCell>
                <TableCell align="right">
                  {new Intl.NumberFormat().format(round(keyword.spent, 2)) +
                    (keyword?.currency === 'USD' ? ' $' : ' €')}
                </TableCell>
                <TableCell align="right">{new Intl.NumberFormat().format(keyword.impressions)}</TableCell>
                <TableCell align="right">
                  {keyword.impressions !== 0
                    ? new Intl.NumberFormat().format(round((keyword.clicks / keyword.impressions) * 100, 2)) + '%'
                    : '0%'}
                </TableCell>

                <TableCell align="right">{new Intl.NumberFormat().format(keyword.clicks)}</TableCell>
                <TableCell align="right">
                  {keyword.clicks !== 0
                    ? new Intl.NumberFormat().format(round(keyword.spent / keyword.clicks, 2)) +
                      (keyword?.currency === 'USD' ? ' $' : ' €')
                    : '0 $'}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={rows !== null && rows?.length > 0 ? [10, 20] : []}
              colSpan={2}
              count={totalRows}
              rowsPerPage={pageSize}
              page={pageNumber}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={''}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
export default KeyWordsTableMobile;
