// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const contactApi = createApi({
  reducerPath: 'contactApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API,
    prepareHeaders: () => {
      return baseHeader(false);
    }
  }),
  endpoints: (build) => ({
    contactSupport: build.mutation({
      query: (body: {
        firstName: string;
        lastName: string;
        email: string;
        companyName: string;
        companySize: string;
        job: string;
      }) => ({
        url: `/api/contact/support`,
        method: 'POST',
        body
      })
    })
  })
});

export const contactPrivateApi = createApi({
  reducerPath: 'contactPrivateApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API,
    prepareHeaders: () => {
      return baseHeader(true);
    }
  }),
  endpoints: (build) => ({
    sendMessageToCopilot: build.mutation({
      query: (body: { message: string }) => ({
        url: `/api/contact/copilot`,
        method: 'POST',
        body
      })
    })
  })
});

export const { useContactSupportMutation } = contactApi;
export const { useSendMessageToCopilotMutation } = contactPrivateApi;
