import { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';

import { useGetOrganizationDetailsQuery } from '../services/organizations';

interface Query {
  [key: string]: StorageValue<any>;
}

const useOrganizationValueCollection = <T extends Query>(keys: string[], organizationId: number): [T] => {
  // The value to retrieve and subscribe to
  const [values, setValues] = useState<T>(
    _.reduce(keys, (values, key) => ({ ...values, [key]: { key: key, value: null, loading: true } }), {} as T)
  );

  const orgaId = Number(window.localStorage.getItem('organizationId'));

  const { data: organizationDetails, isLoading: loadingOrganizationDetails } = useGetOrganizationDetailsQuery({
    id: orgaId
  });
  const keysHash = keys.join('');

  const retrieveValueCollectionAsync = useCallback(async () => {
    if (!keys.length) return;

    const data = _.reduce(
      keys,
      (values, key) => ({
        ...values,
        [key]: {
          key: key.split(':')[1],
          value: organizationDetails?.data?.organization?.[key.split(':')[1]]
            ? organizationDetails?.data?.organization?.[key.split(':')[1]]
            : null,
          loading: false
        }
      }),
      {} as T
    );

    setValues(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keysHash, organizationId]);

  useEffect(() => {
    retrieveValueCollectionAsync();
  }, [retrieveValueCollectionAsync]);

  return [values];
};

interface StorageValue<StorageValueType> {
  key: string;
  value: StorageValueType | null;
  loading: boolean;
  error?: string;
}

export default useOrganizationValueCollection;
export type { Query };
