// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const organizationsApi = createApi({
  reducerPath: 'organizationsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API,
    prepareHeaders: () => {
      return baseHeader(true);
    }
  }),
  tagTypes: ['OrganizationDetails', 'ListOrganizations'],
  endpoints: (build) => ({
    getMyMemberships: build.query({
      query: () => `/api/organizations/members/memberships/list`,
      providesTags: ['ListOrganizations']
    }),
    getOrganizations: build.query({
      query: () => `/api/organizations`,
      providesTags: ['ListOrganizations']
    }),
    getOrganizationDetails: build.query({
      query: () => `/api/organizations/details/`,

      providesTags: ['OrganizationDetails']
    }),
    getOrganizationDetailsById: build.query({
      query: ({ id }) => `/api/organizations/details/${id}`
    }),
    getAllOrganizationDetails: build.query({
      query: () => `/api/organizations/all-details`
    }),
    getDomainTech: build.query({
      query: () => `/api/organizations/tech`
    }),
    getCountRemainingQuestions: build.query({
      query: () => `/api/organizations/remaining-questions`
    }),
    updateOrganizationPicture: build.mutation({
      query: (formData) => ({
        url: `/api/organizations/picture`,
        method: 'PUT',
        body: formData,
        formData: true
      }),
      invalidatesTags: ['OrganizationDetails']
    }),
    updateOrganizationHidden: build.mutation({
      query: (body: { isHidden: boolean }) => ({
        url: `/api/organizations/hidden`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['OrganizationDetails']
    }),
    updateTrialDate: build.mutation({
      query: (body: { organizationId: number; trialDateStart?: Date; trialDateEnd?: Date }) => ({
        url: `/api/organizations/trial-date`,
        method: 'PUT',
        body
      })
    }),
    updateOrganization: build.mutation({
      query: (body: {
        id: number;
        name: string;
        type: string;
        websiteUrl: string;
        mainInterest: string;
        questsNumber: number;
        organizationCountry: string;
        organizationSize: number;
        organizationCreatedYear: number;
        businessLine: string;
      }) => ({
        url: `/api/organizations`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['OrganizationDetails', 'ListOrganizations']
    }),
    postSelectTokenUser: build.mutation({
      query: (body: { namespace: string; organizationId: number; userId: number }) => ({
        url: `/api/organizations/select-token-user`,
        method: 'POST',
        body
      })
    }),
    //TODO : pk POST ? faut le mettre en GET
    getTopTenOrganizationDetails: build.mutation({
      query: (body: {
        district: string;
        countryKey: string;
        employeeNumberKey: string;
        sectorKey: string;
        yearKey: string;
      }) => ({
        url: `/api/organizations/top-ten-details`,
        method: 'POST',
        body
      })
    }),
    //TODO : pk POST ? faut le mettre en GET
    getLastUpdateDatetime: build.mutation({
      query: () => ({
        url: `/api/organizations/last-update-datetime`,
        method: 'POST'
      })
    }),
    postponeTaskItem: build.mutation({
      query: (body: { taskItemKey: string; organizationId: number }) => ({
        url: `/api/taskitems/postpone`,
        method: 'PUT',
        body
      })
    }),
    deleteSelectTokenUser: build.mutation({
      query: ({ organizationId, namespace }) => ({
        url: `/api/organizations/deselect-token-user/${organizationId}/${namespace}`,
        method: 'DELETE'
      })
    }),
    deleteOrganization: build.mutation({
      query: (body: { organizationId: number; reason: string }) => ({
        url: `/api/organizations/deleteOrganization`,
        method: 'DELETE',
        body
      }),
      invalidatesTags: ['OrganizationDetails', 'ListOrganizations']
    }),
    addOrganization: build.mutation({
      query: (body: { name: string; websiteUrl: string; ownerId: string }) => ({
        url: `/api/organizations/add`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['OrganizationDetails', 'ListOrganizations']
    })
  })
});
export const {
  useAddOrganizationMutation,
  useDeleteOrganizationMutation,
  useDeleteSelectTokenUserMutation,
  useGetAllOrganizationDetailsQuery,
  useGetCountRemainingQuestionsQuery,
  useGetDomainTechQuery,
  useGetMyMembershipsQuery,
  useGetLastUpdateDatetimeMutation,
  useGetOrganizationDetailsQuery,
  useGetOrganizationDetailsByIdQuery,
  useGetOrganizationsQuery,
  useGetTopTenOrganizationDetailsMutation,
  useUpdateTrialDateMutation,
  useUpdateOrganizationPictureMutation,
  useUpdateOrganizationMutation,
  useUpdateOrganizationHiddenMutation,
  usePostponeTaskItemMutation,
  usePostSelectTokenUserMutation
} = organizationsApi;
