// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const auditsApi = createApi({
  reducerPath: 'auditsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API,
    prepareHeaders: () => {
      return baseHeader(true);
    }
  }),
  endpoints: (build) => ({
    getAdminAuditAllDetails: build.query({
      query: () => '/api/audits/admin/getAll'
    }),
    getAdminAuditDetails: build.query({
      query: ({ id, languageCode }) => `/api/audits/admin/details/${id}?lg=${languageCode}`
    }),
    getAuditByKey: build.query({
      query: ({ auditKey }) => `/api/audits?auditKey=${auditKey}`
    }),
    getAuditQuestions: build.query({
      query: ({ auditKey }) => `/api/audits/questions?auditKey=${auditKey}`
    }),
    getAuditTips: build.query({
      query: ({ auditKey }) => `/api/audits/tips?auditKey=${auditKey}`
    }),
    getListAuditByDistrictId: build.query({
      query: ({ districtId }) => `/api/audits/admin/${districtId}`
    }),
    updateAuditsValidationTranslation: build.mutation({
      query: (body: { auditId: string; languageCode: string; isValidate: boolean }) => ({
        url: `/api/audits/translations/validation`,
        method: 'PUT',
        body
      })
    }),
    setAuditLevelGrid: build.mutation({
      query: (body: { auditId: number; levelGridId: number }) => ({
        url: `/api/audits/set-level-grid`,
        method: 'PUT',
        body
      })
    }),
    updateAuditsIndex: build.mutation({
      query: (body: { indexes: { key: string; index: number }[] }) => ({
        url: `/api/audits/indexes`,
        method: 'PUT',
        body
      })
    }),
    syncAuditTranslations: build.mutation({
      query: (body: { label: string; languageCode: string; auditId: number }) => ({
        url: `/api/audits/translations`,
        method: 'PUT',
        body
      })
    })
  })
});

export const {
  useGetAdminAuditAllDetailsQuery,
  useGetAdminAuditDetailsQuery,
  useGetAuditByKeyQuery,
  useGetAuditQuestionsQuery,
  useLazyGetAuditQuestionsQuery,
  useGetAuditTipsQuery,
  useGetListAuditByDistrictIdQuery,
  useLazyGetListAuditByDistrictIdQuery,
  useUpdateAuditsValidationTranslationMutation,
  useUpdateAuditsIndexMutation,
  useSyncAuditTranslationsMutation,
  useSetAuditLevelGridMutation
} = auditsApi;
