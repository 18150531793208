// Externals
import _ from 'lodash';
import { Box, Typography } from '@mui/material';
import { t } from '@lingui/macro';

// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';

// Models
import connectorStorageKeys from 'components/legacy/connectors/connectorStorageKeys';
import ReportPartContainer from '../common';

type DateData = [string, string];

type GAData = DateData[];

const ReportGoogleSessionCount = (props) => {
  if (props.organizationId === null || props.organizationId === undefined)
    throw new Error('ReportGoogleSessionCount organizationid empty');

  const [sessionsDataGA] = useStorageValue<GAData>(
    connectorStorageKeys.googleGa4.report.sessions,
    props.organizationId
  );

  const sessionsData = sessionsDataGA.value ? sessionsDataGA.value : null;

  if (sessionsData === null) return null;

  const weekSessions = sessionsData
    ? _.filter(sessionsData, (s, index) => {
        if (+index === 0) return;

        let year = s[0].substring(0, 4);
        let month = s[0].substring(4, 6);
        let day = s[0].substring(6, 8);

        let date = new Date(Number(year), Number(month), Number(day));
        let startDate = new Date();
        let endDate = new Date();

        startDate.setDate(startDate.getDate() - 8);
        endDate.setDate(endDate.getDate() - 1);

        return date < endDate && date > startDate;
      })
    : [];

  const previousWeekSessions = sessionsData
    ? _.filter(sessionsData, (s, index) => {
        if (+index === 0) return;

        let year = s[0].substring(0, 4);
        let month = s[0].substring(4, 6);
        let day = s[0].substring(6, 8);

        let date = new Date(Number(year), Number(month), Number(day));
        let startDate = new Date();
        let endDate = new Date();

        startDate.setDate(startDate.getDate() - 15);
        endDate.setDate(endDate.getDate() - 9);

        return date < endDate && date > startDate;
      })
    : [];

  const weekCount = _.sumBy(weekSessions, (ws) => +ws[1]);
  const previousWeekCount = _.sumBy(previousWeekSessions, (lws) => +lws[1]);

  const difference = weekCount - previousWeekCount;

  return (
    <ReportPartContainer displayLink={props.displayLink} route={'/redirect/google-ga-sessions'}>
      <Typography sx={{ textAlign: 'center', pt: 2 }} variant="h4">
        {t`Visites sur votre site*`}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          py: 2
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            fontFamily: 'Inter, sans-serif',
            fontSize: 58,
            fontStyle: 'normal',
            fontWeight: 700,
            color: (theme) => theme.palette.primary.main,
            letterSpacing: 'normal',
            lineHeight: 50 / 42
          }}
        >
          {new Intl.NumberFormat().format(weekCount)}
        </Typography>
        <Typography
          sx={{
            ml: 1,
            textAlign: 'center',
            fontFamily: 'Inter, sans-serif',
            fontSize: 24,
            fontStyle: 'normal',
            fontWeight: 700,
            letterSpacing: 'normal',
            lineHeight: 50 / 42,
            color: (theme) =>
              difference === null
                ? theme.palette.text.primary
                : difference > 0
                ? theme.palette.success.main
                : difference === 0
                ? theme.palette.text.primary
                : difference < 0
                ? theme.palette.error.main
                : theme.palette.error.main
          }}
        >
          {difference === null
            ? '--'
            : difference > 0
            ? '+' + difference
            : difference === 0
            ? '--'
            : difference < 0
            ? difference
            : difference}
        </Typography>
      </Box>
    </ReportPartContainer>
  );
};

export default ReportGoogleSessionCount;
